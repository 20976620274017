const translations = {
  adjustment: 'Depletion',
  approved: 'Approved',
  canceled: 'Canceled',
  case: 'case',
  cases: 'cases',
  columnBasedOnRealTimeStock: 'Based on your par (real-time stock)',
  columnBasedOnWiskPar: 'Based on WISK par (Consumption)',
  columnBasedOnWiskParSales: 'Based on WISK par (Sales)',
  columnBasedOnYourPar: 'Based on your par (stock)',
  columnBeverageCostPercent: 'Beverage Cost (%)',
  columnBeverageCostPercentActual: 'Actual Cost of Sales (%)',
  columnBeverageCostPercentTheoretical: 'Theoretical Cost of Sales (%)',
  columnConsumption: 'Consumption ($)',
  columnCost: 'Cost ($)',
  columnCostOfGoodsSold: 'Cost of goods sold ($)',
  columnCostPercentagePercent: 'Cost Percentage (%)',
  columnCostPerUnit: 'Cost per unit',
  columnDetails: 'Details',
  columnDistributors: 'Distributors',
  columnEndPrice: 'End Price',
  columnFoodCostPercent: 'Food Cost (%)',
  columnFromArea: 'From Area',
  columnInventorySize: 'Inventory Size ($)',
  columnItemTotal: 'Item Total',
  columnLastMonth: 'Last 30 days',
  columnLastReceivedDate: 'Last received date',
  columnMenuPrice: 'Menu price',
  columnPendingPurchaseOrders: 'Pending Purchase Orders',
  columnPrice: 'Price',
  columnPriceDifference: 'Price Difference',
  columnPriceDifferencePercent: 'Price Difference %',
  columnQuantityUnits: 'Quantity (units)',
  columnRealTimeInventoryValue: 'Real-time inventory value',
  columnShrinkagePercent: 'Shrinkage (%)',
  columnShrinkageVolumePercent: 'Shrinkage Volume (%)',
  columnStartPrice: 'Start Price',
  columnSuggestedOrder: 'Suggested Order',
  columnToArea: 'To Area',
  columnVarianceBeginningInventory: 'Beginning Inventory (units)',
  columnVarianceBottles: 'Variance (units)',
  columnVarianceConsumedBottles: 'Consumed (units)',
  columnVarianceConsumedUM: 'Consumed (UM)',
  columnVarianceConsumptionCost: 'Cost of Goods Sold (COGS) ($)',
  columnVarianceCost: 'Variance Cost ($)',
  columnVarianceEndingInventory: 'Ending Inventory (units)',
  columnVarianceMovements: 'Invoices (units)',
  columnVariancePercentage: 'Variance (%)',
  columnVarianceRetail: 'Variance Retail ($)',
  columnVarianceSold: 'Sold ($)',
  columnVarianceSoldBottles: 'Sold (units)',
  columnVarianceSoldUM: 'Sold (UM)',
  columnVarianceUM: 'Variance (UM)',
  columnVenueId: 'Venue Id',
  confirmApplyGLAccounts:
    'By confirming this action, any GL accounts that have been assigned to an Item, Family, or Distributor will be applied to existing movements / invoices.\nThe GL accounts will be applied in the following priority:\nItem > Family > Distributor',
  confirmAppReload: 'We need to refresh the page.',
  confirmArchivePurchaseOrderText: 'Please confirm archiving the selected purchase order',
  confirmArchiveText: 'Please confirm archiving the selected item(s)',
  confirmArchiveTitle: 'Confirm archive',
  confirmBottlesMergeGroupText:
    "Are you sure you want to group these items?\n- The stock of the grouped item becomes the total count of all item variations, converted to the default.\n- Items are replaced by the grouped item in all inventories and movements (invoices, etc.) in unlocked inventory periods. Data from locked periods is unaffected.\n- After the grouping is finished it can take several minutes for your data to update. To ensure your data remains accurate, please don't make changes to the item immediately after grouping.",
  confirmBottlesMergeText: 'Are you sure you want to merge these items? This operation cannot be undone. Please use with extra care.',
  confirmBottlesMergeTitle: 'Confirm merge',
  confirmBottlesUngroupTitle: 'Confirm Ungrouping',
  confirmBottlesUnMergeUnGroupText:
    'Ungrouping will remove all item variations from this item that were added using the “Group” function.\n The removed item variations will be restored as separate items. Click “OK” to confirm.',
  confirmClearCartDistributorText: 'This will clear the shopping cart for this distributor. Continue?',
  confirmClearCartDistributorTitle: 'Clear shopping cart for distributor',
  confirmCopyDataToVenue: 'Copy operation is scheduled to be executed, the process may take a while, please be patient.',
  confirmDistributorContactDeleteText: 'Please confirm deleting selected contact.',
  confirmDistributorContactDeleteTitle: 'Delete contact',
  confirmForceArchiveText: 'Are you sure you want to force archive this item? The item will be archived and removed from every POS Item and Batch it is used in',
  confirmGenerateOrderDistributorText: 'This will generate a purchase order for this distributor. Continue?',
  confirmGenerateOrderDistributorTitle: 'Generate order',
  confirmGenerateOrderSendDistributorText: 'This will generate and send a purchase order for this distributor. Continue?',
  confirmGenerateOrderSendDistributorTitle: 'Generate order and send it to distributor',
  confirmHistoryChangingOperation: 'This operation will affect all the previous inventories taken',
  confirmInventoryApproveText: 'Please confirm that the inventory count has been completed and approved. Upon confirmation, you will be emailed a copy of your inventory report.',
  confirmInventoryApproveTitle: 'Confirm inventory approval',
  confirmInventoryDurationUnusualText:
    "The selected inventory duration is more than 48 hours, are you sure you want to save it? In most cases, the inventory count should be completed the same day it's started during a period with no sales in the venue.",
  confirmInventoryDurationUnusualTitle: 'Inventory duration is greater than 48 hours',
  confirmInventoryEntriesCopyText: 'Copy inventory entries from selected areas to respective areas in the selected inventory?',
  confirmInventoryLocationResetText: 'Do you want to restart the inventory count for this area? Restarting the count will delete all saved item counts in this area.',
  confirmInventoryLocationResetTitle: 'Restart Area',
  confirmItemsGridFloatingActionText: 'The selected operation will be executed for all the selected items.',
  confirmItemsGridFloatingActionTitle: 'Confirm operation',
  confirmItemVariantSetDefaultText:
    "The new default item variation you have selected is a different size than the current default item variation. The stock count will be converted to units in the new default item variation's measurement size. Please confirm that you want to complete this operation.",
  confirmItemVariantSetDefaultTitle: 'Confirm operation',
  confirmMappingGLAccounts: 'Please confirm you want to apply the GL Account assigned to each Item Family to every item with that family.',
  confirmMovementEditLinkToPurchaseOrder: 'Are you sure you want to link current invoice to this purchase order?',
  confirmMovementEditRemoveLineFromDepletion: 'Delete {a} from depletion',
  confirmMovementEditRemoveLineFromDepletionDescription: 'Are you sure you want to delete this {a} from the depletion?',
  confirmMovementEditSendEmailTitle: 'Confirm send email',
  confirmMovementEditSendTransferOutTitle: 'Transfer out confirmation',
  confirmMultipleOperationsGenericText: 'Please confirm the selected operation for all the selected items',
  confirmOnboardingBottleEditArchiveText: 'Please confirm removing this item',
  confirmOnboardingBottleEditNoteText: 'Ask for information',
  confirmOnboardingBottleEditOpenProcessingItem: 'This item is already open in another session. Open it anyway?',
  confirmOperationTitle: 'Confirm operation',
  confirmPaymentEditSendEmail: 'Would you like to send a subscription update email to the venue?',
  confirmPOSIntegrationDuplicateArchivedDescription:
    'There is currently an inactive integration for {a}. You can either restore the inactive integration or create a new one. If you need help regarding the inactive integration, please contact support@wisk.ai. ',
  confirmPOSIntegrationDuplicateDescription:
    'Are you sure you want to add a new POS integration for {a}? If your existing integration is disconnected, please contact support@wisk.ai to resolve the issue. Adding the same integration again can lead to duplicate POS Items',
  confirmPOSIntegrationDuplicateTitle: 'Duplicate POS integration',
  confirmPOSIntegrationReimportSalesData: 'Do you want to reimport sales data again for this integration?',
  confirmPOSItemsRestoreAll: 'Are you sure you want to restore all the deleted POS items?',
  confirmPOSItemsRestoreSelected: 'Are you sure you want to restore the selected deleted POS items?',
  confirmPOSItemsSaveNoIngredientsText: 'Are you sure you want to save the POS item with no ingredients?',
  confirmPOSItemsSaveNoIngredientsTitle: 'Confirm save without ingredients',
  confirmPrefillCartDistributorTextWiskPar: 'This will replace the quantity to order using WISK par. Continue?',
  confirmPrefillCartDistributorTextWiskParEstimated: 'This will replace the quantity to order using WISK par based on your real-time stock. Continue?',
  confirmPrefillCartDistributorTextYourPar: 'This will replace the quantity to order using your par. Continue?',
  confirmPrefillCartDistributorTextYourParEstimated: 'This will replace the quantity to order using your par based on your real-time stock. Continue?',
  confirmPrefillCartDistributorTitle: 'Prefill with recommended',
  confirmPurchaseOrderCancelTransferRequestMessage: 'Selected order will be canceled',
  confirmPurchaseOrderCancelTransferRequestTitle: 'Confirm cancel',
  confirmPurchaseOrderMarkAsSentMessage: 'Selected order(s) will be marked as sent to distributor but will not actually send it',
  confirmPurchaseOrderMarkAsSentTitle: 'Confirm mark as sent',
  confirmPurchaseOrderResendToDistributor:
    'Are you sure you want to email the purchase order to the distributor? Our records show you have already received this order and added an invoice.\n\nYou should only send this to the distributor again if the invoice they provided does not match what you actually received. You can adjust the purchase order to reflect what was received to request an updated invoice from the distributor.\n\nWe recommend making it clear in the message this is a modification to a past order and not a new order.',
  confirmPurchaseOrderSAQArchiveMessage: 'Did you place the order in the SAQ portal?',
  confirmPurchaseOrderSAQArchiveTitle: 'Confirm placing order',
  confirmPurchaseOrderSendMessage: 'Selected order(s) will be sent to distributor and marked as sent',
  confirmPurchaseOrderSendTitle: 'Confirm send',
  confirmPurchaseOrdersLinkToInvoice: 'Are you sure you want to link current purchase order to this invoice?',
  confirmPurchaseOrdersMarkReceivedNoIntake:
    'Do you want to mark the selected order as "Unable to fulfill"? Only do this if the distributor / supplier was unable to provide the ordered items.\n\nThis closes the purchase order without adding an invoice and removes the items from "pending purchase orders".\n\nIf you received some of the items, add the invoice like normal but remove the missing items.\n',
  confirmPurchaseOrdersMarkReceivedNoIntakeOkText: 'Mark as unable to fulfill',
  confirmRemoveImages: 'Please confirm removing the selected image. This operation is permanent.',
  confirmRemovePermissionText: 'Please confirm removing the selected permission from the role.',
  confirmRemovePermissionTitle: 'Remove Permission',
  confirmRemoveText: 'Please confirm deleting the selected item(s). This operation is permanent.',
  confirmRemoveTitle: 'Confirm Delete',
  confirmRestorePurchaseOrderText: 'Please confirm restoring the selected purchase order',
  confirmRestoreText: 'Please confirm restoring the selected item(s)',
  confirmRestoreTitle: 'Confirm restore',
  confirmResyncVenueData: 'You usually need to do this if a Wisk Customer Support agent has asked you to do so.',
  confirmResyncVenueDataTitle: 'Resync Venue Data',
  confirmRoleArchiveText: 'Please confirm archiving the selected role',
  confirmRoleRestoreText: 'Please confirm restoring the selected role',
  confirmScannedInvoicesConvert: 'Are you ready to convert this draft to a WISK invoice?',
  confirmScannedInvoicesDistributorChange: 'Please confirm the distributor change. This action will reset the draft invoice to the "Processing" status and all changes will be lost.',
  confirmScannedInvoicesDistributorCodeMismatchWishWIthoutItemsEdgeCase:
    'The distributor code of the selected item variation does not match the distributor code of the draft invoice line. This action will replace the distributor code of the draft invoice line with the distributor code of the selected item variation.',
  confirmScannedInvoicesReimport: 'Please confirm you want to re-import the selected invoices. This action will reset them to the "Processing" status.',
  confirmScannedInvoicesReprocess: 'This action will reset the draft invoice to the "Processing" status and all changes will be lost.',
  confirmStripeCardDeleteText: 'Please confirm removing the selected card.',
  confirmUnlinkPublicDistributorText: "Please confirm you want to unlink this distributor from the public database. The distributor will be marked as private and won't appear in searches.",
  confirmUnlinkPublicDistributorTitle: 'Unlink from WISK Distributor',
  confirmUserDeleteFromVenueText: 'Please confirm removing the selected user(s) from the selected venue(s).',
  confirmUserDeleteFromVenueTitle: 'Remove from venue',
  confirmVenueCopyData: 'Are you sure you want to copy data to selected venue?',
  confirmVenueCopyDataCheckLater: 'The venue copy is scheduled to be executed. Please check the destination venue in 10 minutes.',
  confirmVenueCopyDataTitle: 'Copy data to selected venue',
  confirmVenueDuplicate: 'There is already a venue at this address, create new venue anyway?',
  confirmVenueDuplicateTitle: 'Confirm create venue',
  declined: 'Declined',
  friday: 'Friday',
  groupBottlesAlgoPerpetualStatus: {
    no_recent_inventory: 'No recent inventory',
    not_enough_pos_items_mapped: 'Not enough POS items mapped',
    pos_not_integrated: 'POS not integrated'
  },
  groupBottlesAlgoPerpetualStatusTooltips: {
    no_recent_inventory:
      "Real time stock requires a completed inventory count within the last {a} days to remain accurate. If it's been more than {a} days since your last inventory count the system won't calculate a value."
  },
  groupCouchbaseStats: {
    core: 'Core',
    indexes: 'Indexes',
    inventories: 'Inventories',
    invoices: 'Invoices',
    sales: 'POS Items'
  },
  groupDashBoardHealthOverTimeGraphTypes: {
    distributors: 'Distributors',
    health: 'Overall',
    par_levels: 'Par Levels',
    pos_items: 'POS Items',
    prices: 'Prices'
  },
  groupDbErrors: {
    quotaExceededError:
      "Your device's hard drive is out of storage. New data cannot be saved or synced until space is available. This means you cannot get the latest changes and you will see inaccurate data. Please free up space and go to {a}. Data is saved on your device to speed up loading times"
  },
  groupFeatureAds_consumption: {
    subTitle: 'Know exactly how many ingredients you are using and where',
    text: "Know exactly how much of each ingredient you're using, which will help you make better decisions about what to order and how much to prepare",
    title: 'Consumption'
  },
  groupFeatureAds_deliveries: {
    subTitle: 'Stay organized and in control of your spending',
    text: 'Track out what you ordered from any supplier, or what you paid at any given time, in one place. Access all your invoice history from all your suppliers on this page. Sort by supplier, by category, by product, or by cost. Read more',
    title: 'Invoices'
  },
  groupFeatureAds_deliveries_analytics: {
    subTitle: 'Get real-time insights into the movements of your inventory',
    text: "Make better decisions about your inventory and operations. With a complete history of movements, you'll be able to easily see where things are transferred or returned and how much money you're actually spending on orders.",
    title: 'Movements Analytics'
  },
  groupFeatureAds_deliveries_draft_invoices: {
    subTitle: 'Scan invoices and your costs are updated in all your recipes automatically',
    text: "WISK extracts line item data from scanned invoices, in minutes and syncs it with your accounting software with one click of a button. Stop wasting time on data entry and start focusing on what you're good at - running your business!",
    title: 'Draft Invoices'
  },
  groupFeatureAds_pos_integration_pos_items: {
    subTitle: 'POS integration + recipe management = a profitable venue',
    text: 'Make smarter decisions with real-time insights from your POS. Keep your food costs in check with recipe costing and cost alerts, and find new opportunities to increase profitability.',
    title: 'POS Items'
  },
  groupFeatureAds_pos_integration_sales: {
    subTitle: 'Understand your sales and see where your profits come from',
    text: "Easily see what's selling, what's not, and where to focus your efforts so you can run a profitable restaurant. You can even see sales at an ingredient level so you can make informed decisions about your menu.",
    title: 'Sales'
  },
  groupFeatureAds_pos_integration_variance: {
    subTitle: 'See the difference between what was sold and what was actually consumed',
    text: 'We help you identify where your money is going - down to the gram. With our detailed reports, you can pinpoint losses due to waste, spillage, and theft.',
    title: 'Variance'
  },
  groupFeatureAds_purchase_orders: {
    subTitle: 'Manage all of your purchase orders in one place',
    text: "Easily manage and find purchase orders, sorted by supplier, category and product so that you're sure not missing anything important.",
    title: 'Purchase Orders'
  },
  groupFeatureAds_purchase_orders_cart: {
    subTitle: 'Automate ordering, reduce waste & over-ordering',
    text: 'Prepare purchase orders based on your sales (or your pars!) and email and text it directly to your distributors.',
    title: 'Orders'
  },
  groupFeatureAds_transfers: {
    subTitle: 'Reduce variance and consumption errors when you have multiple areas',
    text: "Transfers provide you with consumption / usage for specific areas in your venue. If you're selling products from more than one area and move inventory between areas, this is an important feature for you to track your consumption & variance.",
    title: 'Transfers'
  },
  groupGridViews: {
    'BottleEditTimeline_1c99b23c-e869-4cf6-aefb-e63ca466fbd9': 'Main',
    'BottleEditTimeline_1e8705ba-9b87-4399-85b6-201889862c3b': 'By User',
    'BottleEditTimeline_b80ca1e3-3d6a-4e69-a3a5-ac2db88ed879': 'Year > Month > Week',
    'CartBuilder_0350e5ce-b583-40be-b6c4-0a2eccea273a': 'By Family > Category',
    'CartBuilder_1361516e-acfe-4a6d-8b99-aa22a1f87c86': 'In Order Guide',
    'CartBuilder_8ec03634-8752-4f60-92a9-b4f99f0d7bc5': 'By Distributor > Family > Category',
    'CartBuilder_9fbea853-6b27-48db-8eb5-436435c5e962': 'Variation Cost Comparison',
    'CartBuilder_e732e9ab-4bd4-47a4-b32e-42fc87ceb819': 'Main',
    'CartBuilder_efe5a3cb-c2d0-415b-a76a-f19c5b3bc3be': 'By Category',
    'CartBuilder_f6987ed9-a8f8-4009-8254-7cabb6be49d7': 'Real-time stock',
    'ConsumptionGrid_item_4211649e-0b21-4d9f-9bf7-3eb1aaf24897': 'By Family > Category',
    'ConsumptionGrid_item_c59a2444-6784-4e00-8f1d-f66dfee70e5d': 'Main',
    'ConsumptionGrid_item_location_88434d85-e26e-4bab-b9a2-153762e5aad6': 'By Family > Category',
    'ConsumptionGrid_item_location_bfc34c7c-ea19-4b6c-8145-6b48fca922d0': 'Main',
    'CostChangesGrid_49e74eea-5aba-4dea-b688-712f7da97853': 'Main',
    'CostChangesGrid_a74a171d-1843-4f2c-b399-85e4e78c9b5e': 'By Percentage Difference',
    'CostChangesGrid_ec031ba6-ba6a-45b6-b342-458cd8025816': 'By Item',
    'CostChangesGridByMovement_2163671c-d9a4-4b05-a1fe-5a2bf2d5e5f9': 'Main',
    'CostChangesGridTimeline_d46d6a7e-ffae-49b6-9530-f29a2d67041a': 'Main',
    'CostChangesGridTimeline_f22086c8-82d3-41b5-a610-d070d5593bb5': 'By Percentage Difference',
    'DashboardMainTimeline_71408d76-ae84-4932-a617-40dccf129e89': 'Main',
    'DashboardMainTimeline_7b9029be-3849-4a54-908f-3af957384b48': 'By User',
    'DashboardMainTimeline_b52cfc48-43e5-4ea9-90a2-a8d9d54f2b1b': 'Year > Month > Week',
    'DashboardPurchasedProducts_aa0137df-deee-48d4-913b-750cc759701f': 'Default',
    'DashboardPurchasedProductsByTotal_4c91f4b8-b0b5-4a60-a5cb-95efbc08a62f': 'Main',
    'DashboardTopProfit_493b9416-32c5-4e47-9f8a-75a0a9871da9': 'Main',
    'DashboardTopSale_9c806cff-33b6-4dd5-ac82-f71b8b5ec638': 'Main',
    'DashboardTopSaleByFamily_4a84cc13-f92e-4c83-b050-7fd02f82b9dd': 'Main',
    'DistributorItemsItemSearchGrid_128da4a3-faaa-4716-a781-8baeec3f07cd': 'Main',
    'DistributorItemsSearchGridOnline_f8d4eff0-800b-4a3a-8cb8-c9579ba95607': 'Main',
    'Distributors_633f1aac-8660-4d1d-8ab0-e8d2b1c29746': 'Main',
    'DownloadManagerGrid_07054884-b341-4b92-8975-d844f2959d3f': 'Main',
    'DownloadManagerGrid_a3558cbd-93bc-456c-a414-90ee43a3552a': 'By Venue',
    'DraftInvoiceItemSearchGrid_780a05c6-35a3-41cf-bd2f-e7d931940f79': 'Main',
    'DraftInvoiceItemSearchGridOnline_543350be-d077-4bed-8fbf-f208f845b18a': 'Main',
    'Families_2547d8f0-79b4-4f6f-8be4-d07d20aff085': 'Main',
    'GLAccounts_51ee7317-30a1-4f0f-bdf6-42efcefd71a2': 'Main',
    'InventoriesGrid_7bd4a32c-f4a4-4980-a495-e9aaf6c2d860': 'Main',
    'InventoryEditTimeline_9cc0ed83-375d-4641-8594-5ad945fbf681': 'Main',
    'InventoryEditTimeline_e54e2d64-222e-4374-858f-659a50dccd1f': 'By User',
    'InventoryItems_item_16dd6e62-4d24-4be1-a9cb-54906050df78': 'Main',
    'InventoryItems_item_a16f3ccb-4354-414a-b8cf-722c5534c51b': 'Main with Par',
    'InventoryItems_item_location_25127e0e-7e81-4037-ba5e-5011a70b2f79': 'Main',
    'InvoiceGLAccountsGrid_8dbbda3c-d73e-4cda-9c4d-9b68687ad349': 'Main',
    'LinkInvoicesGrid_b31857e0-9f04-40bb-9b7a-3fcd90bc57ce': 'Main',
    'MovementAdjustmentReasons_95c37783-e1f6-4fc0-9f8b-29b7601719ce': 'Main',
    'MovementItems_5d64f4ae-1e04-41fb-82da-bff674482be4': 'By source',
    'MovementItems_9fd3102a-e4fd-48a1-accf-f2f0705a8941': 'Main',
    'MovementItems_a6066e47-ba4c-49f6-866b-facb1d53ca85': 'By Family > Category',
    'MovementsFlatGrid_a_ba649ce1-cfcf-444e-8b2a-bb9305e42a59': 'Main',
    'MovementsFlatGrid_b_220364d6-e76d-47e4-8c39-84fbfdfc2496': 'Main',
    'MovementsFlatGrid_c_c652c4ce-a64e-4a1e-9b29-93faeff1dc6f': 'Main',
    'MovementsFlatGrid_e_af088679-55ab-4658-ad9e-ec9074e89aa2': 'Main',
    'MovementsFlatGrid_f_db689e21-2d3d-4138-b777-19fab12a2390': 'Main',
    'MovementsFlatGrid_g_95248c79-182d-497c-9d79-f7ade9583be8': 'Main',
    'MovementsGrid_2fd5c624-ead6-455f-b40d-0edea0f98ca5': 'By Distributor',
    'MovementsGrid_7db47190-a10a-4d75-aee4-5cb739b6f7c0': 'Main',
    'MovementsGrid__987330fb-02ba-4fef-ac3f-58ecbf6aaf19': 'Main',
    'MovementsGrid_depletion_adjustment_3e955c3a-9133-487b-aabe-8b8d6357cf2c': 'Main',
    'MovementsGrid_depletion_adjustment_689ad659-0fb1-4b73-8050-b76865573167': 'By Description',
    'MovementsGrid_intake_return_91d5d37f-4614-4557-a259-288d41167ae8': 'Main',
    'MovementsGrid_intake_return_9aa51ebd-d718-460e-be67-04990cc69e88': 'By Distributor',
    'MovementsGrid_transfer_transfer_out_4d809107-eb0b-4338-b172-2e40a6de581a': 'Main',
    'MovementsItemsGridDepletions_4e785517-bd10-4063-b28d-f8bf95940362': 'Daily Waste / Depletions',
    'MovementsItemsGridDepletions_79e1ac9b-be88-48d7-bb1e-4d0988396759': 'By Depletion Reason',
    'MovementsItemsGridDepletions_7acc367f-e5ed-4c2e-9fea-da3e436114e4': 'Main',
    'MovementsItemsGridIntakes_01df2033-b6a0-4642-91e4-e49c26c231d8': 'Charges Report',
    'MovementsItemsGridIntakes_47e47c67-e65c-4264-9a90-e3fa4c32ec7f': 'Main',
    'MovementsItemsGridIntakes_5fa75a0d-1c31-48c9-8d83-322b5385b6b2': 'Credits Report',
    'MovementsItemsGridIntakes_91b2ec8f-431d-44f3-b160-0ee18f2a833b': 'Purchases by Distributor',
    'MovementsItemsGridIntakes_a4d6d3f8-c9ce-4237-8a5a-d21731c147e1': 'Purchases by GL Account',
    'MovementsItemsGridIntakes_c0ee0da7-ade4-4acf-a310-56925b8ee86b': 'By Purchase Amount',
    'MovementsItemsGridIntakes_c7843ae7-6229-479d-aea0-41dc5b2f0d63': 'Purchases by Invoice',
    'MovementsItemsGridIntakes_c946e55b-ffe8-40e7-90ad-55fc55c35d9f': 'Purchases by Item',
    'MovementsItemsGridTransfers_9c007492-d19a-4fba-ad06-b6414763f154': 'Main',
    'OrganizationsGrid_d447f15c-4d5c-464b-8d6f-3221ebc18b1a': 'Main',
    'POSItemIngredients_49caf2a9-b79e-4c9a-843d-951ea035e3b3': 'Main',
    'POSItemsGrid_254641c9-a665-4671-8337-518876641e7e': 'Pos Menu Category',
    'POSItemsGrid_586555a4-4fa3-4777-ace6-71f6d9586e84': 'Recipe Details',
    'POSItemsGrid_5b0f7373-47bb-446a-aaa9-bfa4ab557986': 'Main',
    'POSItemsGrid_bb462a9e-f230-414b-a58e-7aac0f13a044': 'Pos Sale Category',
    'POSItemsGrid_c0a79459-0dd5-4161-8e16-d8b257ffefb3': 'By Family > Category',
    'POSItemsGrid_d9db3514-eab8-44e9-8056-4048f2006bfd': 'Most Sold Last 30 Days',
    'POSItemsGridFlat_03d2c4da-1b7d-41f2-8b45-354a5c68ad91': 'Main',
    'POSItemsGridFlat_25347ed7-872e-49ac-a603-0330b96c19dc': 'By POS Item',
    'POSItemsGridFlat_33ff7b78-96cc-4d03-94fc-d2d6b6387898': 'By Family > Category',
    'POSItemsGridFlat_774b4b96-dd7b-4546-82f9-3bad0e3b3dfc': 'By POS Code',
    'POSItemsGridFlat_ed4192ea-ed72-4fe4-a0bf-0c2c52572ce2': 'Most Sold Last 30 Days',
    'POSModifiersGrid_1b5e307b-f4b0-48f6-b667-33cfe39e5f1a': 'By Mapping Type',
    'POSModifiersGrid_7f040635-02b0-47c0-b752-8c04ba7e5066': 'With Price',
    'POSModifiersGrid_c9011a93-a647-451f-bf27-6b3e1ea3674d': 'Main',
    'PurchaseOrderGrid_0c90d3af-e3b6-4fad-95bd-0832ad7328c9': 'Not received yet',
    'PurchaseOrderGrid_bf6ccea2-c485-4bde-9532-6421eb2c4d20': 'Main',
    'PurchaseOrderItems_90f0eed6-b3c9-4772-b455-3d723638bcea': 'Main',
    'RolesPermissions_f56c0fa0-ad77-444d-b48c-10e80c7e152c': 'Main',
    'SalesGrid_by_day_2b79cbec-6976-4bc5-b897-77406b5f7802': 'Main',
    'SalesGrid_by_day_e5876df7-f47d-4b6f-87f2-acda5c1739b2': 'By Family > Category',
    'SalesGrid_by_day_separateModifiers_551f658b-c805-492e-a936-d723d2f8fa56': 'Main',
    'SalesGrid_by_week_20b1c074-7acb-43e7-b5cb-9051520b698e': 'By Family > Category',
    'SalesGrid_by_week_847b4458-97be-4427-8fcc-a23bb5c4350a': 'Main',
    'SalesGrid_by_week_separateModifiers_fe8bf2ab-7d7d-4375-b25c-bab4b5ded922': 'Main',
    'SalesGrid_overall_4b2817c1-c4b6-484d-858c-226ab7ec6076': 'By Family > Category',
    'SalesGrid_overall_5a8faf64-e59c-48da-82dc-f4ea4614fee1': 'Most Sold ($)',
    'SalesGrid_overall_b6077f65-591a-449f-81a7-2e50e808a1f1': 'Most Sold (Quantity)',
    'SalesGrid_overall_d3fc100e-42d0-44f7-97b3-aad09cd9aebb': 'Main',
    'SalesGrid_overall_e8052b08-626f-43ad-8da8-cfdd84434b82': 'Pos Menu Category',
    'SalesGrid_overall_f00a6155-f0ec-4788-af99-baaa9e6c1cdc': 'Pos Sale Category',
    'SalesGrid_overall_separateModifiers_bcd7f535-6eef-4e56-b277-b4ce720e82bd': 'Main',
    'SalesGridByItem_2af427e2-5772-4fb0-8489-e11359ff74a6': 'Main',
    'SalesGridByItem_402dda09-19eb-42d8-a070-ab10dad6f252': 'By Category',
    'SalesGridByItem_a3548ff7-8681-4bcc-88d6-318fc75c68db': 'By Family > Category',
    'ScannedInvoicesGrid_6d4ea324-0b4c-4559-bb55-440e78c29390': 'By Invoice Date',
    'ScannedInvoicesGrid_8f34dfa8-36c6-40a1-bd62-3c2dacd69045': 'Main',
    'ScannedInvoiceTimeline_86e7869e-90f0-4425-a1b8-f9ab697bf0be': 'Main',
    'ShoppingCart_80c9ca51-de52-4608-b58f-1dc2e76814fe': 'By Distributor > Family > Category',
    'ShoppingCart_ed5095d3-8921-4a06-8af9-76c35a60700d': 'Main',
    'StockGrid_37931e8e-af48-495d-bb3d-1a086e9965be': 'Main',
    'SubRecipeIngredients_90019117-b05e-4d2f-adf2-462a59874f17': 'Main',
    'SubRecipesGrid_22f5014b-d306-4407-9dfc-6b3d4250f5cb': 'Main',
    'UnmappedGridVariance_fdbf85de-52c0-4c88-88a9-a0d8c55e5991': 'Main',
    'UserEmailReports_5495aa90-bbce-4e03-ad82-f9dcaac76cb8': 'Main',
    'UsersGrid_d3a592ce-ec2a-4ecc-9881-e7cab9ccf627': 'Main',
    'UsersVenues_e7e39cc0-61ea-4b1f-a81e-cf2d208d0cd6': 'Main',
    'UserVenues_032c00b0-376e-45ce-8208-287f450415e6': 'Main',
    'Variance_0d2491bd-062a-4bc5-9932-491fd60b4bf6': 'By Category',
    'Variance_0e99b3b2-dc7f-4520-b428-264326bde427': 'By Family > Category',
    'Variance_2c9e6e2d-169e-42fa-9202-894f9267bd89': 'Main',
    'Variance_74cc536e-d2e2-4c41-b7ee-20ea45ac1dbc': 'By Items',
    'Variance_7b8808bb-70ca-426b-ab8f-b5ad8bd9897e': 'Top Under Usages',
    'Variance_da246b85-0e13-414b-80b7-100e3c1ff344': 'Top Losses',
    'Variance_f2ff980f-45f5-4c53-966d-92efaa2f285b': 'By Reporting UM',
    'VenueBottles_byLocation_9be7c4c9-52ad-4f10-817b-03b7957ec244': 'Main',
    'VenueBottles_byVariation_77fa206a-ec0e-486d-afa2-469b58d33f6c': 'Variation Cost Comparison',
    'VenueBottles_byVariation_835779f1-4745-4deb-823a-328518cbd853': 'Main',
    'VenueBottles_byVariation_ad1b76af-8824-494e-9405-f5c188a6a892': 'Order Guides',
    'VenueBottles_byVariation_dbd5ce6d-f210-4d81-8546-efd34dfbf442': 'Distributor Product Codes',
    'VenueBottles_byVariation_fb7d4d63-b1bf-4aa2-bf18-5f1ff732dbed': 'By Assigned GL Account',
    'VenueBottles_duplicateCheck_f7591850-3bf2-4d6a-9400-54999ba44c6e': 'Main',
    'VenueBottles_items_01f63aa4-ad0d-439a-8d40-affe180d07f2': 'Check Measurement',
    'VenueBottles_items_1afa49d6-c0e1-46ee-a68a-0fdbc8d27e7b': 'Inventory Value',
    'VenueBottles_items_2df77891-9129-45b4-ac9d-3901dc0ef44e': 'By Family > Category',
    'VenueBottles_items_b6a9c4c2-6f44-48a7-8fe6-4b5f33955243': 'Main',
    'VenueBottles_items_c1a95dfd-3efe-4aee-b64a-d9e6c70c2625': 'By Assigned GL Account',
    'VenueBottles_items_c713dcc5-8963-4f80-91be-802cd0a26145': 'Recently added',
    'VenueBottles_items_d1b1c6d1-4023-4da9-94be-79dca0276bb6': 'Order Guides',
    'VenueBottles_items_d3b23afc-fb23-451d-bf48-bf90cc6fb5c3': 'Stock Alert',
    'VenuesGrid_cba568f3-5893-4b16-b0e1-87e2d053c687': 'Main',
    'VenuesUsers_d3559ac1-8d82-4035-945d-cd10bb7a623d': 'Main',
    'VenueTransfersGrid_7531d1d9-7917-4c04-a52b-995cf16ea4c7': 'Main',
    'VenueTransfersReportGrid_33df8e05-9d66-41e9-9d25-c4994938ef94': 'By Transfer Request ID',
    'VenueTransfersReportGrid_81641fe0-fa55-4b5a-94d6-c73271a9ea47': 'Main',
    'VenueTransfersReportGrid_a4e6b866-c15e-4df7-8a43-66321e9b80c2': 'By Item',
    'WiskDistributors_8e385726-4771-4927-b25b-62169c6124b3': 'By Created At',
    'WiskDistributors_c91ebd01-f7c1-4129-8025-11279eba889b': 'Main'
  },
  groupItemCreationSource: {
    batch_import: 'Created from batch import',
    copy_from_venue: 'Copied from another venue',
    distributor_invoice: 'Created from distributor invoice',
    draft_invoice: 'Created from draft invoice',
    inventory: 'Created from inventory',
    invoice: 'Created from invoice',
    manual: 'User created',
    purchase_order: 'Created from purchase order'
  },
  groupMovementEditIssues: {
    check_for_multiple_invoices: 'Check for multiple invoices',
    missing_information: 'Missing information',
    missing_pages: 'Missing pages',
    unreadable: 'Unreadable'
  },
  groupMovementTypes: {
    adjustment: 'Depletion',
    intake: 'Invoices',
    return: 'Returns',
    transfer: 'Transfer between areas',
    transfer_in: 'Transfer In',
    transfer_out: 'Transfer to Partner Venue'
  },
  groupPosItemsGridStatus: {
    archived: 'Archived',
    deleted: 'Deleted',
    excluded: 'Excluded',
    included: 'Included'
  },
  groupPosItemsGridStatusSales: {
    never_sold: 'Never Sold',
    recently_sold: 'Sold recently',
    sold: 'Not sold recently'
  },
  groupPOSModifierTypes: {
    add: 'Add',
    base_change: 'Base change',
    ingredient_change: 'Ingredient change',
    label: 'Label',
    measurement_change: 'Measurement change',
    multiplier: 'Multiplier',
    not_mapped: 'Not mapped',
    remove: 'Remove',
    subtract: 'Subtract'
  },
  groupPurchaseOrdersApprovalStatus: {
    approved: 'Approved',
    canceled: 'Canceled',
    declined: 'Declined',
    in_preparation: 'In Preparation',
    not_received: 'Not received',
    pending_approval: 'Pending approval',
    received: 'Received',
    received_manual_intake: 'Received with manual invoice',
    requested: 'Requested',
    unable_to_fulfill: 'Unable to fulfill'
  },
  groupPurchaseOrdersSentStatus: {
    not_sent: 'Not sent',
    sending: 'Sending',
    sent: 'Sent'
  },
  groupPurchaseOrdersStatus: {
    approved: 'Approved',
    declined: 'Declined',
    pending_approval: 'Pending approval',
    sent_for_approval: 'Requested'
  },
  groupScannedInvoicesAPIErrors: {
    duplicate_invoice: 'Duplicate invoice',
    duplicate_scanned_invoice: 'Duplicate scanned invoice',
    lines_dont_match: 'Lines do not match',
    missing_date: 'Missing date',
    missing_extra_line_info: 'Missing charges/credits info',
    missing_invoice_number: 'Missing invoice number',
    missing_supplier: 'Missing supplier',
    totals_almost_dont_match: 'Totals almost do not match',
    totals_dont_match: 'Totals do not match'
  },
  groupScannedInvoicesGLAccountGridLineTypes: {
    charge: 'Charge',
    credit: 'Credit',
    line_item: 'Line Item'
  },
  groupScannedInvoiceStatuses: {
    converted_to_invoice: 'Converted to Invoice',
    final_approval_needed: 'Final Approval Needed',
    in_processing: 'Processing',
    inbox: 'Processing',
    no_invoice_lines: 'No invoice lines detected',
    reviewed_by_wisk: 'Reviewed by WISK',
    select_distributor: 'Select distributor',
    to_review: 'Ready for Review'
  },
  groupTaxSchemes: {
    no_taxes: 'No tax',
    taxes_excluded: 'Taxes excluded',
    taxes_included: 'Taxes included'
  },
  groupTooltips: {
    '0009bbf4-3d4e-4e01-87db-644116dbad9b':
      'How much product was used at cost in the venue during the inventory period. Cost of Goods Sold (COGS) = Starting Inventory + Invoices - Ending Inventory. This is also referred to as Consumption ($).',
    '010cab9a-eb6b-4f27-8d28-56c70ef74f25': 'Displays the total $ value of all invoices added for each inventory period.',
    '0d94062c-3b33-48e3-bb25-8a034cd4e53d':
      'You cannot submit the previous counts for this area because 2 or more items have been archived since your last inventory. Please go into the area and submit your item counts.',
    '14883dec-b5d1-4e2e-9c42-6bf18d916d22': 'Select your POS Type and follow the setup instructions to complete your POS Integration.',
    '1595d339-b19f-4a9f-9778-cdcefa3d28f1': 'The cost percentage for the inventory period. Actual Cost % = Cost of Goods Sold (COGS) / Sales',
    '18316418-2db5-48df-b441-cb05421e493a': 'This table shows the breakdown of the variance by family. The larger the difference between the Actual and Theoretical Costs, the larger the variance.',
    '18a7c513-652b-4a06-894d-826fdf5c61c9':
      'This graph shows how your performance rating is calculated. The measure for success in this graph is having your Actual and Theoretical Cost % as close together as possible. \n\nThe closer they are, the smaller your variance for the period.',
    '202cd169-8d25-4281-affd-d7756b83e8f0': 'Select a barcode label printer from the list of supported models.',
    '20733d6f-ee85-48f1-9e52-52d9de21974e':
      "If checked, this item is omitted from inventory, consumption and variance calculations.  \n\nFor example, if you're inventorying something such as flatware/glassware. You don't want that to be included when using your inventory value and consumption costs to calculate variance.",
    '262d012c-32a9-4b10-855d-adb8f7b79bf4': 'The amount of the item that was received. This can be units, cases, or a custom value.',
    '264abe2f-d767-40c7-a191-19ff3a5a8d18': 'Marks this as a preferred item that you can filter by "Order Guide Items" on the Orders Page.',
    '2774291e-c835-4dc5-a63f-169ad0c50da5': 'If your venue has more than one POS system you can complete the integration for it here.',
    '2835def7-4946-4c8a-a3d9-5c93a2726063': 'Your ideal stock level for an item, that you can adjust at any time by clicking in the field.',
    '29998d2e-addd-4b74-9864-3aef88192ada':
      'The number input accepts only numbers, any invalid character will make the value to be 0. It can accept int or decimals, percent. The thoushands and decimal separators can be configured. Values in number inputs are always aligned to the right.',
    '29eda0ea-8c61-4cb5-94e6-afb85f2fedeb':
      'Populates the new venue with all the POS Items (and their mapping) and Batches from the current venue. Only select this if the new venue uses the same POS as the current venue and the menus are identical.',
    '2a63fec0-5bc1-4fb9-8fa7-166c1bf8614c': 'The item variation can be counted by cases in inventory.',
    '2b08398a-34f4-44ac-a822-6c217e08a065': 'The $ value of invoices added during the selected inventory period',
    '2bf8be7a-8897-4517-b210-4cbcd0eee161': 'This input will have red border after first focus if there is no value',
    '2c7ab526-3d34-442c-943c-5361a2598e4b': 'This option is only visible to users in Quebec. When this box is checked, a "Fee" column appears on the "Items" page to add agency fees for wines imported from the SAQ.',
    '30bb341a-5859-444e-b195-b1a4f977593f': 'The breakdown by item Family for the closing inventory count.',
    '333dd733-f42b-429d-ba16-e96f25e0588e': 'This shows your Performance % for each inventory period. This is how your Actual Cost % compares to your Theoretical Cost %. The closer to 100%, the smaller your variance.',
    '33ed65ed-77d5-4c61-be19-b3ac79276d20':
      'Allows you to add a retail price to your items (what they would sell for in your venue). This number is used to calculate your Variance Retail if you have consumption but no sales for an item.',
    '3419a020-475b-409d-be8d-602ac6d38187':
      'This represents the sales of mapped POS Items for the selected inventory period. If you have unmapped items you will see a difference between the number here and what is on the "Sales" page.  \n\nThis number doesn\'t include sales of unmapped POS Items, archived POS Items, or any POS Items that contain an item that is marked as "Excluded from Variance"',
    '353938ae-cffb-4dc0-a172-3f569ae183e6': 'The Cost of Goods Sold (COGS) by Item Family based on POS Item mapping.',
    '366965ef-4eb7-422e-9762-8a4623125877': 'This table shows the products purchased during the selected inventory period, sorted from the highest to lowest amount spent.',
    '36c49f26-c2ad-4926-b03f-35d3235769ae': 'The dollar value of the closing inventory count for the selected period',
    '3a47e0e3-961f-47d5-ad49-a34903f37b98': 'The unit of measurement for the item. This can be a volume, weight, or "unit/each" for items that don\'t have a weight or volume (for example, cups, cutlery, merch)',
    '40a40c56-473c-4001-a332-f6165176364a': "The theoretical value that the Variance Cost could've sold for based on your POS Item recipes.",
    '40b3a172-1fba-4b75-92c9-fe7983b08fea': 'The default option for measuring partial items when taking inventory. If you don\'t use the supported Bluetooth Scale, set this to"select".',
    '4194999b-cb33-4875-82a1-d99b8e6f960e':
      'The time that this specific inventory count started and finished. \n\nThis is not the "Inventory Period". The "Inventory Period" is calculated by WISK automatically based on the time between two completed inventory counts.',
    '45689096-e3b5-4668-8f6a-64e8acdb8ea9':
      'Populates the new venue with a shared item list from the current venue. This includes all items, distributors, and families & categories.  Follow the help link below to learn more before selecting this option:',
    '4f378036-f389-489c-9210-55d5f816906d': 'Enables Weighted Average Pricing in the venue.',
    '503b90c1-4bed-4b47-8399-ea5757998f4b': 'Supported accounting software. For setup instructions please see the link below:',
    '51518360-4314-4a2d-837f-f5218df5466c':
      'This is the same single select component as above, only here is displayed as a button group. If it does fit in the form, it is better because the user can select using only one click ad sees all the options at once.',
    '51f84b8e-000f-4c65-83f6-7ab68fd863f5':
      'Your top 10 items by under usage. These are items where you sold more than you actually consumed, or have a negative consumption value (you gained stock without a reason to account for it).',
    '55834d55-2148-47f8-b412-72567c6b03c6':
      'The % change in inventory value between the beginning and ending inventory counts. It also displays the change in $ amount. If the number is positive, the inventory value increased, if the number is negative, the inventory value decreased.',
    '5639bca2-6b5d-455e-95ba-435203086ae3': 'The currency of the venue. This displays on all pages and reports in WISK.',
    '578677f4-cf29-4473-9652-1a4fa2e34919': 'Select the label size that you are printing.',
    '59b40650-0cfe-488a-ad5f-698a2b2d0744': 'This is how a number and text field looks when disabled',
    '5a0305ac-27ea-49bf-97c0-9d3b956b3f92':
      'Ideal stock days are used to calculate your WISK Par. WISK looks at your last 6 weeks of consumption per item to recommend what you should order to have enough stock to last the ideal stock days.',
    '5a9805b5-e105-4ee9-a5c0-b9407d93cd8e': 'The yield percentage after an item has been processed and is ready to be used in recipes (trimming meat, peeling vegetables, etc.)',
    '5ac7b970-6119-4dd9-9399-9e7555142a25': "The full weight of the item and the container it's stored in. This is mostly used for volume-based items (liquids) to determine how much remains in partial units.",
    '5ad3d342-667b-46ee-99a0-1943400c2203': 'The total sales by item Family for the selected inventory period based on POS Item mapping.',
    '5fa0b2eb-5b3e-48fc-8003-179ba0e2017c': 'The breakdown of purchases during the inventory period by Family.',
    '6282a1c3-b52b-484b-bcd3-1302d0b6d005':
      'Based on your POS Item mapping, there is a theoretical cost for each item assuming there is no wastage. Theoretical Cost % = Theoretical Cost of Goods Sold (COGS) / Sales. This assumes you are using exactly what is mapped to your POS Items. This is that target cost percentage that you want to achieve.',
    '698d45d4-1b96-4432-abe4-168826e263b3': 'If no POS code is entered, one will be generated automatically.',
    '6a1d1284-778d-4fe8-81ba-0742fa98ec21':
      "How much a full unit of an item would sell for in your venue. This amount is generated automatically based on your POS mapping and sales data. \n\nYou may enter a value here that will be used in the event the item isn't used in any POS Items but still has consumption.\n\nNote: This is not the amount you paid for the item from your supplier.",
    '6c9c8794-7893-4abf-9922-0c3ad908a327': 'Enables use of the Supported Bluetooth Scales in the venue.',
    '6f0a1843-ac11-4205-870c-6f4f48d374fc': 'The item variation can be counted in Inventory.',
    '7018ddd9-6d3a-47f2-84fb-c4d474a0d939': "The weight of the item's container when empty.",
    '733af2ab-12cb-4a5b-bb0a-73b678178847': 'This table shows the breakdown of the variance by family. The larger the difference between the Actual and Theoretical Costs, the larger the variance.',
    '749616e8-4b37-4546-a20d-42648a4a401f': 'Whether the item is ordered by the unit or by the case from your supplier.',
    '7a70a618-6217-4b48-92d7-25a39685c981': 'The $ value of invoices added during the previous inventory period',
    '7b1628dc-2e2c-4ce9-99ae-b85f7931d38e':
      "Unchecked: The distributor is linked to its corresponding WISK Distributor. This allows you to search the WISK database for items belonging to this distributor.\n\nChecked: This distributor is private and won't appear in searches. Use this if the distributor is internal to your organization (it's not a distributor that any other business can order from).",
    '7be0961a-2ddf-4944-b44e-9d265ddc1ae6': 'Adding a variation name helps in identifying the correct one in many places in the app, for example when selecting which variation to add to an invoice.',
    '7d99aafe-a721-4e34-9b73-719cffe5c739': 'A comparison of your sales based on POS Item mapping and consumption for each inventory period.',
    '7dc534ad-5238-4da5-ad7a-ba56b9079b25': 'The total distributed percentage must equal 100%',
    '806a7d66-6daa-4f94-8dd1-c628f52c0626':
      'The sum of the Variance Cost for each item. Variance Cost = Cost per Unit * Variance. A negative Variance Cost indicates a loss. A positive Variance Cost indicates you used less than you sold',
    '82299dbd-af65-40b3-acc5-55581616ed81': 'This venue is used for testing. To learn more about all WISK Admin fields, see the attached Help link.',
    '82ff6948-b925-4459-967b-c2bd608f05d2': 'This table displays your top selling items based on your POS Item Mapping. Sales are assigned to an item based on the percentage of the overall cost it makes up.',
    '8734557e-b47c-4cbd-9acc-c15c6b3197cb':
      'This represents the sales of mapped POS Items for the selected inventory period. If you have unmapped items you will see a difference between the number here and what is on the "Sales" page.  \n\nThis number doesn\'t include sales of unmapped POS Items, archived POS Items, or any POS Items that contain an item that is marked as "Excluded from Variance"',
    '8dc2dd6a-3f0a-4ac1-a2cb-51e7ca3a1eaf':
      'Ideal stock days are used to calculate your WISK Par. WISK looks at your last 6 weeks of consumption per item to recommend what you should order to have enough stock to last the ideal stock days. This can be set at the venue level, or by individual distributor.',
    '8e68c44d-ac56-4f26-86c7-6cc64e6c4aea':
      'The Multiselect is a versatile component which can be used for many things. We can have single selection, combined with searching for the element, or multi selection, adding items to a list. It can be a bit confusing to persons who are not too used to using a computer.',
    '8f679494-295b-4f39-a609-fd4020f9d619':
      'This table shows the difference between your Theoretical Cost and Actual Cost for each family by inventory period. The difference is equal to Theoretical Cost % - Actual Cost %.  A negative value indicates that your Actual Cost was over the Theoretical Cost. A positive value indicates your Actual Cost was below the Theoretical Cost. A value is highlighted in red if it is greater than + or - 3%.',
    '8fd6db78-530c-4ed0-bb6a-e99e8e555935': 'If there is no validation applied to it, will have border green after first focus, denoting that it is valid.',
    '94174540-7515-4d71-8e03-568cab1aa153': 'This table displays your items sorted by profit from highest to lowest.',
    '9522b81d-f60e-439a-ae0d-4a084c56accb':
      'If checked, a purchase order for this distributor is generated automatically whenever an inventory count is completed. The amount added for each item is based on the par level.',
    '99c5e091-077d-445f-a1c6-e8b4093f886f': 'The SAQ import fee for the item (Quebec users only).',
    'a0ae7fca-16cb-412e-b23b-1bb0b311c82a':
      'How much product was used at cost in the venue during the inventory period. Consumption = Starting Inventory + Invoices - Ending Inventory. This is also known as the Cost of Goods Sold (COGS).',
    'a3fc14ee-7b29-4ed6-bafd-c3554caff97b':
      'This is how your Actual Cost % compares to the Theoretical Cost %. This value is calculated by taking the lower value of the two and dividing it by higher one. In most cases, Theoretical Cost % will be the lower of the two due to waste/spillage, etc. The closer to 100%, the smaller your variance.',
    'a40f6682-36f1-451f-bac4-9ace3527e898':
      'The sum of the Variance Cost for each item. Variance Cost = Cost per Unit * Variance. A negative Variance Cost indicates a loss. A positive Variance Cost indicates you used less than you sold',
    'a53c2fe3-2d2f-4529-94f5-1f03f6d78828': 'Text input value is aligned to the left',
    'a59a400d-6f3b-4820-89bb-dfe976c374a0': 'GL Accounts can be assigned to item families, individual items, and distributors as part of the Accounting Integration Add-On.',
    'aa7391fd-0884-49af-a2a6-6959448ff3cc': 'Select a supported label printer model and label size to be printed.',
    'accf7898-af33-4888-ac62-88c760ec31c2': 'Just adding prefix $ and 2 decimal places we get a currency type display.',
    'ad2d27c3-2c1f-4045-b98b-6075bffd7a0e': 'All cost changes during the selected inventory period.',
    'aeb05136-ea84-4175-9e53-7edd8ec686bf': 'In  this version of Multiselect we can add tags, starting with an empty list. This is used for items weights, barcodes, etc.',
    'b58fd8b6-c4af-4b0b-9d59-08765538546e': 'The name by which the Quantity + Unit of Measurement will be displayed(ex: bottle, can, package, box, etc.)',
    'b63ace9c-c202-4106-b68d-3f8a3dda08f5': 'The breakdown of purchases during the inventory period by Distributor.',
    'ba362fff-824e-43c5-bee5-0a8d1a07b2a2':
      'The comparison between your inventory value and Cost of Goods Sold (COGS) for each inventory period.  If your inventory is a lot larger than your COGS, you may have over or dead stock or missed adding some invoices.',
    'bb31faf4-a1d7-44bc-a75c-8234bbae331c': 'Your top 10 items with the largest losses. These are items where you consumed more than you sold.',
    'c55ba45d-1f5f-42b5-a12d-d15f285c5753':
      'How much product was used at cost in the venue during the inventory period. Cost of Goods Sold (COGS) = Starting Inventory + Invoices - Ending Inventory. This is also referred to as Consumption ($).',
    'c645dd57-8a03-4e02-baec-4293b5eb792c': 'Items marked as "Receive Only" cannot be added to purchase orders.',
    cartBuilderInfoMessage: 'The quantity of items included on any pending purchases orders are subtracted from your suggested pre-fill amounts to order.',
    'cdd5ae75-39`da-4d73-aad0-38189cd2532e': "Order emails to this distributor won't include the current item cost (based on your most recently added invoice)",
    'cdd5ae75-39da-4d73-aad0-38189cd2532e': "Order emails/texts sent to suppliers through WISK won't include your venue's item costs.",
    'cf6f8a9d-ed99-4dd8-a3cf-d730c9fddd05': "Use WISK's preset views or customize your display and create your own.",
    'd6a7c431-fff8-4b6b-b396-7e1e162223e7': 'The display language on the WISK Web Portal.',
    'd8fac951-6609-48ce-8645-4aee1bd59310': 'This graph compares your total sales of mapped POS Items for each inventory period. Place your mouse over a specific point to see the total for that period.',
    'dad59ae0-7c51-44cd-9b0f-e66538cfc12a':
      'By tagging an item with Inventory Areas, you can print an inventory sheet for each area in your venue. This is for if you want to count an area on paper, then add the values to the web or mobile app later.',
    dashboard_checklist__bottle_database: 'Bottle Library can be accessed by clicking "Venue Bottles"',
    dashboard_checklist__first_delivery: 'Click this checklist item to add your first invoice to track your usage of products accurately.',
    dashboard_checklist__first_inventory: 'Complete your first inventory by using the WISK Mobile App.',
    dashboard_checklist__generate_order: 'Click this checklist item to save time ordering and receiving by generating an order in WISK.',
    dashboard_checklist__map_pos_items: 'Click this checklist item to add recipes to your sales items to compare your sales vs usage.',
    dashboard_checklist__missing_costs: 'Click this checklist item to enter your missing costs to get the accurate dollar value of your inventory.',
    dashboard_checklist__missing_distributors: 'Click this checklist item to add your distributors to start placing orders and adding invoices.',
    dashboard_checklist__pos_brand_selected: 'Select your POS to start the process to import your sales data into WISK.',
    dashboard_checklist__pos_integrated: 'WISK will notify you when the POS is integrated',
    dashboard_checklist__second_inventory: 'Complete your second inventory in the WISK Mobile App to see your usage / consumption.',
    'dd2c568a-4847-459f-b1a6-9af16129fa44': 'The dollar value of the starting inventory count for the selected period',
    distributorPortalCredentialsInfo: 'There is an integration available for this supplier to import their invoices automatically.',
    draftInvoiceAdvancedSearchInfo: 'Click here to either:\n1) Create new items or new item variations\n2) Search the online database',
    draftInvoiceStatus_final_approval_needed: 'The WISK Team has reviewed this invoice. Please check all details are correct and convert it.',
    draftInvoiceStatus_in_processing: 'This invoice is currently being processed and cannot be edited.',
    draftInvoiceStatus_inbox: 'This invoice has been added to the processing queue. Invoice items will populate automatically when complete.',
    draftInvoiceStatus_issue_check_for_multiple_invoices:
      'This PDF is over 5 pages and may contain multiple invoices. Click "Actions" and then "Manage invoice pages" to split it into separate invoices. If this is a single invoice, click "Reprocess" under the "Actions" menu.',
    draftInvoiceStatus_issue_missing_information:
      'Please check the notes left by the Invoicing Team on specific line items and fill in the missing information. Click "Fix" to send the invoice back to the team to review.',
    draftInvoiceStatus_issue_missing_pages: 'The invoice appears to be missing pages. Please click "Fix" to reupload the full invoice.',
    draftInvoiceStatus_issue_unreadable: 'The invoice image is not clear enough for our team to process. Please click "Fix" to reupload the invoice.',
    'e482eea1-63b1-492e-8edb-2c1ec3005512':
      'For venues with a real-time POS integration.  If checked, your sales data is refreshed automatically every 30 minutes. If unchecked, your sales will refreshed every 6 hours, or when an inventory is started. You can also trigger a refresh manually on the Sales page.',
    'e6642b25-212e-45ba-bf93-1bf397963cbc': 'The breakdown by item Family for the closing inventory count.',
    'e6b67430-d627-4ca8-beb4-e15e3c7dac06': "Some distributors have unique codes for items that you can enter here. This can help with ordering, as the Title of your item may not match exactly with the distributor's.",
    'e78b4e44-a6f2-40de-9184-17595b4dd8db': "The theoretical value that the Variance Cost could've sold for based on your POS Item recipes.",
    'e8090b97-9c5c-4f00-a8e3-287b89ef43d8': 'This is the same Multiselect but adding new items is not allowed, only selecting from existing list.',
    'e9b01b87-0c49-444d-aa54-b971fdadbc31': 'The dollar value of the closing inventory count for the selected period',
    'e9f1fca5-bea1-4a6d-8579-08b0efa02f90': 'When adding invoices in the mobile app, a photo of the invoice is required.',
    'ea47a96b-23df-490d-8240-3749f3449a3f': 'When expanded, batches that are used as inventory items display as their base ingredients',
    'ea791a06-15ad-4ce5-b1c0-7fdf84419512': 'The total $ value of consumption by Item Family and Category.',
    'eb225c3f-7489-46cd-a46b-3048fe3bb1c0': 'Enables transfers between inventory areas in the WISK mobile app.',
    'eb66491e-359b-4488-8272-bdd4213a6922': "The item's measurement. This is the size of the item (for example, 750ml, 5lbs).",
    'eecab665-2d13-4984-9a75-dce6c791b876': 'The display language for all reports generated in WISK. To change the language for the WISK Web Portal, please go to the Account page.',
    'ef6a6fac-52d7-4a32-965d-a94c42a4e6de': 'In the future we might use a different library for picking date and time, which will open inline not in a popup. We use the popup now to avoid confusing UI.',
    email_reports_billing_updates_email: 'If a subscription payment fails, users with this option check receive an email notification',
    email_reports_daily_summary_email: "A summary of your venue's activity from yesterday, including inventory counts, orders placed, invoices received, price changes and more.",
    email_reports_draft_invoice_email: 'This is sent if an invoice added via scanning/uploading has an issue that prevents the WISK team or your team from processing it',
    email_reports_inventory_email: 'This is sent out every time an inventory count is completed and includes an attached copy of your inventory, consumption, and variance report (if your POS is integrated).',
    email_reports_invoice_email: 'When an invoice is added, you can send a confirmation email to all users selected to receive it. This email shows the details of the invoice.',
    email_reports_item_distributor_cost_alert_email: "This alert email is sent when an item's cost exceeds its set alert threshold.",
    email_reports_partial_inventory_email:
      'This is sent out every time a partial inventory count is completed and includes an attached copy of your inventory, consumption, and variance report (if your POS is integrated).',
    email_reports_pos_cost_alert_email: "This alert email is sent when a POS Item's cost percentage exceeds its set alert threshold.",
    email_reports_purchase_order_email: 'When an order email is sent to a distributor, all users sent to receive this email are cc-ed automatically.',
    email_reports_stock_alert_email: 'When the “Real-time Stock” of an item meets or goes below the “Stock Alert” level, an email notification is sent.',
    email_reports_weekly_summary_email: "A summary of your venue's activity for the past week, including inventory counts, orders placed, and invoices received and more.",
    'f3d57c5b-6322-46a7-bcab-002fe1146034': "If the POS Item's Cost % goes above the Cost Alert % you'll receive an email notification.",
    'f3dd73b3-6fc4-42c8-b5ba-f3e220a85654': 'This graph compares your total purchases for each inventory period.',
    'fd3620ea-9b15-4df5-9551-8f45c42087f5': 'What is a partial inventory?',
    'fdb8b6db-2049-469e-98f4-3a59eb1a8f2d': 'Sales minus Cost of Goods Sold (COGS) for the selected inventory period.',
    'fe4b26eb-eaa4-4f06-9e31-1b1476e48d9c': 'Uploading multiple files as a single invoice is possible only if all the files are images. You cannot combine multiple PDFs into a single invoice.',
    'fe534b3c-04a4-42e1-974e-92744ae13abf': 'This table shows the breakdown of your sales by family. This is categorized based on the family of the items mapped to your POS Items.',
    glAccountAccountingMappingMaxPercent: "This field can have maximum value of {a}% based on the other fields' percentage values",
    grid__CartBuilder__caseSize: 'How many units are in a case of an item. Setting a case size can help when counting your inventory and placing orders and receiving deliveries.',
    grid__CartBuilder__category: 'This is the sub-category of an item, based on the Family.',
    grid__CartBuilder__costCase: 'How much a case of an item costs based on the Case Size and Cost /Unit.',
    grid__CartBuilder__costUnit: 'The price you pay per unit to your distributors. This amount should be before taxes are applied',
    grid__CartBuilder__country: 'The country of origin of an item',
    grid__CartBuilder__distributor: 'The distributor that you order/ receive the item from. If you get this product from more than one distributor, you can change the distributor at any time by clicking in the field.',
    grid__CartBuilder__distributorCode: "Some distributors have unique codes for items that you can enter here. This can help with ordering, as the Title of your item may not match exactly with the distributor's.",
    grid__CartBuilder__emptyWeights: "The weight of the item's container when empty.",
    grid__CartBuilder__family: 'The family of alcohol that an item is from (Wine, Beer, Liquor, etc.)',
    grid__CartBuilder__inventoryValue: 'The value of the current “Stock” count.',
    grid__CartBuilder__locations: 'This is an optional feature where you can tag an item with inventory areas. This allows you to generate an inventory sheet for areas where counting using the app may not be practical.',
    grid__CartBuilder__orderFormat: 'Whether you order an item by the unit or the case. You can change the format at any time by clicking in the column.',
    grid__CartBuilder__par: 'Your ideal stock level for an item, that you can adjust at any time by clicking in the field.',
    grid__CartBuilder__parSuggested: "Calculated by an item's consumption from the previous 6 weeks. This is your WISK recommended ideal stock level.",
    grid__CartBuilder__parSuggestedSales: "Calculated by an item's sales from the previous 6 weeks. This is your WISK recommended ideal stock level.",
    grid__CartBuilder__pendingPurchaseOrders: "If you have an open purchase order for an item, it shows how much stock you're expecting to receive when the order comes in.",
    grid__CartBuilder__perpetual:
      'Takes the "Stock" count and subtracts your sales since the most recent inventory to provide a theoretical current stock count. You can click the "i" icon to see the sales for an item since the most recent inventory.',
    grid__CartBuilder__quantityToOrder: 'The amount that will be ordered. The "Order Format" displays on the left.',
    grid__CartBuilder__stock:
      'The stock from your most recent inventory count. This number increases with invoices and decreases with returns/adjustments. \n\nYou can see where the item was counted, and any invoices/returns since that inventory by hovering over the “i” icon.',
    grid__CartBuilder__stock_full_bottles: 'The same as "Stock", but it ignores all partial bottles of an item.  This is for the "Prefill - full bottles" par option.',
    grid__CartBuilder__suggestedOrder:
      'The recommended amount to order, based on the "Stock" value and Your Par. \n\nIf you select the "Based on your par - Stock" option under pre-fill, it will add that amount to your cart / order.',
    grid__CartBuilder__suggestedOrderEstimatedStock:
      'The recommended amount to order, based on the "Real Time Stock" and Your Par. If you select the "Using your par - estimated stock" option under pre-fill, it will add that amount to your cart / order. \n\nThis is if you want to generate an order but have not recently completed an inventory. You can use the Real Time Stock estimate to determine the amount you need to order. Keep in mind this is theoretical, based on your sales. It doesn\'t account for any overpouring or potential theft.',
    grid__CartBuilder__suggestedOrderFullBottles:
      'The recommended amount to order, based on your current stock of full units and Your Par. If you select the "Using your par" option under pre-fill, it will add that amount to your cart / order. \n\nThis is helpful for venues that offer bottle service and have a lot of full unit sales.',
    grid__CartBuilder__suggestedOrderRealTimeStock:
      'The recommended amount to order, based on the "Real Time Stock" and Your Par. \n\nIf you select the "Based on your par - Real Time Stock" option under pre-fill, it will add that amount to your cart / order. \n\nThis is if you want to generate an order but have not recently completed an inventory. You can use the Real Time Stock estimate to determine the amount you need to order. Keep in mind this is theoretical, based on your sales. It doesn\'t account for any overpouring or potential theft.',
    grid__CartBuilder__suggestedOrderWisk:
      'The amount that WISK recommends to order, based on the "Stock" value and the WISK Par. \n\nIf you select the "Based on WISK Par - Stock" option under pre-fill, it will add that amount to your cart / order.',
    grid__CartBuilder__title: 'The name of the WISK Item.',
    grid__CartBuilder__weights: "The full weight of the item and the container it's stored in. This is mostly used for volume-based items (liquids) to determine how much remains in partial units.",
    grid__CartBuilder__wiskId: 'The unique identifier number for the item in WISK',
    grid__ConsumptionGrid__category: 'This is the sub-category of an item, based on the Family.',
    grid__ConsumptionGrid__consumptionDollars: "The dollar value of an item's consumption. It's the amount of an item consumed, multiplied by the Cost/Unit.",
    grid__ConsumptionGrid__costUnit: 'The price you pay per unit to your distributors. This amount should be before taxes are applied',
    grid__ConsumptionGrid__distributor:
      'The distributor that you order/ receive the item from. If you get this product from more than one distributor, you can change the distributor at any time by clicking in the field.',
    grid__ConsumptionGrid__distributorCode: "Some distributors have unique codes for items that you can enter here. This can help with ordering, as the Title of your item may not match exactly with the distributor's.",
    grid__ConsumptionGrid__family: 'The family that an item is from (Wine, Beer, Liquor, etc.)',
    grid__ConsumptionGrid__title: 'The name of the WISK Item.',
    grid__ConsumptionGrid__wiskId: 'The unique identifier number for the item in WISK',
    grid__DashboardMainTimeline__date: 'The date and time the action was performed.',
    grid__DashboardMainTimeline__doneBY: 'The user account that performed the action.',
    grid__DashboardMainTimeline__message: 'The action performed in the system.',
    grid__dashboardTopLosses__itemName: 'the name of the item',
    grid__dashboardTopLosses__varianceCost: 'the dollar value of the loss at cost',
    grid__dashboardTopLosses__variancePercentage: 'How much of the total consumption of the item is due to variance. Variance % = Variance / Consumption',
    grid__dashboardTopLosses__varianceRetail: "An estimate of what the Variance Cost value would've sold for (based on POS Items)",
    grid__dashboardTopLosses__varianceUnits: 'Equal to Sold (units) - Consumed (units). The difference between what was sold and what was actually consumed',
    grid__DashboardTopProfit__actualConsumptionCost: 'The dollar value at cost of what was actually consumed (based on inventories and invoices received)',
    grid__DashboardTopProfit__actualCost: 'Your overall cost percentage for the item. Actual Cost (%) = Cost of Goods Sold (COGS) ($) / Sold ($)',
    grid__DashboardTopProfit__idealCost: 'Your Theoretical Cost percentage for the family. Theoretical Cost of Goods Sold (COGS) ($) / Sold ($). This is if you use exactly what is mapped to your POS Items',
    grid__DashboardTopProfit__item: 'The name of the item',
    grid__DashboardTopProfit__profit: 'Overall profit for the item. Profit = Sales - Cost of Goods Sold (COGS) ($)',
    grid__DashboardTopProfit__sale: 'The total sales for the item during the period based on POS Item mapping.',
    grid__DashboardTopProfit__salePercentage: 'The percentage of the overall sales assigned to the item',
    grid__DashboardTopProfit__targetConsumptionCost: 'The dollar value of what should have been consumed based on POS Item mapping',
    grid__DashboardTopSale__actualConsumptionCost: 'The dollar value at cost of what was actually consumed (based on inventories and invoices received)',
    grid__DashboardTopSale__actualCost: 'Your overall cost percentage for the item. Actual Cost (%) = Cost of Goods Sold (COGS) ($) / Sold ($)',
    grid__DashboardTopSale__idealCost: 'Your Theoretical Cost percentage for the family. Theoretical Cost of Goods Sold (COGS) ($) / Sold ($). This is if you use exactly what is mapped to your POS Items',
    grid__DashboardTopSale__item: 'The name of the item',
    grid__DashboardTopSale__profit: 'Overall profit for the item. Profit = Sales - Cost of Goods Sold (COGS) ($)',
    grid__DashboardTopSale__sale: 'The total sales for the item during the period based on POS Item mapping.',
    grid__DashboardTopSale__salePercentage: 'The percentage of the overall sales assigned to the item',
    grid__DashboardTopSale__targetConsumptionCost: 'The dollar value of what should have been consumed based on POS Item mapping',
    grid__DashboardTopSaleByFamily__actualConsumptionCost: 'The dollar value at cost of what was actually consumed (based on inventories and invoices received)',
    grid__DashboardTopSaleByFamily__actualCost: 'Your overall cost percentage for the family. Actual Cost (%) = Cost of Goods Sold (COGS) ($) / Sold ($)',
    grid__DashboardTopSaleByFamily__familyName: 'The name of the item family',
    grid__DashboardTopSaleByFamily__idealCost: 'Your Theoretical Cost percentage for the family. Theoretical Cost of Goods Sold (COGS) ($) / Sold ($). This is if you use exactly what is mapped to your POS Items',
    grid__DashboardTopSaleByFamily__profit: 'Overall profit for the item family. Profit = Sales - Cost of Goods Sold (COGS) ($)',
    grid__DashboardTopSaleByFamily__sale: 'The total sales based on POS Item mapping for the item family.',
    grid__DashboardTopSaleByFamily__salePercentage: 'The percentage of the overall sales assigned to the family',
    grid__DashboardTopSaleByFamily__targetConsumptionCost: 'The dollar value of what should have been consumed based on POS Item mapping',
    grid__dashboardTopUnderUsage__consumptionUnits: '(Beginning inventory + Invoices Added) - Ending inventory. In other words, what was actually used during the inventory period.',
    grid__dashboardTopUnderUsage__itemName: 'the name of the item',
    grid__dashboardTopUnderUsage__soldUnits: 'The number of units sold based on the sales data received from your POS system and using the POS items and recipes you have created.',
    grid__dashboardTopUnderUsage__varianceCost: 'the dollar value of the overage at cost',
    grid__dashboardTopUnderUsage__variancePercentage: 'How much of the total consumption of the item is due to variance. Variance (%) = Variance / Consumption',
    grid__dashboardTopUnderUsage__varianceRetail: "An estimate of what the Variance Cost value would've sold for (based on POS Items)",
    grid__dashboardTopUnderUsage__varianceUnits: 'Equal to Sold (units) - Consumed (units). The difference between what was sold and what was actually consumed',
    grid__dashboardVarianceByCategory__actualConsumptionCost: 'The dollar value at cost of what was actually consumed (based on inventories and invoices received)',
    grid__dashboardVarianceByCategory__actualCost: 'Your overall cost percentage for the category. Actual Cost (%) = Cost of Goods Sold (COGS) ($) / Sold ($)',
    grid__dashboardVarianceByCategory__categoryName: 'The name of the item category.',
    grid__dashboardVarianceByCategory__sold: 'The total sales based on POS Item mapping for the item category.',
    grid__dashboardVarianceByCategory__targetConsumptionCost: 'The dollar value of what should have been consumed based on POS Item mapping.',
    grid__dashboardVarianceByCategory__targetCost: 'Your Theoretical Cost percentage for the category. Theoretical Cost of Goods Sold (COGS) ($) / Sold ($). This is if you use exactly what is mapped to your POS Items',
    grid__dashboardVarianceByCategory__varianceCost:
      'Equals Theoretical Cost of Goods Sold (COGS) - Cost of Goods Sold (COGS). If this value is negative it indicates a loss. You used more than you were supposed to based on recipe mapping',
    grid__dashboardVarianceByCategory__varianceRetail: "An estimate of what the Variance Cost value would've sold for (based on POS Items)",
    grid__dashboardVarianceByFamily__actualConsumptionCost: 'The dollar value at cost of what was actually consumed (based on inventories and invoices received)',
    grid__dashboardVarianceByFamily__actualCost: 'Your overall cost percentage for the family. Actual Cost (%) = Cost of Goods Sold (COGS) ($) / Sold ($)',
    grid__dashboardVarianceByFamily__familyName: 'The name of the item family.',
    grid__dashboardVarianceByFamily__sold: 'The total sales based on POS Item mapping for the item family',
    grid__dashboardVarianceByFamily__targetConsumptionCost: 'The dollar value of what should have been consumed based on POS Item mapping.',
    grid__dashboardVarianceByFamily__targetCost: 'Your Theoretical Cost percentage for the family. Theoretical Cost of Goods Sold (COGS) ($) / Sold ($). This is if you use exactly what is mapped to your POS Items',
    grid__dashboardVarianceByFamily__varianceCost:
      'Equals Theoretical Cost of Goods Sold (COGS) - Cost of Goods Sold (COGS). If this value is negative it indicates a loss. You used more than you were supposed to based on recipe mapping',
    grid__dashboardVarianceByFamily__varianceRetail: "An estimate of what the Variance Cost value would've sold for (based on POS Items)",
    grid__Families__categoryTitle: 'This is the sub-category of an item, based on the Family.',
    grid__Families__density:
      'The density of water (with a specific gravity of 1.0) is used to determine the density of other liquids. This value is used to calculate how much remains in a partial unit of an item if there is only a full weight on file.',
    grid__Families__familyId: 'The family of alcohol that an item is from (Wine, Beer, Liquor, etc.)',
    grid__Families__partial: 'Your default partial measuring preference for the category.  Wines are typically set to the visual mode, and Liquors to the scale.',
    grid__Families__target_beverage_cost: 'Your target beverage cost % for the category.',
    grid__InventoriesGrid__duration: 'This is the total in-app "active" time that the inventory took to complete.\nIt is not the difference between the start and finish time.',
    grid__InventoriesGrid__finishedAt: 'The time the inventory count was finished and submitted in the venue.',
    grid__InventoriesGrid__startedAt: 'The time the inventory count began in your venue.',
    grid__InventoryItems_item__costUnit:
      'The item\'s current cost per unit based on your most recent invoice. This value is for your reference to compare costs. The inventory value is based on the "Inventory cost per unit"',
    grid__InventoryItems_item__costUnitInventory: "The item's cost per unit at the inventory start time. This is based on the last invoice received before the inventory count began.",
    grid__InventoryItems_item__totalUom: 'Displays the total quantity converted to the unit of measurement of the default variation.',
    grid__MovementExtraLines__reasonType: 'Whether the adjustment is a charge (adds to the total), or a credit (subtracts from the total).',
    grid__POSItemsGrid__category: 'This is the sub-category of an item, based on the Family.\n\nNote: If the POS Item has ingredients from different families or categories, it displays as "Cocktails"',
    grid__POSItemsGrid__cost: 'The cost of the item based on the ingredients that have been mapped to it.',
    grid__POSItemsGrid__costAlert: "If the Cost Percentage of the POS Item goes above the Cost Alert percent, you'll receive an email notification.",
    grid__POSItemsGrid__family: 'The family of alcohol that an item is from (Wine, Beer, Liquor, etc.)\n\nNote: If the POS Item has ingredients from different families or categories, it displays as "Cocktails"',
    grid__POSItemsGrid__menuPrice: 'The menu price of the item as it appears in your POS.',
    grid__POSItemsGrid__more: 'The ingredients and their amounts that have been mapped to the POS Item. To add ingredients, click into the field.',
    grid__POSItemsGrid__percentage: 'The cost percentage of the item. \n\nPercentage = Cost / Menu Price',
    grid__POSItemsGrid__pluNumber: 'The unique identifier code for the POS Item in your POS system.',
    grid__POSItemsGrid__posCategorySale: 'If your POS has a direct integration with WISK, WISK can pull the menu categories and display them here (Food, Liquor etc.).',
    grid__POSItemsGrid__posGridGroupLast30ActualCostPercentage: 'The actual drink cost percentage of the item, based on the Average Price. \n\nActual Cost Percentage = Cost / Average Price.',
    grid__POSItemsGrid__posGridGroupLast30AvgPrice:
      'The average price of the item in the past 30 days. \n\nAverage Price = Total / Quantity Sold. \n\nThis is different from the menu price, because it will factor in any comps or discounts.',
    grid__POSItemsGrid__posGridGroupLast30Cost: 'The at-cost value of inventory sold based on the POS Item recipe. \n\nSales Cost = Cost * Quantity Sold',
    grid__POSItemsGrid__posGridGroupLast30Profit: 'The profit made on each sale of the item. \n\nProfit = Menu Price - Cost',
    grid__POSItemsGrid__posGridGroupLast30QuantitySold: 'The number of times the POS Item has been sold in the last 30 days.',
    grid__POSItemsGrid__posGridGroupLast30Total: 'The total sales for the POS Item in the last 30 days.',
    grid__POSItemsGrid__title: 'The name of the item in your POS.',
    grid__PurchaseOrderGrid__receivedStatus: 'Whether the order has been received in the venue and the invoice has been added. It either displays as "Received" or "Not received".',
    grid__PurchaseOrderGrid__sentStatus: 'Whether the purchase order has been emailed to the distributor. It either displays as "Sent" or "Not sent".',
    grid__PurchaseOrderItems__distributorCode:
      "Some distributors have unique codes for items that you can enter here. This can help with ordering, as the Title of your item may not match exactly with the distributor's.",
    grid__PurchaseOrderItems__orderFormat: 'Whether you order an item by the unit or the case. You can change the format at any time by clicking in the column.',
    grid__PurchaseOrderItems__title: 'The name of the WISK Item.',
    grid__SalesGrid__category: 'This is the sub-category of an item, based on the Family.\n\nNote: If the POS Item has ingredients from different families or categories, it displays as "Cocktails"',
    grid__SalesGrid__cost: 'The cost of the item based on the ingredients that have been mapped to it.',
    grid__SalesGrid__costPercentage: 'The actual drink cost percentage of the item, based on the Average Price. \n\nActual Cost Percentage = Cost / Average Price.',
    grid__SalesGrid__family: 'The family of alcohol that an item is from (Wine, Beer, Liquor, etc.)\n\nNote: If the POS Item has ingredients from different families or categories, it displays as "Cocktails"',
    grid__SalesGrid__pluNumber: 'The unique identifier code for the POS Item in your POS system.',
    grid__SalesGrid__price:
      'The average price of the item in the defined date range. \n\nAverage Price = Total / Quantity Sold. \n\nThis is different from the menu price, because it will factor in any comps or discounts.',
    grid__SalesGrid__quantity: 'The number of times the POS Item has been sold in the defined date range.',
    grid__SalesGrid__title: 'The name of the POS Item in your POS.',
    grid__SalesGrid__totalCost: 'The cost of the POS Item multiplied by the quantity sold for the defined date range.',
    grid__SalesGrid__totalProfit: 'The Total Profit made on sales of the POS Item during the defined date range.\n\nTotal Profit = Total Sales - Total Cost',
    grid__SalesGrid__totalSales: 'The total sales for the POS Item in the defined date range.',
    grid__ScannedInvoicesGrid__distributor: 'The selected distributor for the invoice',
    grid__ScannedInvoicesGrid__invoiceDate: 'The date the invoice / order arrived at the venue',
    grid__ScannedInvoicesGrid__invoiceNumber: "The invoice's ID number from the selected distributor",
    grid__ScannedInvoicesGrid__invoiceSource: 'How the invoice was uploaded. Options include - email, mobile app, web and distributor integration',
    grid__ScannedInvoicesGrid__invoiceTotal: 'The total value of the invoice including all line items, charges, credits, and applicable taxes.',
    grid__ScannedInvoicesGrid__scanDate: 'The date the invoice was uploaded to WISK',
    grid__ScannedInvoicesGrid__status: 'The current status of the invoice. An invoice can be “Processing”, “Ready for Review” or “Done”',
    grid__ScannedInvoicesGrid__totalItems: 'The total number of line items on the invoice',
    grid__ScannedInvoicesGrid__totalItemsNeedsAttention: 'Items that have been flagged for review. Based on the invoice, there may be missing context / information (case size, item measurement etc.)',
    grid__ScannedInvoicesGrid__totalItemsUnmapped: "The number of line items on the invoice not detected in your venue's item list and require action (Creating new items, or linking them to existing ones)",
    grid__ShoppingCart__caseSize: 'How many units are in a case of an item. Setting a case size can help when counting your inventory and placing orders and receiving deliveries.',
    grid__ShoppingCart__category: 'This is the sub-category of an item, based on the Family.',
    grid__ShoppingCart__costCase: 'How much a case of an item costs based on the Case Size and Cost /Unit.',
    grid__ShoppingCart__costUnit: 'The price you pay per unit to your distributors. This amount should be before taxes are applied',
    grid__ShoppingCart__country: 'The country of origin of an item',
    grid__ShoppingCart__distributor: 'The distributor that you order/ receive the item from. If you get this product from more than one distributor, you can change the distributor at any time by clicking in the field.',
    grid__ShoppingCart__distributorCode: "Some distributors have unique codes for items that you can enter here. This can help with ordering, as the Title of your item may not match exactly with the distributor's.",
    grid__ShoppingCart__family: 'The family of alcohol that an item is from (Wine, Beer, Liquor, etc.)',
    grid__ShoppingCart__inventoryValue: 'The value of the current “Stock” count.',
    grid__ShoppingCart__orderFormat: 'Whether you order an item by the unit or the case. You can change the format at any time by clicking in the column.',
    grid__ShoppingCart__par: 'Your ideal stock level for an item, that you can adjust at any time by clicking in the field.',
    grid__ShoppingCart__parSuggested: "Calculated by an item's consumption from the previous 6 weeks. This is your WISK recommended ideal stock level.",
    grid__ShoppingCart__pendingPurchaseOrders: "If you have an open purchase order for an item, it shows how much stock you're expecting to receive when the order comes in.",
    grid__ShoppingCart__perpetual:
      'Takes the "Stock" count and subtracts your sales since the most recent inventory to provide a theoretical current stock count. You can click the "i" icon to see the sales for an item since the most recent inventory.',
    grid__ShoppingCart__quantityToOrder: 'The amount that will be ordered. The "Order Format" displays on the left.',
    grid__ShoppingCart__stock:
      'The stock from your most recent inventory count. This number increases with invoices and decreases with returns/adjustments. You can see where the item was counted, and any invoices/returns since that inventory by hovering over the “i” icon.',
    grid__ShoppingCart__suggestedOrder:
      'The recommended amount to order, based on the "Stock" value and Your Par. \n\nIf you select the "Based on your par - Stock" option under pre-fill, it will add that amount to your cart / order.',
    grid__ShoppingCart__title: 'The name of the WISK Item.',
    grid__ShoppingCart__total: 'The total dollar value of the item being ordered. This is based on the quantity, and the current cost (Cost Unit or Cost Case) of the item.',
    grid__ShoppingCart__wiskId: 'The unique identifier number for the item in WISK',
    grid__StockGrid__current_stock: 'Takes the "Stock" count and subtracts your sales since the most recent inventory to provide a theoretical current stock count.',
    grid__StockGrid__daily_consumption: 'The average daily consumption of an item based on the last 6 weeks of data for your venue.',
    grid__StockGrid__recommended_stock: 'This is the amount of stock that WISKs recommends you have on hand. It compares your ideal stock days to your daily/weekly consumption. This is also called your WISK Par.',
    grid__StockGrid__stock: 'This compares your recommended stock to your current stock level to see if you have more on hand than you need. \n\nOver Stock = Current Stock - Recommended Stock.',
    grid__StockGrid__stock_days:
      'The number of days it would take to reduce the current stock count of the item to 0 based on daily consumption.\n\nNote: If it displays as "Deadstock", there is no consumption of the item.',
    grid__StockGrid__stock_weeks:
      'The number of weeks it would take to reduce the current stock count of the item to 0 based on weekly consumption.\n\nNote: If it displays as "Deadstock", there is no consumption of the item.',
    grid__StockGrid__stockCost: 'The value of your over stock.  Over Stock Cost = Over Stock * Cost Unit',
    grid__StockGrid__title: 'The name of the WISK Item.',
    grid__StockGrid__weekly_consumption: 'The average weekly consumption of an item based on the last 6 weeks of data for your venue.',
    'grid__test page__title': 'This type of selection is used rarely, but is useful in some situations. For most cases, we use the multiselect type dropdown.',
    grid__UserEmailReports__billing_updates: 'If a subscription payment fails, users with this option check receive an email notification',
    grid__UserEmailReports__daily_summary: "A summary of your venue's activity from yesterday, including inventory counts, orders placed, invoices received, price changes and more.",
    grid__UserEmailReports__draft_invoice: 'If a subscription payment fails, users with this option check receive an email notification',
    grid__UserEmailReports__inventory: 'This is sent out every time an inventory count is completed and includes an attached copy of your inventory, consumption, and variance report (if your POS is integrated).',
    grid__UserEmailReports__invoice: 'When an invoice is added, you can send a confirmation email to all users selected to receive it. This email shows the details of the invoice.',
    grid__UserEmailReports__item_distributor_cost_alert: "This alert email is sent when an item's cost exceeds its set alert threshold.",
    grid__UserEmailReports__pos_cost_alert: "This alert email is sent when a POS Item's cost percentage exceeds its set alert threshold.",
    grid__UserEmailReports__purchase_order: 'When an order email is sent to a distributor, all users sent to receive this email are cc-ed automatically.',
    grid__UserEmailReports__stock_alert: 'When the “Real-time Stock” of an item meets or goes below the “Stock Alert” level, an email notification is sent.',
    grid__UserEmailReports__weekly_summary: "A summary of your venue's activity for the past week, including inventory counts, orders placed, and invoices received and more.",
    grid__Variance__beginningInventory: 'The count of an item in the "From" inventory.',
    grid__Variance__beverageCostPercentActual:
      'This is the cost percentage for an item overall based on the actual consumption during the inventory period. \n\nActual Cost of Sales (%) = Cost of Goods Sold (COGS) ($) / Sold ($).',
    grid__Variance__beverageCostPercentTheoretical:
      'This is the theoretical cost percentage if you used exactly what was mapped to your recipe, in other words, if there was no variance. \n\nTheoretical Cost of Sales (%) =(Cost / Unit * Sold (units) / Sold ($). \n\nThe closer your Actual Cost of Sales (%) is to the Theoretical Cost of Sales (%) the better.',
    grid__Variance__category: 'This is the sub-category of an item, based on the Family.',
    grid__Variance__consumed: 'The amount of an item consumed during the inventory period converted to units. \nConsumed (units) = Beginning Inventory + Movements - Ending Inventory.',
    grid__Variance__consumedUM: "The amount consumed in the item's unit of measurement during the inventory period.\n\nConsumed (UM) = Beginning Inventory + Movements - Ending Inventory",
    grid__Variance__cost: "The dollar value of an item's consumption. It's the amount of an item consumed, multiplied by the Cost/Unit.",
    grid__Variance__costPerOz: 'The cost / unit of an item divided by the volume.',
    grid__Variance__costUnit: 'The price you pay per unit to your distributors. This amount should be before taxes are applied',
    grid__Variance__endingInventory: 'The count of an item in the "To" inventory.',
    grid__Variance__family: 'The family of alcohol that an item is from (Wine, Beer, Liquor, etc.)',
    grid__Variance__movements: 'The sum of invoices and returns/adjustments in the inventory period for an item.',
    grid__Variance__retailPrice:
      "How much a full unit of an item would sell for in your venue. This amount is generated automatically based on your POS mapping and sales data. You may enter a value here that will be used in the event the item isn't used in any POS Items but still has consumption.",
    grid__Variance__shrinkage:
      'Shrinkage percent is how much of your overall inventory at cost was lost due to the variance.  This displays at the family and category level, not for individual items. \n\nShrinkage (%) = Variance Cost / Beginning Inventory Cost.',
    grid__Variance__shrinkageVolume:
      'Shrinkage Volume (%) is how much of your overall inventory in volume was lost due to the variance.  This displays at the family and category level, not for individual items. \n\nShrinkage Volume (%) = Variance Oz / Beginning Inventory Oz. \n\nThis is a more useful metric for venues such as brewpubs, that produce their own alcohol at a lower cost. Seeing your shrinkage in volume may be a better measure when your costs are low.',
    grid__Variance__sold: 'The total dollar value of sales for an item.',
    grid__Variance__soldBottles:
      "The number of units sold based on the sales data received from your POS system and using the POS items and recipes you have set. The amount sold in the item's unit of measurement is converted into full units to get this number.",
    grid__Variance__soldCost:
      "The dollar value of what was sold at cost (based on your POS Items recipes).  \n\nCost of goods sold ($) = Cost per Unit * Sold\n\nThis differs from Cost of Goods Sold (COGS) because this is theoretical, based on the recipes you've added, instead of the inventory count.  \n\nIt's what your consumption should be if you're using exactly what is mapped to the recipe.",
    grid__Variance__soldUM: "The amount sold in the item's unit of measurement. This is based on the sales data received from your POS system and using the POS items and recipes you have set.",
    grid__Variance__title: 'The name of the WISK Item.',
    grid__Variance__variance:
      'The difference between what was sold based on the POS Item recipes, and how much was actually consumed (based on inventory count). \n\nVariance (units) = Sold (units) - Consumed (units). \n\nA negative value indicates a loss (you consumed more than you sold), whereas a positive value indicates a surplus (you sold more than you consumed).',
    grid__Variance__varianceCost: 'The at-cost dollar value of the variance for an item. This is calculated by multiplying the variance by the cost/unit.',
    grid__Variance__variancePercentage: 'The percentage of consumption that is a variance.\n\nVariance % = (Variance (UM) / Consumed (UM)) * 100\n\nThe lower the variance %, the higher your profitability on the item.',
    grid__Variance__varianceRetail:
      "An approximation of what the Variance Cost would've sold for based on your POS Items and recipes. It takes a weighted average of all the POS Items an item is an ingredient in and calculates the retail value of the variance.",
    grid__Variance__varianceUM:
      'The difference between what was sold based on the POS Item recipes, and how much was actually consumed based on the inventory count.\n\nVariance (UM) = Sold (UM) - Consumed (UM)\n\nA negative value indicates a loss (you consumed more than you sold), whereas a positive value indicates a surplus (you sold more than you consumed).',
    grid__Variance__wiskId: 'The unique identifier number for the item in WISK',
    grid__VenueBottles__addedOn: 'The date and time the item was added.',
    grid__VenueBottles__barcodes: 'The barcodes on file for the item. Having correct barcodes is important when scanning items during inventory counts.',
    grid__VenueBottles__batches: 'The batched POS Item linked to the item to determine the ratio of ingredients.',
    grid__VenueBottles__caseSize: 'How many units are in a case of this item. Setting a case size can help when counting your inventory and placing orders and receiving deliveries.',
    grid__VenueBottles__category: 'This is the sub-category of an item, based on the Family.',
    grid__VenueBottles__costCase: 'How much a case of an item costs based on the Case Size and Cost /Unit.',
    grid__VenueBottles__costPerOz: 'The cost/unit of an item divided by the volume.',
    grid__VenueBottles__costUnit: 'The price you pay per unit to your distributors. This amount should be before taxes are applied.',
    grid__VenueBottles__country: 'The country of origin of an item.',
    grid__VenueBottles__creationSource: 'How the item was added to the venue',
    grid__VenueBottles__date: 'The date and time the item was added to your venue. You can use this to spot any duplicate items that were added accidentally.',
    grid__VenueBottles__distributor: 'The distributor that you order/ receive the item from. If you get this product from more than one distributor, you can change the distributor at any time by clicking in the field.',
    grid__VenueBottles__distributorCode: "Some distributors have unique codes for items that you can enter here. This can help with ordering, as the Title of your item may not match exactly with the distributor's.",
    grid__VenueBottles__emptyWeights: "The weight of the item's container when empty.",
    grid__VenueBottles__excludedFromVariance: "This item is excluded from variance calculations.  These are usually items that you inventory but don't sell.",
    grid__VenueBottles__family: 'The family of alcohol that an item is from (Wine, Beer, Liquor, etc.)',
    grid__VenueBottles__inventoryAlert: "If your real-time stock goes below the entered value, you'll receive an email alert. This is helpful for keeping track of items you're running low on stock.",
    grid__VenueBottles__inventoryValue: 'The value of the current “Stock” count multiplied by the cost per unit.',
    grid__VenueBottles__inventoryValueRealTime: 'The value of the "Real-time stock" count multiplied by the cost per unit.',
    grid__VenueBottles__invoicesCount: 'The number of invoices added that include the item.',
    grid__VenueBottles__locations:
      'This is an optional feature where you can tag an item with inventory areas. This allows you to generate an inventory sheet for areas where counting using the app may not be practical.',
    grid__VenueBottles__measurement: 'The size of the WISK Item. It includes the unit of measurement and the quantity.',
    grid__VenueBottles__measurementType: 'The measurement type for the item. Volume, Weight, or Unit.',
    grid__VenueBottles__orderFormat: 'Whether you order an item by the unit or the case. You can change the format at any time by clicking in the column.',
    grid__VenueBottles__orderGuide: 'If the box is checked, this item has been added to your order guide',
    grid__VenueBottles__par: 'Your ideal stock level for an item, that you can adjust at any time by clicking in the field.',
    grid__VenueBottles__parSuggested: "Calculated by an item's consumption from the previous 6 weeks. This is your WISK recommended ideal stock level.",
    grid__VenueBottles__parSuggestedSales: "Calculated by an item's sales from the previous 6 weeks. This is your WISK recommended ideal stock level.",
    grid__VenueBottles__pendingPurchaseOrders: "If you have an open purchase order for an item, it shows how much stock you're expecting to receive when the order comes in.",
    grid__VenueBottles__perpetual:
      'Takes the "Stock" count and subtracts your sales since the most recent inventory to provide a theoretical current stock count. You can click the "i" icon to see the sales for an item since the most recent inventory.',
    grid__VenueBottles__retailPrice: 'How much a full unit of an item would sell for in your venue. This amount is generated automatically based on your POS mapping and sales data.',
    grid__VenueBottles__stock:
      'The stock from your most recent inventory count. This number increases with invoices and decreases with returns/adjustments. You can see where the item was counted, and any invoices/returns since that inventory by hovering over the “i” icon.',
    grid__VenueBottles__title: 'The name of the WISK Item',
    grid__VenueBottles__variantsCount: 'The total number of item variations that the item has.',
    grid__VenueBottles__volume: 'The volume of the WISK Item displayed in ml.',
    grid__VenueBottles__weights: "The full weight of the item and the container it's stored in. This is mostly used for volume-based items (liquids) to determine how much remains in partial units.",
    grid__VenueBottles__wiskId: 'The unique identifier number for the item in WISK',
    grid__VenueBottles__yields: 'The yield percentage after an item has been processed and is ready to be used in recipes (trimming meat, peeling vegetables, etc.)',
    grid__WiskVarianceByCategory__beverage_cost:
      'This is the beverage cost for the Category overall based on the actual consumption during the inventory period. \n\nBeverage Cost = Cost of Goods Sold (COGS) ($) / Sold ($).',
    grid__WiskVarianceByCategory__category: 'This is the sub-category of an item, based on the Family.',
    grid__WiskVarianceByCategory__consumption:
      "The overall dollar value of consumption at cost for the Category from the most recent inventory period. \n\nIt's the consumption of each item in the Category, multiplied by it's Cost per unit. The dollar value of each item's consumption is then added together.",
    grid__WiskVarianceByCategory__family: 'The family of alcohol that an item is from (Wine, Beer, Liquor, etc.)',
    grid__WiskVarianceByCategory__sale_cost:
      "The dollar value of what was sold at cost (based on your POS Items recipes).  \n\nThis differs from Cost of Goods Sold (COGS) because this is theoretical, based on the recipes you've added, instead of the inventory count.  \n\nIt's what your consumption should be if you're pouring exactly what is mapped to the recipe.",
    grid__WiskVarianceByCategory__sale_sold:
      'The total sales for the Category This number is calculated based on the mapping of your POS Items.\n\nIf a POS Item contains ingredients that belong to multiple categories, the sales are allocated proportionately based on the ratio of ingredients.',
    grid__WiskVarianceByCategory__theoretical_beverage_cost:
      'This is the target beverage cost if you poured exactly what was mapped to your recipes. In other words, if there was no variance. \n\nTheoretical Beverage Cost = Cost of goods sold ($) / Sold ($)\n\nThe closer your Beverage Cost is to the Theoretical Beverage Cost, the better.',
    grid__WiskVarianceByCategory__variance: 'The at-cost dollar value of the variance for the Category. \n\nVariance Cost ($) = Cost of goods sold ($) - Cost of Goods Sold (COGS) ($)',
    grid__WiskVarianceByCategory__variance_retail: "An estimate of what the Variance Cost would've sold for at retail based on your POS Items and recipes.",
    grid__WiskVarianceByFamily__beverage_cost:
      'This is the cost percentage for the Family overall based on the actual consumption during the inventory period. \n\nCost Percentage = Cost of Goods Sold (COGS) ($) / Sold ($).',
    grid__WiskVarianceByFamily__consumption:
      "The overall dollar value of consumption at cost for the Family from the most recent inventory period. \n\nIt's the consumption of each item in the Family, multiplied by it's Cost per unit. The dollar value of each item's consumption is then added together.",
    grid__WiskVarianceByFamily__name: 'The family that an item is from (Wine, Beer, Liquor, etc.)',
    grid__WiskVarianceByFamily__sale_cost:
      "The dollar value of what was sold at cost (based on your POS Items recipes).  \n\nThis differs from Cost of Goods Sold (COGS) because this is theoretical, based on the recipes you've added, instead of the inventory count.  \n\nIt's what your consumption should be if you're using exactly what is mapped to the recipe.",
    grid__WiskVarianceByFamily__sale_sold:
      'The total sales for the Family. This number is calculated based on the mapping of your POS Items.\n\nIf a POS Item contains ingredients that belong to different families, the sales are allocated proportionately based on the ratio of ingredients.',
    grid__WiskVarianceByFamily__theoretical_beverage_cost:
      'This is the theoretical cost percentage if you used exactly what was mapped to your recipes. In other words, if there was no variance. \n\nTheoretical Cost Percentage = Cost of goods sold ($) / Sold ($)\n\nThe closer your Cost Percentage is to the Theoretical Beverage Cost, the better.',
    grid__WiskVarianceByFamily__variance: 'The at-cost dollar value of the variance for the family. \n\nVariance Cost ($) = Cost of goods sold ($) - Cost of Goods Sold (COGS) ($)',
    grid__WiskVarianceByFamily__variance_retail: "An estimate of what the Variance Cost would've sold for at retail based on your POS Items and recipes.",
    Group:
      'Please review the following points before continuing:\n\n    Grouping is intended for items that can be used interchangeably in all the same Menu items in your venue\n    The item you selected as the default item will remain, and the others will be archived and added as variations of that item\n    As part of item grouping, your "Stock" count for the resulting item won\'t be accurate until you take your next inventory count. It\'s advised that you group items just prior to an inventory to have the least impact.\n    The grouping applies as of this point forward. All of your past invoices and inventory counts will display the items before they were grouped (if applicable).\n\nThis operation cannot be undone and should be used with extra care.',
    helpTextInventoryEntryInputPartial: "For a full unit, choose the 'Unit' option.",
    itemsMergeGroupInfoTooltip: 'This function is for merging items together that are exact duplicates.',
    itemsMergeInfoTooltip: 'This function allows you to group together different formats of the same item. This is an advanced feature and we recommend reading this article before proceeding:',
    measurementComponent: 'The size of the WISK item. It includes the unit of measurement and the quantity (for example, 750ml, 12oz, 1kg, 10lbs, etc.)',
    measurementConversionTooltip:
      'Based on the measurement entered above, you must enter how much of the default item variation it converts to.This makes it so you can count this variation during inventory, and it gets converted to the default for reporting purposes.\n\nFor example, if you purchase apples by the pound, but want to be able to count individual units, you can create an item variation of 1 unit, and enter the average weight of 1 unit in the Measurement conversion field.\n\nThis conversion does not apply to recipes. If you want to convert an item from one measurement type to another (weight to volume or vice versa) you must create a batch.',
    movementInvoiceNumberDuplicate: "An invoice with the same number already exists for this distributor. Please check the number and your existing invoices to make sure this isn't a duplicate.",
    movementItemAddCreateItemVariantHelp: 'Please select an item in the "Add Item" field for this button to become enabled. You can then create a new variation for the selected item.',
    newVenueCreationInfoForExistingUser: "New venues require a new subscription. After you've created the venue, you'll need to select a plan and enter payment before continuing.",
    posItemNeedReviewTitleChanged: 'Read this article for more info.',
    purchaseOrderToIntakeHelpInfo: 'Select one of the options available.',
    scannedInvoiceInfoMessage:
      'Items highlighted in red require action before you can convert the invoice. These items are either:\n\n1) New to your venue and need to be added. To create a new item click "Search/Add"\n2) Missing information (the number of units, cost, etc)\n3) Weren\'t recognized during the scanning process\n\nSee the article linked below for more information:',
    scannedInvoicesPDFManagerMessage:
      'Upload and organize multiple PDFs and images to create new invoices. Existing invoices will be archived, and new ones will be generated based on your arrangement. Drag, drop, and rearrange pages as needed to customize your invoices.',
    scannedInvoicesPDFSplitMessage: 'The current invoice will be archived and for each pdf resulted from the split a new invoice will be created.',
    scannedInvoicesUploadMessage: 'Upload and organize multiple PDFs and images to create new invoices. Drag, drop, and rearrange pages as needed to customize your invoices.',
    shoppingCartIdealStockDaysInfo:
      'Ideal stock days are used to calculate your WISK Par. WISK looks at your last 6 weeks of consumption per item to recommend what you should order to have enough stock to last the ideal stock days.',
    varianceBeverageCostTooltip: 'The overall beverage/food cost for the inventory period. Beverage/Food Cost % = Consumption / Sales',
    varianceConsumptionTooltip:
      'How much product was used at cost in the venue during the inventory period. Consumption = Starting Inventory + Invoices - Ending Inventory.\n\nNote: The consumption of any item marked as "Excluded from Variance" isn\'t included in this calculation.',
    varianceCostTooltip: 'The sum of the Variance Cost for each item. Variance Cost = Cost per Unit * Variance. A negative Variance Cost indicates a loss.',
    varianceRetailTooltip: "The theoretical value that the Variance Cost could've sold for based on your POS Item recipes.",
    varianceSalesTooltip:
      'This represents the sales of mapped POS Items for the selected inventory period. If you have unmapped items you will see a difference between the number here and what is on the "Sales" page.  \n\nThis number doesn\'t include sales of unmapped POS Items, archived POS Items, or any POS Items that contain an item that is marked as "Excluded from Variance"',
    varianceShrinkagePercentageTooltip: 'The percent reduction in your starting inventory due to Variance Cost. Shrinkage Percentage = Variance Cost / Beginning Inventory.',
    variantDefaultInfoTooltip: "The selected item variation's measurement is the default for inventory and pars. The stock count from an inventory is displayed as units of the default item variation."
  },
  groupTransferRequestStatuses: {
    canceled: 'Canceled',
    declined: 'Declined',
    draft: 'Draft',
    in_preparation: 'In preparation',
    received: 'Received',
    requested: 'Requested',
    sent: 'Sent'
  },
  in_preparation: 'In Preparation',
  intake: 'Invoice',
  monday: 'Monday',
  not_received: 'Not received',
  not_sent: 'Not sent',
  pending_approval: 'Pending approval',
  received: 'Received',
  received_manual_intake: 'Received with manual invoice',
  requested: 'Requested',
  return: 'Return',
  saturday: 'Saturday',
  sending: 'Sending',
  sent: 'Sent',
  sunday: 'Sunday',
  thursday: 'Thursday',
  tplAuthPassRecoverConfirm: 'Follow the instructions sent to {a} to recover your password',
  tplAuthSignupInvitedMessage: '{a} invited you to the venue {b}',
  tplBottlesMergeBottlesList: 'Items to merge or group with {a}',
  tplCartBuilderAddBottleTo: 'Add item to {a}',
  tplConfirmHistoryChangingOperation: "Changing the {a} updates the item in all inventories, invoices, and other movements in the unlocked inventory period. Data in locked inventory periods won't be changed.",
  tplConfirmItemMeasurementGreaterThanKgOrLThreshold: 'The entered measurement of {a} is unusually high for this item. Please confirm if this is correct.',
  tplConfirmItemMeasurementUnit:
    '\n  Warning: You have selected {a} for this item.\n\nAre you sure this is intentional? Selecting {a} could cause issues with inventory tracking, especially for items that are typically managed by weight or in packs. Consider whether a different measurement would be more accurate.\n\nPlease confirm to proceed with this choice.\n  ',
  tplConfirmItemMeasurementVariationBy10Percent: 'Are you sure you want to change the measurement from {a} to {b}? This is a large change in measurement.',
  tplConfirmMovementEditSendEmail: 'Did you finish the {a}? Would you like to send a confirmation email?',
  tplConfirmMovementEditSendTransferOut:
    "\n  After sending the transfer out you can't make changes to it. Please confirm the date/time and item quantities are correct before sending.\n  The transferred items will be removed from {a} immediately and a draft invoice will be created in {b}.\n  The draft invoice must be approved in the receiving venue to complete the transfer.\n  ",
  tplConfirmScannedInvoicesConvertTotalsAlmostMatch:
    "Are you ready to convert this draft to a WISK invoice?\n\n  Please note the totals don't match by 0.01. This may be due to rounding differences or bad input of data. Make sure it's okay to convert the draft invoice even though the totals don't match.",
  tplConfirmScannedInvoicesMove: 'Please confirm you want to move the selected invoices to one of your other venues. This action will archive them in the current venue and import them to {a}.',
  tplConsumptionByLocationForItem: 'Consumption by area for {a}',
  tplConsumptionMovementsForItem: 'Invoice for {a} at {b}',
  tplCostUnitInfoIntakes: 'Invoices for {a}',
  tplDashboardDateBy: '{a} by {b}',
  tplDashboardDateScheduled: 'Scheduled for {a}',
  tplDelinquentWarning: 'Your account has {a} invoices on file amounting to {b}. We have limited access to your account until a valid form of payment is added.',
  tplDistributorItems: 'Items for {a}',
  tplDistributorItemsInVenue: '{a} items in your venue',
  tplDistributorItemsInWisk: "Showing {a} items from WISK's database for this distributor",
  tplDistributorPublincLinked: 'Linked to WISK distributor: {a}',
  tplDistributorsPortalCredentialsSaved: 'Portal credentials saved for user {a}',
  tplDraftInvoiceChatConversationTooltip: '{a} messages. Last message on {b}:\n   {c}',
  tplDraftInvoiceChatConversationTooltipShort: '{a} messages. Last message on {b}',
  tplDraftInvoiceChatSendMessageTooltip: 'To submit press {a}',
  tplEmailsCountFoundInBulkText: 'Found {a} email addresses',
  tplFamiliesCategoriesBottlesUsingCategory: 'The following items are using category {a}',
  tplFamiliesCategoriesFamilyRename: 'Rename family {a}',
  tplFamiliesCategoriesFloatingSelectedCount: 'categories selected of {a}',
  tplFilterArchivedFoundItems: 'We found {a} archived items. Click here to view them.',
  tplGenericForEmail: 'for <strong>{email}</strong>',
  tplGenericLoadingInProgress: 'Data loading in progress for {b}: {a}%',
  tplGLAccountAccountingMappingMaxPercentWarning: 'Available: {a}%',
  tplGLAccountAccountingMappingTotalRemaindedPercentWarning: 'Remaining: {a}% out of 100%',
  tplIndependentInventoriesEditTitle: 'Partial inventory by {a}',
  tplInventoriesEditPartialComponent: 'Sub units of {a}',
  tplInventoriesEditPartialComponentHelpText: 'Input value from 1 to {a}',
  tplInventoriesEditTitle: 'Inventory by {a}',
  tplInventoriesEditTitleOngoing: 'Ongoing inventory by {a}',
  tplInventoriesFinishedAtBy: 'Finished {a} by {b}',
  tplInventoriesLockedWhoWhen: 'Locked by {a} at {b}.',
  tplInventoriesStartedAtBy: 'Started {a} by {b}',
  tplInventoriesSubmitAllWithSingleArchivedItem: 'This area contains "{a}" that was archived since your last inventory count. The previous counts for this area, excluding this item, have been submitted.',
  tplInventoriesWeightInputValidation: 'Please input a weight between {a} g and {b} g',
  tplInventoryDetailsScheduledLock: 'This inventory will be locked at {a}',
  tplInventoryScheduledNext: 'Next inventory scheduled for {a}',
  tplItemCostDiscountDisplay: 'Original: {a}, Discount: {b}',
  tplItemCostDiscountDisplayWithUM: 'Original: {a}/{c}, Discount: {b}',
  tplItemSwapConfirmMessage: 'Are you sure you want to replace {a} with {b} in the selected items?',
  tplItemSwapTitle: 'Swap {a}',
  tplMovementAdjustmentReasonTitle: 'Depletion reason: {a}',
  tplMovementEditDiscountPerUnitOrCase: 'Disc. / {a}',
  tplMovementEditPerUnitOrCase: 'per {a}',
  tplMovementExtraLineReasonNew: 'New {a} reason',
  tplMovementsAnalytics: '{a} analytics',
  tplMovementsFloatingSelectedCount: 'lines selected of {a}',
  tplOnlineItemSearchDistributor: "Search {a}'s items",
  tplOnlineItemSearchMinChars: 'Please input {a} or more characters',
  tplOnlineItemSearchPartnerVenue: "Search {a}'s items",
  tplOrderPagePurchaseOrdersTitleCount: 'Step 3: Purchase Orders ({a})',
  tplOrderPageShoppingCartTitleCount: 'Step 2: Your cart ({a} items)',
  tplParSuggestedInfoStockEnoughForDays: 'Stock enough for {a} days / {b} weeks',
  tplPOSItemsNeedReviewTitleChanged: 'The title has changed from {a} to {b}. Please review and confirm the ingredients are still correct.',
  tplPOSItemsNotificationCostError: 'You have {a} POS items with cost percentage higher than 100%.',
  tplPOSItemsNotificationSelectedFilter: 'Displaying items filtered by "{a}" filter.',
  tplPOSItemsNotificationUnmapped: 'You have {a} unmapped items.',
  tplPurchaseOrdersConvertIntakeTitle: 'New invoice to {a}',
  tplPurchaseOrdersEmailSubject: 'Order from {a} to {b}',
  tplPurchaseOrdersFloatingSelectedCount: 'order(s) selected of {a}',
  tplPurchaseOrdersStatusApproved: 'Approved by {a} on {b}',
  tplPurchaseOrdersStatusDeclined: 'Declined by {a} on {b}',
  tplPurchaseOrdersStatusRequested: 'Requested by {a} on {b}',
  tplReportsAnalyticsGoToPage: 'Go to {a} page',
  tplRoleRenameValidationLabel: 'Please enter new name for the role {a}',
  tplSalesLastUploadWasAtDate: 'The last manual sales upload was for the period of: {formatted}',
  tplSalesRefreshImportMessageMinImportDate: "You can't import sales older than {a} days for this POS type.",
  tplSalesUploadConfirmTitle: 'Confirm sales upload from {from} to {to}',
  tplScannedInvoiceDateInFutureDescription: 'The selected date of {a} is in the future! Most invoices are dated in the recent past. Please recheck the date format and confirm you want to set this date.',
  tplScannedInvoicePreviousPricePerUnitWarning: 'It was previously {a} per unit.',
  tplScannedInvoicePricePerUnitDecreased: 'The cost per unit has decreased by',
  tplScannedInvoicePricePerUnitIncreased: 'The cost per unit has increased by',
  tplScannedInvoicesEdit: 'Invoice scanned on {a}',
  tplScannedInvoicesEmailAddress: 'Send your invoices to the following email address: {a}',
  tplScannedInvoicesUploaderModeDuplicateFileWarning: 'The file "{a}" is already in the list and will not be added again',
  tplStockGridStock: '{a} stock',
  tplStockGridStockCost: '{a} stock cost',
  tplStripeCardPlanPriceDueIn: '{a} {b} due in {days} days',
  tplStripeCardPlanPriceDueNow: '{a} {b} due now',
  tplStripeCardPlanPriceDueOn: '{a} {b} due on {c}',
  tplStripeCardPlanPriceMonthly: '{a} {b}/mo paid monthly',
  tplStripeCardPlanPriceSavePerYear: 'save {a} {b} per year',
  tplStripeCardPlanPriceYearly: '{a} {b}/mo paid yearly',
  tplStripeCardSubscribeTo: 'Subscribe to {a}',
  tplStripeCardSubscriptionFreeTrial: "You can cancel anytime. Take the next {a} days to use WISK for FREE, and see if it's right for you.",
  tplSubrecipeRecipePDFFor: 'Recipe book for {a}',
  tplUsersInviteUsersToVenue: 'Invite users to venue {a}',
  tplUsersInviteUserToVenues: 'Invite user {a} to multiple venues',
  tplUsersInviteUserToVenuesSelected: 'Invite user {a} to selected venues',
  tplValidationCustomFieldLabel: 'A custom field with the same name already exists for {a}',
  tplValidationTooHigh: '{a} cannot be higher than the {b}',
  tplVarianceConsumptionUM: 'Consumption ({a})',
  tplVarianceDashboardSelectedInventory: 'This data is based on the last two inventories in the selected range. ({a})',
  tplVarianceDetailsConsumption: 'Consumption: <span style="font-weight: 700;">{a}</span> units ({b} {c})',
  tplVarianceDetailsDeliveries: 'Invoices:  <span style="font-weight: 700;">{a}</span> units ({b} {c})',
  tplVarianceDetailsDepletion: 'Depletion: <span style="font-weight: 700;">{a}</span> units ({b} {c})',
  tplVarianceDetailsInventoryEnd: 'End inventory from {a}:  <span style="font-weight: 700;">{b}</span> units ({c} {d})',
  tplVarianceDetailsInventoryStart: 'Start inventory from {a}:  <span style="font-weight: 700;">{b}</span> units ({c} {d})',
  tplVarianceSalesUM: 'Sales ({a})',
  tplVarianceUM: 'Variance ({a})',
  tplVarianceUnmappedMessage: 'You have {a} unmapped POS Items, representing ${b} ({c}) of the total sales for the selected interval. ',
  tplVenueBottlesMeasurementConvertsTo: 'Converts to {a}',
  tplVenueBottlesVariantFor: 'Item Variation for {a}',
  tplVenueBottlesViewMovementDetail: 'Date: {a}, quantity: {b}',
  tplVenueOperationsCentralItemsList: 'Populate this venue with a shared WISK Items list from {a}',
  tplVenueOperationsCentralPOSItemsList: 'Populate this venue with a shared POS Items list from {a}',
  tplVenueOperationsCopyAllList: 'Populate this venue with a copy of WISK Items, Batches and POS Items from {a}',
  tplVenueOperationsCopyBatchesList: 'Populate this venue with a copy of only Items and Batches from {a}',
  tplVenueOperationsCopyItemsList: 'Populate this venue with a copy of only Items from {a}',
  tplVenueOperationsStartTrialButton: 'Start {a} Day Free Trial',
  tplVenueOperationsTrialDaysLeft: 'Trial days left: {a}',
  tplVenueTransferRequestTitle: 'Venue transfer request to {a} - {b}',
  tplWiskExportPageCount: 'Page {a} of {b}',
  tplWiskGridRemoveViewMessage: 'Are you sure you want to remove this report: "{a}"?',
  tplWiskGridRenameViewMessage: 'Please enter new name for the report {a}',
  tplWiskGridRowSelected: '{a} row selected',
  tplWiskGridRowsSelected: '{a} rows selected',
  tplWiskItemsGridCustomizeTable: 'Customize "{a}" report',
  transfer: 'Transfer between areas',
  transfer_in: 'Transfer In',
  transfer_out: 'Transfer to Partner Venue',
  tuesday: 'Tuesday',
  txtAccountingAccount: 'Accounting account',
  txtAccountingAccounts: 'Accounting accounts',
  txtAccountingDistributor: 'Accounting distributor',
  txtAllergens: 'Allergens',
  txtAllergensManage: 'Manage Allergens',
  txtAllergensNew: 'Add allergen',
  txtAllergensTitle: 'Modify allergen title',
  txtAuthConfirmPass: 'This operation requires password verification',
  txtAuthEmailChangeBySuperAdminConfirm: 'User will have to sign in using the new email',
  txtAuthEmailInput: 'Please input your email address',
  txtAuthErrorMessage: 'Your request to reset your password has expired or the link has already been used',
  txtAuthErrorTitle: 'Try resetting your password again',
  txtAuthGetStarted: 'Get Started',
  txtAuthHaveAccount: 'Have an Account?',
  txtAuthInvalidCredentials: 'Invalid credentials. Please check your email and password.',
  txtAuthNewPassword: 'New password',
  txtAuthPass1: 'Password',
  txtAuthPass2: 'Confirm password',
  txtAuthPassChange: 'Change password',
  txtAuthPassChangedMessage: 'Your password was changed successfully',
  txtAuthPassCreate: 'Create password',
  txtAuthPassDescription: '(Minimum 6 characters)',
  txtAuthPassInputCurrent: 'Please input your current password',
  txtAuthPassInputNew: 'Please input new password',
  txtAuthPassLength: 'Password must be at least 6 characters',
  txtAuthPassMatch: 'Password and confirm password must be the same',
  txtAuthPassRecover: 'Recover password',
  txtAuthPassRecoverConfirmTitle: 'Check your email',
  txtAuthPassRecoverQuestion: 'Trouble signing in?',
  txtAuthPassSet: 'Set password',
  txtAuthPhoneDescription: '(Please include area code)',
  txtAuthResetedMessage: 'You can now sign in with your new password',
  txtAuthResetedTitle: 'Password changed',
  txtAuthResetPassword: 'Reset Password',
  txtAuthResetPasswordEmptyInput: 'Enter your password',
  txtAuthResetPasswordTitle: 'Reset your password',
  txtAuthResetWeakPassword: 'Strong passwords have at least 6 characters and a mix of letters and numbers',
  txtAuthSignIn: 'Sign In',
  txtAuthSignInHere: 'Sign in here',
  txtAuthSignInOrSignUpHere: 'Sign into your WISK account, or sign up',
  txtAuthSignNewToWISK: 'New to WISK?',
  txtAuthSignOut: 'Sign out',
  txtAuthSignUp: 'Sign Up',
  txtAuthSignupInvitedAcceptedMessage: 'This invitation has already been accepted',
  txtAuthUserAdd: 'Add user',
  txtAuthUserInvited: 'An invitation has been sent',
  txtAuthUserInvitedFound: 'We found a user with this email address and sent an invitation to join the selected venue',
  txtAuthUsersInvited: 'An invitation has been sent to all the emails in the list',
  txtAuthUsersInvitePlaceholder: 'Please input emails separated by comma, semicolon, space, etc...',
  txtAuthWelcomeBack: 'Welcome back',
  txtAuthBusinessRole: 'Business role',
  txtAuthBusinessRoleAdd: 'Add business role',
  txtAuthBusinessRoleAddDescription: 'Type to add a new business role',
  txtAuthBusinessRoles: {
    'Owner / Partner': 'Owner / Partner',
    'General Manager': 'General Manager',
    'Chef / Kitchen Manager': 'Chef / Kitchen Manager',
    'Front of House Manager': 'Front of House Manager',
    'Bartender / Server': 'Bartender / Server',
    'Admin / Accounting': 'Admin / Accounting'
  },
  txtBarcodePrinterConfig: 'Barcode printer config',
  txtBarcodePrinterLabelSize: 'Label size',
  txtBarcodePrinterName: 'Barcode printer',
  txtBarcodePrinterNone: 'No barcode printer selected',
  txtBarcodePrinterSoftware: 'Printer Software: use Dymo Connect > 1.4.2 for both Windows and Mac.',
  txtBookDemoButtonMeeting: 'Book my demo',
  txtBookDemoButtonPrices: 'View our plans',
  txtBookDemoTitle: 'Book a demo to get started',
  txtBottlesGroupOpenResulted: 'This item was grouped with another item and is no longer editable. Click here to see the resulted item variation',
  txtBottlesGroupRangeSelectedMessage: 'Please select at least 2 items to group',
  txtBottlesGroupTitle: 'Group items',
  txtBottlesGroupVolumeValidationMessage: 'The measurement type of the items to be grouped must match',
  txtBottlesMeasurementLockedInfo: 'Item measurement is locked while the dependent data is being recalculated.',
  txtBottlesMergeBottlesAdd: 'Add to the list of items to be merged or grouped',
  txtBottlesMergeBottlesSearch: 'Search for items',
  txtBottlesMergedArchived: 'This item was archived after being merged with another and is no longer editable',
  txtBottlesMergeGroupWaitingMessage: 'The selected items are being grouped. Please check in a few moments.',
  txtBottlesMergeInfoHowTo1: 'Choose item and click on the button',
  txtBottlesMergeInfoHowTo2: ' to use that value in the resulted item ',
  txtBottlesMergeInfoResulted: 'Resulted item',
  txtBottlesMergeOrGroup: 'Merge or group',
  txtBottlesMergeRangeSelectedMessage: 'Please select 2 to 5 items to merge',
  txtBottlesMergeRevert: 'Ungroup',
  txtBottlesMergeStartingBottles: 'Items to be merged',
  txtBottlesMergeStartingBottlesGroup: 'Items to be grouped',
  txtBottlesMergeTitle: 'Merge items',
  txtBottlesMergeTooManySelectedMessage: 'A max of 5 items can be merged or grouped',
  txtBottlesMergeVolumeValidationMessage: 'The measurement type and quantity of the items to be merged must match',
  txtBottlesMergeWaitingMessage: 'The selected items are being merged. Please check in a few moments.',
  txtBottlesVariantGroupDisabled: 'Grouping is not available for variations',
  txtBottlesVariantMergeInfoHowTo1: 'Choose variation and click on the button',
  txtBottlesVariantMergeInfoHowTo2: ' to use that value in the resulted variation ',
  txtBottlesVariantMergeInfoResulted: 'Resulted variation',
  txtBottlesVariantMergeStartingBottles: 'Variations to be merged',
  txtBottlesVariantMergeStartingBottlesGroup: 'Variations to be grouped',
  txtBottlesVariantMergeTitle: 'Merge item variations',
  txtBottlesVariantCurrentDistributorPrice: 'Current distributor price',
  txtBottlesVariantCurrentDistributorPriceExpirationDate: 'Current distributor price expiration date',
  txtBottlesVariantCurrentDistributorPriceExpired: 'Price is expired',
  txtCartBuilderAddToCart: 'Add to cart',
  txtCartBuilderClearDistributorCart: 'Clear Distributor Cart',
  txtCartBuilderFilterWiskPar: 'With suggested order - using WISK par',
  txtCartBuilderFilterYourPar: 'With suggested order - using your par',
  txtCartBuilderInOrderGuide: 'In order guide',
  txtCartBuilderNotInOrderGuide: 'Not in order guide',
  txtCartBuilderOrderGuideItem: 'Order guide item',
  txtCartBuilderOrderGuideItems: 'Order guide items',
  txtCartBuilderOrderGuideItemsClear: 'Remove from order guide items',
  txtCartBuilderOrderGuideItemsSet: 'Mark as order guide items',
  txtCartBuilderPrefill: 'Prefill',
  txtCartBuilderPrefillWiskPar: 'Based on WISK par',
  txtCartBuilderPrefillYourPar: 'Based on your par',
  txtCartBuilderPrefillYourParFullBottles: 'Using your par - full items',
  txtCartBuilderPrefillYourParRealTimeStock: 'Using your par - real-time stock',
  txtCartBuilderPrepareYourOrders: 'Prepare your orders in minutes!',
  txtCartBuilderPrepareYourOrdersExplanation:
    'The ordering module will allow you to place orders to all of your suppliers with accuracy and ease. Manage all your distributors, and distributor contact information so you can send orders via email or SMS.',
  txtCartBuilderStartOrdering: 'Start ordering items with WISK!',
  txtCartBuilderStartOrderingBottles: 'Start ordering items with WISK!',
  txtCartBuilderViewRealTimeStock: 'Real-time stock',
  txtCartBuilderWarningInventoryStarted: 'There is currently an inventory in progress. WISK cannot suggest amounts to order until the inventory is submitted and the Stock count is updated',
  txtCellOrderUnitsEditorRemoveItem: 'Remove item from cart',
  txtChurnedVenueAccessRestricted: 'Access has been restricted',
  txtChurnedVenueDeleteNotice: 'If the account has been inactive for more than 60 days, all data has been deleted.',
  txtChurnedVenueDescription: 'This account no longer has an active subscription, and your access has been restricted.',
  txtChurnedVenueLink: 'To continue using WISK, please schedule a call with our team.',
  txtChurnedVenuePricing: 'Please visit {a} for our latest pricing information.',
  txtChurnedVenueTitle: 'No Active Subscription Found',
  txtConsumptionBottles: 'Consumption (units)',
  txtConsumptionByCategoryResendEmail: 'Send by email',
  txtConsumptionByCategoryResendEmailForAllVenues: 'Send by email for all venues',
  txtConsumptionDollars: 'Consumption ($)',
  txtConsumptionExpandBatches: 'Expand batches',
  txtConsumptionFlowMeterUnits: 'Consumption Flow Meter (units)',
  txtConsumptionInventoryFrom: 'Start Inventory (units)',
  txtConsumptionInventoryFromDollars: 'Start Inventory ($)',
  txtConsumptionInventoryTo: 'End Inventory (units)',
  txtConsumptionInventoryToDollars: 'End Inventory ($)',
  txtConsumptionMovementsDollars: 'Invoice ($)',
  txtConsumptionOpen: 'Go to Consumption',
  txtConsumptionStartTracking: 'Start tracking your consumption!',
  txtConsumptionStayOnTop: 'Stay on top of your bottles',
  txtConsumptionStayOnTopExplanation: "WISK's Venue Items features lets you stay on top of what you have on hand at a quick glance, giving you an overview of your entire beverage operations.",
  txtContactUsAddImage: 'Attach an image',
  txtContactUsAddScreenshot: 'Capture screenshot',
  txtContactUsAddScreenshotDraw: 'Draw on screenshot to highlight issues',
  txtContactUsAddScreenshotDrawClear: 'Clear drawing',
  txtContactUsAddScreenshotDrawStop: 'Stop drawing',
  txtContactUsSuccessMessage: 'The message has been sent succesfully.',
  txtCostChangesCostDifference: 'Difference amount',
  txtCostChangesCostDifferencePercentage: 'Difference percentage',
  txtCostChangesCostTotalChange: 'Total change',
  txtCostChangesCount: 'Cost changes',
  txtCostChangesGo: 'Go to cost changes page',
  txtCostChangesNewCostUnit: 'New cost per unit',
  txtCostChangesShowUnchangedCost: 'Include unchanged prices',
  txtCostUnitInfoIntakesId: 'Invoice Id',
  txtCostUnitInfoNoIntakesFound: 'No invoices found. Update the Unit Cost manually if needed.',
  txtCostUnitInfoUnitCost: 'Unit cost',
  txtCustomField: 'Custom Field',
  txtCustomFieldAdd: 'Add custom field',
  txtCustomFieldAddAttachments: 'Add attachments',
  txtCustomFieldAddValue: 'Add list item',
  txtCustomFieldDefaultValue: 'Default value',
  txtCustomFieldDropdownItems: 'Values',
  txtCustomFieldEdit: 'Modify custom field',
  txtCustomFields: 'Custom Fields',
  txtCustomFieldsActive: 'Active custom fields',
  txtCustomFieldsView: 'View custom fields',
  txtCustomFieldType: 'Field type',
  txtDashboardDeliveryLast: 'Last Invoice',
  txtDashBoardFillOnboarding: 'Fill your venue onboarding items',
  txtDashBoardHealthOverTimeGraphTitle: 'Health over time: ',
  txtDashboardInventory: 'Inventory',
  txtDashboardInventoryCombinedTotals: 'Combined Totals',
  txtDashboardInventoryCurrentOnHand: 'Ending Inventory Value',
  txtDashboardInventoryDeadStock: 'Dead Stock',
  txtDashBoardInventoryDurationMinutes: 'Inventory duration in minutes',
  txtDashBoardInventoryDurationTime: 'Inventory duration over time',
  txtDashboardInventoryHistoricalOnHand: 'Historical Inventory Value',
  txtDashboardInventoryLast: 'Last Inventory',
  txtDashboardInventoryLast30Days: 'last 30 days',
  txtDashboardInventoryNext: 'Next Inventory',
  txtDashboardInventoryNotFound: 'Not Enough Data',
  txtDashboardInventoryNotFoundDesc: 'No inventory found with the given id.',
  txtDashboardInventoryOnHand: 'Inventory Value',
  txtDashboardInventoryOnHandVariance: 'Inventory Variance',
  txtDashboardInventoryOverUnderstock: 'Over / Understock $',
  txtDashboardInventoryPercentChange: 'Percent change',
  txtDashboardInventoryPreviousOnHand: 'Beginning Inventory Value',
  txtDashboardInventoryRequired: 'You must complete at least 2 inventory counts to generate and view dashboard data.',
  txtDashboardInventorySlowMoving: 'Slow-moving',
  txtDashboardInventoryStockEfficiency: 'Stock Efficiency Rating',
  txtDashboardInventoryStockValue: 'Stock Value',
  txtDashboardInventoryTop10SlowMoving: 'Top 10 Slow-moving Items',
  txtDashboardInventoryTurnover: 'Inventory Turnover',
  txtDashboardInventoryUsed: 'Used',
  txtDashBoardInventoryValueOverTime: 'Inventory value over time',
  txtDashBoardInventoryWeeklyIntakesOverTime: 'Inventory & Weekly Invoices over time',
  txtDashBoardNewVenue: 'Need to add another venue? Click here to book a call with the team',
  txtDashboardObsolete: 'Variance for this period was generated at {a} and not up to date',
  txtDashboardOrderLast: 'Last Order',
  txtDashboardOverview: 'Overview',
  txtDashboardOverviewActual: 'Actual',
  txtDashboardOverviewActualCost: 'Actual Cost',
  txtDashboardOverviewCashFlowMargin: 'Cash Flow Margin',
  txtDashboardOverviewHistoricalPurchases: 'Historical Purchases',
  txtDashboardOverviewHistoricalSale: 'Historical Sales',
  txtDashboardOverviewIdeal: 'Theoretical',
  txtDashboardOverviewIdealCost: 'Theoretical Cost',
  txtDashboardOverviewLossAtCost: 'Loss at Cost',
  txtDashboardOverviewPerformance: 'Performance',
  txtDashboardOverviewRevenuePotential: 'Variance Retail',
  txtDashboardOverviewTargetConsumptionCost: 'Theoretical Cost of Goods Sold ($)',
  txtDashboardOverviewUsedCost: 'Cost of Goods Sold (COGS)',
  txtDashboardPurchases: 'Purchases',
  txtDashboardPurchasesDetailedItemPurchases: 'Detailed Item Purchases',
  txtDashboardPurchasesDollarCurrent: 'Current Period Purchases ($)',
  txtDashboardPurchasesDollarPrevious: 'Previous Period Purchases ($)',
  txtDashboardPurchasesHistorical: 'Historical Purchases',
  txtDashboardPurchasesProduct: 'Product',
  txtDashboardPurchasesPurchasesBy: 'Purchases By',
  txtDashboardPurchasesQuantity: 'Purchases Quantity',
  txtDashboardPurchasesTopProducts: 'Purchased Products by Total',
  txtDashboardPurchasesTotalPurchased: 'Total Purchased',
  txtDashboardPurchasesUnits: 'Purchases Units',
  txtDashboardRecalculate: 'click here to recalculate',
  txtDashboardRecalculating: 'Your data will be recalculated.',
  txtDashboardRecentlyAddedItems: 'Items added in last 30 days',
  txtDashBoardRefreshDashboard: 'Refresh dashboard data',
  txtDashBoardRefreshDashboardMessage: 'We have triggered a refresh of your dashboard data. Please check again in a few minutes.',
  txtDashboardSalesBreakdownByFamily: 'Sales Breakdown by Family',
  txtDashboardSalesIdealCOGS: 'Ideal COGS',
  txtDashboardSalesOverallTopByProfit: 'Overall - Top Profits',
  txtDashboardSalesOverallTopBySale: 'Overall - Top Sales',
  txtDashboardSalesPercentage: 'Sales (%)',
  txtDashboardSalesPotentialProfit: 'Potential Profit',
  txtDashboardSalesSale: 'Sales',
  txtDashboardSalesShrinkage: 'Shrinkage',
  txtDashBoardSavedThisMonth: 'Labor Saved in the last 30 Days',
  txtDashBoardStockInformationBasedOn: 'Stock Information based on',
  txtDashBoardstockPredictionBasedOn: 'Stock Prediction based on',
  txtDashboardTotalItems: 'Total Items',
  txtDashboardTotalValue: 'Total Value',
  txtDashboardUniqueItems: 'Unique Items',
  txtDashboardUnmappedSalesOnly: 'The Sales total represents sales of mapped POS Items in the selected range. You have unmapped POS Items totalling {a} in this range.',
  txtDashBoardUpdateMissingDistributors: 'Update missing distributors',
  txtDashBoardUpdateMissingParLevels: 'Update missing Par Levels',
  txtDashBoardUpdateMissingPOSItems: 'Update missing POS items',
  txtDashBoardUpdateMissingPrices: 'Update missing prices',
  txtDashboardVariance: 'Variance',
  txtDashBoardVarianceBy: 'Variance by',
  txtDashboardVarianceByCategory: 'Variance By Category',
  txtDashboardVarianceByFamily: 'Variance By Family',
  txtDashboardVarianceDetailedReport: 'Detailed Variance Report',
  txtDashboardVarianceFamilyVariancesActual: 'Actual',
  txtDashboardVarianceFamilyVariancesPotential: 'Potential',
  txtDashboardVariancePeriodEnding: 'Period Ending',
  txtDashboardVarianceRetailVariances: 'Retail Variances',
  txtDashboardVarianceTopLosses: 'Top 10 by Losses',
  txtDashboardVarianceTopUnderUsage: 'Top 10 by Under Usage',
  txtDashboardVarianceTrend: 'Variance Trend',
  txtDashBoardVenueSetupAddedItemCosts: 'Added',
  txtDashBoardVenueSetupAssignedDistributors: 'Assigned',
  txtDashBoardVenueSetupProgress: 'Venue Setup Progress',
  txtDashBoardVenueSetupProgressInfo: 'Complete your checklist to take control of your costs.',
  txtDashboardVenueStats: 'Venue Stats',
  txtDistributorAdd: 'Add distributor',
  txtDistributorContacts: 'Contacts',
  txtDistributorContactValidationPhoneOrEmail: 'At least phone or email is needed for a contact.',
  txtDistributorCreatedAt: 'Created at',
  txtDistributorCustomerAccountNumber: 'Customer / Account number',
  txtDistributorExcluded: 'Excluded',
  txtDistributorFindHelpTextPublic: 'Type to search for a distributor',
  txtDistributorItemsAddToVenueSelected: 'Add selected to venue',
  txtDistributorItemsOnlineSearchMinChars: 'Please enter at least 3 characters for online search',
  txtDistributorManage: 'Manage distributor',
  txtDistributorName: 'Distributor Name',
  txtDistributorNew: 'Add Distributor',
  txtDistributorNotifyNewDistributors: 'Notify new distributors',
  txtDistributorOpenLastDraftInvoice: 'Open Last Draft Invoice',
  txtDistributorPublincLinked: 'Link to WISK Distributor',
  txtDistributorPublincLinkedNot: 'Not linked to WISK distributor',
  txtDistributorPublincLinkNot: 'Unlink from WISK distributor',
  txtDistributorPublincLinkSelected: 'Link to selected WISK distributor',
  txtDistributorReviewed: 'Reviewed',
  txtDistributorsAllowAutoOrdering: 'Automatic purchase orders',
  txtDistributorsArchiveError: "The distributor can't be archived because there are items assigned to it. Please change or remove the distributor from the following items and try again:",
  txtDistributorsClearPortalCredentials: 'Clear portal credentials',
  txtDistributorSearch: 'Find distributor',
  txtDistributorSearchAdd: 'Find or add distributor',
  txtDistributorsExcludePriceOrders: 'Exclude price from purchase orders',
  txtDistributorsImport: 'Import distributors from Excel file',
  txtDistributorsManage: 'Manage distributors',
  txtDistributorsPortalCredentials: 'Portal credentials',
  txtDistributorsSavePortalCredentials: 'Save portal credentials',
  txtDistributorsUploadDownloadExisting: 'Existing distributors',
  txtDistributorsUploadMessage: 'Download one of the files below and populate it with your latest data. Distributors found will be updated, new distributors will be added, based on the data in the uploaded file.',
  txtDistributorsUploadTemplateLink: 'Empty template',
  txtDistributorSupplyIntegration: 'Supply integration',
  txtDistributorsWisk: 'Wisk Distributors',
  txtDistributorTitleHelpTextPrivate: 'Add private distributor',
  txtDistributorTitleHelpTextPublic: 'Type to search for a distributor, or add one if not found',
  txtDistributorViewItems: 'Search items',
  txtDistributorWisk: 'Wisk Distributor',
  txtDistributorWiskInstantLearning: 'Use instant learning',
  txtDistributorWiskInstantLearningNot: 'Do not use instant learning',
  txtDistributorWiskNew: 'New Wisk Distributor',
  txtDownloadManagerDownloaded: 'Downloaded at',
  txtDownloadManagerEmailed: 'Emailed at',
  txtDownloadManagerRequestedAt: 'Requested at',
  txtDownloadManagerRetryDescription: 'Are you sure you want to retry generating this report?',
  txtDownloadManagerRetryTitle: 'Retry generating report',
  txtDraftInvoiceChatConversationMarkDone: 'Mark as done',
  txtDraftInvoiceChatConversationMarkDoneInfo: 'Marking the conversation as done will end the conversation and remove the "Needs attention" status from the draft invoice line',
  txtDraftInvoiceChatConversationStart: 'Start a conversation',
  txtDraftInvoiceChatConversationStartInfo: 'Starting a conversation will mark the draft invoice line as "Needs attention"',
  txtDraftInvoiceChatConversationTitle: 'Conversation about draft invoice line',
  txtDraftInvoiceChatConversationTooltip: 'Click to start a conversation',
  txtDraftInvoiceChatConversationTooltipRestart: 'Click to restart the conversation',
  txtDraftInvoiceChatMessageAdd: 'Add Message',
  txtDraftInvoiceChatReply: 'Reply',
  txtDraftInvoiceChatSend: 'Send',
  txtDraftInvoiceChatSendDone: 'Send and mark as resolved',
  txtFamiliesCategoriesAddCategory: 'Add Category',
  txtFamiliesCategoriesArchiveError: "The category can't be archived because there are items using it. Please change or remove the category from the following items and try again:",
  txtFamiliesCategoriesArchiveFamily: 'Archive Family',
  txtFamiliesCategoriesArchiveFamilynotAllowed: 'Cannot archive family with active categories',
  txtFamiliesCategoriesBottlesUsingCategoryMessage: 'Please change the category or archive the items in order to be able to archive the category.',
  txtFamiliesCategoriesEditFamily: 'Edit Family',
  txtFamiliesCategoriesFamilyName: 'Family name',
  txtFamiliesCategoriesNewCategory: 'New Category',
  txtFamiliesCategoriesNewFamily: 'New Family',
  txtFamiliesCategoriesPartial: 'Partial',
  txtFamiliesCategoriesTargetBeverageCost: 'Theoretical cost %',
  txtFamiliesCategoriesTargetVariancePercentage: 'Target variance %',
  txtFamilyInfo: 'Family cannot be changed directly, please use the category selector.',
  txtFilterActive: 'Active',
  txtFilterAdd: 'Add Filter',
  txtFilterArchived: 'Archived',
  txtFilterBy: 'Filter By',
  txtFilterCocktails: '2+ Ingredients',
  txtFilterCombineClauseAnd: 'And',
  txtFilterCombineClauseOr: 'Or',
  txtFilterConsumptionDepletedItems: 'Depleted Items',
  txtFilterConsumptionGreaterThanSales: 'Consumption greater than sales',
  txtFilterConsumptionNewItems: 'New Items',
  txtFilterConsumptionNoSales: 'With Consumption but without Sales',
  txtFilterConsumptionNotMapped: 'With Consumption but not mapped to Any POS Items',
  txtFilterConsumptionOrSales: 'With Consumption or Sales',
  txtFilterCostHigherThan100Percent: 'Cost % higher than 100%',
  txtFilterDeadStock: 'Deadstock',
  txtFilterDuplicatedBarcodes: 'Duplicated Barcodes',
  txtFilterExcludedfromVariance: 'Excluded from Variance',
  txtFilterIncludedInVariance: 'Included in Variance',
  txtFilterInfo: 'Active filters',
  txtFilterInStock: 'In stock',
  txtFilterManualCost: 'Cost Set Manually',
  txtFilterMissingCategory: 'Missing Category (unknown)',
  txtFilterMissingCostAlert: 'Missing Cost Alert',
  txtFilterMissingCosts: 'Missing Cost',
  txtFilterMissingDistributor: 'Missing Distributor',
  txtFilterMissingFullWeights: 'Missing Full Weights',
  txtFilterMissingParLevel: 'Missing Par Level',
  txtFilterMissingPercentage: 'Missing %',
  txtFilterMissingStockAlert: 'Missing Stock Alert',
  txtFilterMultipleBarcodes: 'Multiple Barcodes',
  txtFilterNegativeConsumption: 'Stock Errors',
  txtFilterNegativeConsumptionWithIntake: 'Stock Errors With Invoice',
  txtFilterNegativeConsumptionWithoutIntake: 'Stock Errors Without Invoice',
  txtFilterNotInStock: 'Not in stock',
  txtFilterOutLostAccounts: 'Filter out lost accounts',
  txtFilterOutTestAccounts: 'Filter out test accounts',
  txtFilterOverStock: 'Overstock and deadstock',
  txtFilterPOSItemSoldInTheLast30days: 'Sold in the last 30 days',
  txtFilterPOSItemSoldNot: 'With no sales',
  txtFilterPOSModifierMappedModifiers: 'Mapped Modifiers',
  txtFilterPOSModifierUnmappedModifiers: 'Unmapped Modifiers',
  txtFilterSales: 'With Sales',
  txtFilterSalesGreaterThanConsumption: 'Sales greater than consumption',
  txtFilterSalesNoConsumption: 'With Sales but no consumption',
  txtFilterSalesWithUnmappedModifiers: 'With unmapped modifiers',
  txtFilterUnderStock: 'Understock',
  txtFilterUnmappedPOSItems: 'Unmapped POS Items',
  txtFilterUnmappedToPOSItemsOrBatches: 'Unmapped to POS Items / batches',
  txtFilterWithArchivedBottles: 'With archived items',
  txtFilterWithNoIngredients: 'With no ingredients',
  txtFilterWithPerpetualInventory: 'With Real-Time Inventory',
  txtFilterWithSuggestedOrder: 'With Suggested Order',
  txtGenericActions: 'Actions',
  txtGenericActivate: 'Activate',
  txtGenericAdd: 'Add',
  txtGenericAddBarcode: 'Add barcode (min 6 characters)',
  txtGenericAddDescription: 'Add Description',
  txtGenericAddedOn: 'Added On',
  txtGenericAddEmptyWeight: 'Add empty weight (g)',
  txtGenericAddIngredient: 'Add Ingredient',
  txtGenericAddItem: 'Add Item',
  txtGenericAddress: 'Address',
  txtGenericAddWeight: 'Add weight (g)',
  txtGenericAdjustment: 'Adjustment',
  txtGenericAdjustments: 'Adjustments',
  txtGenericAggregation: 'Aggregation',
  txtGenericAll: 'All',
  txtGenericAllButArchived: 'All (but archived)',
  txtGenericAllIncludingArchived: 'All (including archived)',
  txtGenericAmount: 'Amount',
  txtGenericApiKey: 'API Key',
  txtGenericArchive: 'Archive',
  txtGenericArchived: 'Archived',
  txtGenericBack: 'Back',
  txtGenericBarcodes: 'Barcodes',
  txtGenericBatch: 'Batch',
  txtGenericBatches: 'Batches',
  txtGenericBeerSaverTap: 'Beer Saver Tap',
  txtGenericBeginningInventory: 'Beginning inventory',
  txtGenericBeverageCost: 'Cost of Sales',
  txtGenericBeverageSales: 'Sales',
  txtGenericBottle: 'Item',
  txtGenericBottles: 'items',
  txtGenericBottleSearchPlaceholder: 'Search by item name ...',
  txtGenericBottleSearchPlaceholderByCode: 'Search by item code ...',
  txtGenericByCategory: 'By Category',
  txtGenericByDistributor: 'By Distributor',
  txtGenericByFamilyCategory: 'By Family > Category',
  txtGenericByItem: 'By Item',
  txtGenericByLocation: 'By Area',
  txtGenericByLocationFamilyCategory: 'By Area > Family > Category',
  txtGenericByLocationOrdered: 'By Area Ordered',
  txtGenericByMonthDistributor: 'By Month > By Distributor',
  txtGenericBypassThis: 'Bypass this',
  txtGenericByUser: 'By User',
  txtGenericByVariation: 'By Variation',
  txtGenericCancel: 'Cancel',
  txtGenericCase: 'Case',
  txtGenericCases: 'cases',
  txtGenericCaseSize: 'Case Size',
  txtGenericCategory: 'Category',
  txtGenericChange: 'Changes',
  txtGenericCity: 'City',
  txtGenericClear: 'Clear',
  txtGenericClickDeselect: 'Click to deselect',
  txtGenericClickSelect: 'Select',
  txtGenericClickToHide: 'click to hide',
  txtGenericClickToSelect: 'Click row to select',
  txtGenericClone: 'Clone',
  txtGenericClose: 'Close',
  txtGenericCode: 'Code',
  txtGenericColumns: 'Columns',
  txtGenericComplete: 'Complete',
  txtGenericCompleted: 'Completed',
  txtGenericConfirm: 'Confirm',
  txtGenericConfirmation: 'Confirmation',
  txtGenericConsumed: 'Consumed',
  txtGenericConsumption: 'Consumption',
  txtGenericContactUs: 'Email Us',
  txtGenericContactUsTitle: 'Contact Us',
  txtGenericContinue: 'Continue',
  txtGenericContinueInventory: 'Continue inventory',
  txtGenericCopyClipboard: 'Copy to clipboard',
  txtGenericCopyToVenue: 'Copy to venue',
  txtGenericCost: 'Cost',
  txtGenericCostCase: 'Cost per Case',
  txtGenericCostChanges: 'Cost Changes',
  txtGenericCostOfGoodsSold: 'Cost of Goods Sold',
  txtGenericCostPercentage: 'Cost Percentage',
  txtGenericCountry: 'Country',
  txtGenericCreate: 'Create',
  txtGenericCreateCategory: 'Create category',
  txtGenericCreated: 'Created',
  txtGenericCreatedAt: 'Created at',
  txtGenericCreatedBy: 'Created by',
  txtGenericCredentials: 'Credentials',
  txtGenericCurrency: '$',
  txtGenericCurrencyText: 'Currency',
  txtGenericCurrentCostUnit: 'Current cost per Unit',
  txtGenericCurrentMonth: 'Current month',
  txtGenericCurrentQuarter: 'Current quarter',
  txtGenericCurrentYear: 'Current year',
  txtGenericCustomFilters: 'Predefined filters',
  txtGenericCustomRange: 'Custom range',
  txtGenericDate: 'Date',
  txtGenericDateRange: 'Date Range',
  txtGenericDayOfWeek: 'Day Of week',
  txtGenericDays: 'days',
  txtGenericDeactivate: 'Deactivate',
  txtGenericDeadstock: 'Deadstock',
  txtGenericDecimalPlaces: 'Decimal places',
  txtGenericDefault: 'Default',
  txtGenericDegrees: 'Degrees',
  txtGenericDelete: 'Permanently Delete',
  txtGenericDeliveries: 'Invoices',
  txtGenericDeliveriesReturns: 'Invoices / Returns',
  txtGenericDensity: 'Density',
  txtGenericDepletion: 'Depletion',
  txtGenericDepletions: 'Depletions',
  txtGenericDeposit: 'Deposit',
  txtGenericDepositFee: 'Fee',
  txtGenericDescription: 'Description',
  txtGenericDetailed: 'Detailed',
  txtGenericDetails: 'Details',
  txtGenericDisabled: 'Disabled',
  txtGenericDiscount: 'Discount',
  txtGenericDismiss: 'Dismiss',
  txtGenericDisplayCurrency: 'Display Currency',
  txtGenericDistributor: 'Distributor',
  txtGenericDistributorCode: 'Distributor Code',
  txtGenericDistributors: 'Distributors',
  txtGenericDollars: 'Dollars',
  txtGenericDownload: 'Download',
  txtGenericDownloadManager: 'Download Manager',
  txtGenericDragSort: 'Drag to change sort order',
  txtGenericEach: 'Each',
  txtGenericEdit: 'Edit',
  txtGenericEmail: 'Email',
  txtGenericEmailAdresses: 'Email Adresses',
  txtGenericEmailAdressesInput: 'Input one or more email adresses',
  txtGenericEmailInProgress: "Generating your report! It will download when finished. If you close the browser tab while generating, it'll send to your email when complete.",
  txtGenericEmptyList: 'Nothing to display',
  txtGenericEmptyWeights: 'Empty Weights (g)',
  txtGenericEnabled: 'Enabled',
  txtGenericEndDate: 'End date',
  txtGenericEndingInventory: 'Ending inventory',
  txtGenericError: 'Error',
  txtGenericErrorNo: 'No Error',
  txtGenericErrors: 'The following errors occurred',
  txtGenericEvent: 'Event',
  txtGenericExcludedFromVariance: 'Excluded from Variance',
  txtGenericExcludeFromVariance: 'Exclude from Variance',
  txtGenericExisting: 'Existing',
  txtGenericExport: 'Export',
  txtGenericFamilies: 'Families',
  txtGenericFamiliesAndCategories: 'Families & Categories',
  txtGenericFamily: 'Family',
  txtGenericFee: 'Fee',
  txtGenericField: 'Field',
  txtGenericFilter: 'Filter',
  txtGenericFilters: 'Filters',
  txtGenericFindOnGoogle: 'Find on Google',
  txtGenericFindVenue: 'Find your venue on Google',
  txtGenericFirstName: 'First Name',
  txtGenericFix: 'Fix',
  txtGenericFrom: 'From',
  txtGenericFromDate: 'From date',
  txtGenericFullName: 'Full Name',
  txtGenericGenerated: 'Generated',
  txtGenericGetData: 'Fetch Data',
  txtGenericGooglePlacesId: 'Google Places Id',
  txtGenericGrams: 'grams',
  txtGenericGrandTotal: 'Grand Total',
  txtGenericGraphPointInfo: 'Click on a point to view details in the panel on the left',
  txtGenericGroup: 'Group',
  txtGenericGroupTitleMissing: 'Other/Missing',
  txtGenericHelp: 'Help',
  txtGenericHelpLink: 'Help Link',
  txtGenericHere: 'here',
  txtGenericHide: 'Hide',
  txtGenericHideAll: 'Hide all',
  txtGenericHours: 'hours',
  txtGenericId: 'Id',
  txtGenericImage: 'Image',
  txtGenericImageAdd: 'Add Image',
  txtGenericImages: 'Images',
  txtGenericImagesClear: 'Clear Images',
  txtGenericImagesPDFAdd: 'Add images / pdf',
  txtGenericImportedNow: 'Import now',
  txtGenericInactie: 'Inactive',
  txtGenericIncludeInVariance: 'Include in Variance',
  txtGenericInfo: 'Info',
  txtGenericInformationRequired: 'Information Required',
  txtGenericIngredients: 'Ingredients',
  txtGenericInputBarcode: 'Input barcode',
  txtGenericInputEmptyWeight: 'Input empty weight (g)',
  txtGenericInputManually: 'Input manually',
  txtGenericInputWeight: 'Input weight (g)',
  txtGenericIntakes: 'Invoices',
  txtGenericInventories: 'Inventories',
  txtGenericInventory: 'Inventory',
  txtGenericInventoryAlert: 'Stock Alert',
  txtGenericInventoryDuration: 'Inventory duration',
  txtGenericInventoryRange: 'Inventory Range',
  txtGenericInventoryValue: 'Inventory value',
  txtGenericInvoiceCostUnit: 'Invoice cost per Unit',
  txtGenericItemCode: 'Item Code',
  txtGenericItems: 'Items',
  txtGenericLabel: 'Label',
  txtGenericLanguage: 'Language',
  txtGenericLast30Days: 'Last 30 days',
  txtGenericLast30DaysLast6Months: 'Last 6 months',
  txtGenericLast7Days: 'Last 7 days',
  txtGenericLast90Days: 'Last 90 days',
  txtGenericLastMonth: 'Last month',
  txtGenericLastName: 'Last Name',
  txtGenericLastQuarter: 'Last quarter',
  txtGenericLastSalesCheck: 'Last sales check',
  txtGenericLastWeek: 'Last week',
  txtGenericLastYear: 'Last year',
  txtGenericLearnMore: 'Learn more...',
  txtGenericLineNumber: 'Line #',
  txtGenericLoadMore: 'Load more...',
  txtGenericLocation: 'Area',
  txtGenericLocations: 'Areas',
  txtGenericLogOut: 'Logout',
  txtGenericMain: 'Main',
  txtGenericManage: 'Manage',
  txtGenericManual: 'Manual',
  txtGenericMax: 'Max',
  txtGenericMeasurement: 'Measurement',
  txtGenericMessage: 'Message',
  txtGenericMessageStatus: 'Message status',
  txtGenericMililiters: 'milliliters',
  txtGenericMin: 'Min',
  txtGenericMinutes: 'minutes',
  txtGenericMobile: 'Mobile',
  txtGenericModify: 'Modify',
  txtGenericMonth: 'Month',
  txtGenericMonth0: 'January',
  txtGenericMonth1: 'February',
  txtGenericMonth10: 'November',
  txtGenericMonth11: 'December',
  txtGenericMonth2: 'March',
  txtGenericMonth3: 'April',
  txtGenericMonth4: 'May',
  txtGenericMonth5: 'June',
  txtGenericMonth6: 'July',
  txtGenericMonth7: 'August',
  txtGenericMonth8: 'September',
  txtGenericMonth9: 'October',
  txtGenericMoreInfo: 'More info',
  txtGenericMovements: 'Movements',
  txtGenericMovementsAnalytics: 'Movement Analytics',
  txtGenericMovementType: 'Movement type',
  txtGenericName: 'Name',
  txtGenericNew: 'New',
  txtGenericNewDistributor: 'New Distributor',
  txtGenericNewIntake: 'New invoice',
  txtGenericNewInventory: 'New inventory',
  txtGenericNewMovement: 'New movement',
  txtGenericNext: 'Next',
  txtGenericNo: 'No',
  txtGenericNoChangesRecorded: 'No changes recorded',
  txtGenericNoDistributor: 'No distributor',
  txtGenericNoInfo: 'No info',
  txtGenericNoItems: 'no items',
  txtGenericNoResult: 'No result',
  txtGenericNormalView: 'Normal View',
  txtGenericNote: 'Note',
  txtGenericNotEnoughData: 'Not enough data',
  txtGenericNotes: 'Notes',
  txtGenericNotFound: 'Not Found',
  txtGenericNotSet: 'Not set',
  txtGenericNoValue: 'No value',
  txtGenericNumber: 'Number',
  txtGenericOf: 'of',
  txtGenericOK: 'OK',
  txtGenericOnline: 'Online',
  txtGenericOpen: 'Open',
  txtGenericOpenVenueNewTab: 'Open venue in new tab',
  txtGenericOperation: 'Operation',
  txtGenericOperationSuccess: 'Changes Saved!',
  txtGenericOrderAmount: 'Order Amount',
  txtGenericOrderFormat: 'Order Format',
  txtGenericOrders: 'Orders',
  txtGenericOrganization: 'Organization',
  txtGenericOverstock: 'Overstock',
  txtGenericOverview: 'Overview',
  txtGenericPage: 'Page',
  txtGenericParLevel: 'Par Level',
  txtGenericParLevels: 'Par Levels',
  txtGenericPartial: 'partial',
  txtGenericPartnerVenue: 'Partner Venue',
  txtGenericPassword: 'Password',
  txtGenericPaymentMethod: 'Payment method',
  txtGenericPDF: 'Download PDF',
  txtGenericPending: 'Pending',
  txtGenericPercentage: 'Percentage',
  txtGenericPermission: 'Permission',
  txtGenericPerpetual: 'Real Time Stock',
  txtGenericPerson: 'Person',
  txtGenericPhone: 'Phone Number',
  txtGenericPhoneNumberInput: 'Input one or more phone numbers',
  txtGenericPhotos: 'Photos',
  txtGenericPlaceholder: 'Placeholder',
  txtGenericPlatform: 'Platform',
  txtGenericPOSCode: 'POS Code',
  txtGenericPOSItem: 'POS Item',
  txtGenericPOSItems: 'POS Items',
  txtGenericPressEnterToAdd: 'Press enter to add',
  txtGenericPreview: 'Preview',
  txtGenericPrevious: 'Previous',
  txtGenericPrice: 'Price',
  txtGenericPrices: 'Prices',
  txtGenericPriority: 'Priority',
  txtGenericPrivacy: 'Privacy',
  txtGenericProcessed: 'Processed',
  txtGenericProfit: 'Profit',
  txtGenericQuantity: 'Quantity',
  txtGenericQuantityToOrder: 'Quantity to order',
  txtGenericReason: 'Reason',
  txtGenericReceivedAt: 'Received At',
  txtGenericReceivedStatus: 'Invoice status',
  txtGenericReceivings: 'Invoices',
  txtGenericRemove: 'Remove',
  txtGenericRename: 'Rename',
  txtGenericReport: 'Report',
  txtGenericReports: 'Reports',
  txtGenericReset: 'Reset',
  txtGenericResolve: 'Resolve',
  txtGenericResolved: 'Resolved',
  txtGenericRestore: 'Restore',
  txtGenericResumeSubscription: 'Resume Subscription',
  txtGenericRetailPrice: 'Retail Price',
  txtGenericRetailValue: 'Retail Value',
  txtGenericRetry: 'Retry',
  txtGenericRole: 'Role',
  txtGenericRunReport: 'Run Report',
  txtGenericSales: 'Sales',
  txtGenericSave: 'Save',
  txtGenericSaveNext: 'Save & Next',
  txtGenericScale: 'Scale',
  txtGenericSearchOnline: 'Search online catalog',
  txtGenericSearchReturnNothing: 'Search returned no elements',
  txtGenericSelect: 'Select',
  txtGenericSelectAll: 'Select all',
  txtGenericSelectImage: 'Select Image',
  txtGenericSelection: 'Selection',
  txtGenericSend: 'Send',
  txtGenericSendEmail: 'Send email',
  txtGenericSendTransferOut: 'Send transfer out',
  txtGenericSendUsEmail: 'Send us an email',
  txtGenericSendUsMessage: 'Send us a message',
  txtGenericSentAt: 'Sent At',
  txtGenericSet: 'set',
  txtGenericSetAsDefault: 'Set as default',
  txtGenericSetUp: 'Set up',
  txtGenericShowAll: 'Show all',
  txtGenericSignupDate: 'Signup Date',
  txtGenericSize: 'Size',
  txtGenericSkip: 'Skip',
  txtGenericSold: 'Sold',
  txtGenericSort: 'Sort',
  txtGenericSortAsc: 'Sort ascending',
  txtGenericSortClear: 'Clear sort',
  txtGenericSortDesc: 'Sort descending',
  txtGenericSource: 'Source',
  txtGenericSpreadsheet: 'Spreadsheet',
  txtGenericStart: 'Start',
  txtGenericStartDate: 'Start date',
  txtGenericStartedOn: 'started on',
  txtGenericState: 'State',
  txtGenericStatus: 'Status',
  txtGenericStatusInfoRequired: 'Information Required',
  txtGenericStatusPending: 'Pending',
  txtGenericStatusProcessed: 'Processed',
  txtGenericStatusProcessing: 'Processing',
  txtGenericStatusSkipped: 'Skipped',
  txtGenericStock: 'Stock',
  txtGenericStockFullBottles: 'Stock Full Units',
  txtGenericStockInformation: 'Stock Information',
  txtGenericStockPrediction: 'Stock Prediction',
  txtGenericSubject: 'Subject',
  txtGenericSubmit: 'Submit',
  txtGenericSubscribeAll: 'Subscribe all',
  txtGenericSubTotal: 'Subtotal',
  txtGenericSuggestedStock: 'Suggested stock',
  txtGenericSummary: 'Summary',
  txtGenericSuperUser: 'Super user',
  txtGenericSymbol: 'Symbol',
  txtGenericTakeatour: 'Take a tour',
  txtGenericTakeYouToLightspeed: 'Taking you to Lightspeed...',
  txtGenericTax: 'Tax',
  txtGenericTaxRate: 'Tax Rate (ex 14.975, 13,...)',
  txtGenericTermsOfService: 'Terms Of Service',
  txtGenericTesting: 'TESTING',
  txtGenericText: 'Text',
  txtGenericThankYou: 'Thank you for using WISK!',
  txtGenericTime: 'Time',
  txtGenericTimeline: 'Timeline',
  txtGenericTimestamp: 'Timestamp',
  txtGenericTimeZone: 'Time zone',
  txtGenericTitle: 'Title',
  txtGenericTitleSearch: 'Title / Search',
  txtGenericTo: 'To',
  txtGenericToday: 'Today',
  txtGenericTotal: 'Total',
  txtGenericTransfers: 'Transfers',
  txtGenericType: 'Type',
  txtGenericTypeToSearch: 'Type to search',
  txtGenericUM: 'UM',
  txtGenericUnderstock: 'Understock',
  txtGenericUnit: 'Unit',
  txtGenericUnitOfMeasurement: 'Unit of measurement',
  txtGenericUnits: 'units',
  txtGenericUnknown: 'Unknown',
  txtGenericUnmapped: 'Unmapped',
  txtGenericUnSelectAll: 'Unselect all',
  txtGenericUnsubscribeAll: 'Unsubscribe all',
  txtGenericUpload: 'Upload',
  txtGenericURL: 'Link',
  txtGenericUse: 'Use',
  txtGenericUsedIn: 'Used in',
  txtGenericUser: 'User',
  txtGenericUsername: 'Username',
  txtGenericUsers: 'Users',
  txtGenericValue: 'Value',
  txtGenericVariance: 'Variance',
  txtGenericVariant: 'Variant',
  txtGenericVariants: 'Variants',
  txtGenericVenue: 'Venue',
  txtGenericVenueApiKey: 'Venue API Key',
  txtGenericVenues: 'Venues',
  txtGenericView: 'View',
  txtGenericViewAccount: 'View Account',
  txtGenericViewer: 'Viewer',
  txtGenericVisual: 'Visual',
  txtGenericVolume: 'Volume',
  txtGenericVolumePercentage: 'Volume Percentage',
  txtGenericWarningInventoryStarted: 'There is currently an inventory in progress. These numbers are not final and may change until you submit the inventory.',
  txtGenericWeb: 'Web',
  txtGenericWeek: 'Week',
  txtGenericWeekly: 'Weekly',
  txtGenericWeeklyIntakes: 'Weekly Invoices',
  txtGenericWeight: 'Weight',
  txtGenericWeights: 'Weights (g)',
  txtGenericWiskId: 'WISK ID',
  txtGenericWiskItem: 'WISK Item',
  txtGenericWiskItems: 'WISK Items',
  txtGenericWISKPar: 'WISK Par (Consumption)',
  txtGenericWISKParSales: 'WISK Par (Sales)',
  txtGenericWiskSupportWillContactYou: 'Thank you, WISK customer support will contact you shortly',
  txtGenericYear: 'Year',
  txtGenericYes: 'Yes',
  txtGenericYesterday: 'Yesterday',
  txtGenericYields: 'Yields',
  txtGenericYourPar: 'Your Par',
  txtGLAccount: 'GL Account',
  txtGLAccountEdit: 'Edit GL Account',
  txtGLAccountName: 'GL Account Name',
  txtGLAccountNew: 'New GL Account',
  txtGLAccountNumber: 'GL Account Number',
  txtGLAccounts: 'GL Accounts',
  txtGLAccountsAppliedMessage: 'GL Accounts applied successfully.',
  txtGLAccountsApply: 'Apply GL Accounts',
  txtGLAccountsManage: 'Manage GL Accounts',
  txtGLAccountsMap: 'Map GL Accounts',
  txtGLAccountsMappedMessage: 'GL Accounts mapped successfully.',
  txtGridGroupExpandDoubleClick: 'Double click to expand all',
  txtGroupBy: 'Group By',
  txtHelpForBackup: 'Need help picking back up?',
  txtHelpInfo: 'The text will propagate to all clients instantly',
  txtHelpInfoTitle: 'Change tooltip text',
  txtImageUploaderAddFiles: 'Click here to add more files',
  txtImageUploaderCheckingPreview: 'Checking image link',
  txtImageUploaderCurrentImege: 'Current Image',
  txtImageUploaderDropZoneText: 'Click to browse files from your device, or instead drag and drop, or paste here',
  txtImageUploaderDropZoneTextMultiple: 'Drop or paste files, or click to browse from device, multiple selection enabled',
  txtImageUploaderDropZoneTextSmall: 'drop files here to upload',
  txtImageUploaderEmpty: 'empty',
  txtImageUploaderImageIsBroken: 'Image link is broken',
  txtImageUploaderLoadFromURL: 'Load from URL',
  txtImageUploaderLoadFromURLText: 'Input a file URL (image, pdf)',
  txtImageUploaderSearchOrPaste: 'Search on Google, or paste your target image link',
  txtImageUploaderSelectedImage: 'Selected Image',
  txtImageUploaderSelectFile: 'Select file',
  txtImageUploaderTitle: 'Uploader',
  txtImageUploaderUploadedImeges: 'Uploaded Images',
  txtIncludeVenueNameInExcel: 'Include venue name in header of exported file',
  txtIndependentInventories: 'Partial Inventories',
  txtIndependentInventoriesAdd: 'Add partial inventory',
  txtIndependentInventoriesGroup: 'Group',
  txtIndependentInventoriesGroupMissingMessage: 'Please add or select a group to see the partial inventories for it',
  txtIndependentInventoriesGroups: 'Groups',
  txtIndependentInventoriesGroupsAdd: 'Add group',
  txtIndependentInventoriesGroupsArchive: 'Archive group',
  txtIndependentInventoriesGroupsArchiveDescription: 'Are you sure you want to archive "{a}" group?',
  txtIndependentInventoriesGroupsManage: 'Manage groups',
  txtIndependentInventoriesGroupsRestore: 'Restore group',
  txtIndependentInventoriesGroupsRestoreDescription: 'Are you sure you want to restore "{a}" group?',
  txtIndependentInventoriesGroupsUpdate: 'Update group',
  txtIndependentInventory: 'Partial Inventory',
  txtIndependentInventoryPeriod: 'Inventory period',
  txtInventorieLockStatus: 'Lock Status',
  txtInventoriesApprove: 'Approve',
  txtInventoriesApprovedBy: 'Approved By',
  txtInventoriesArchivedWarning: 'Adding inventory to an archived inventory item will automatically restore it',
  txtInventoriesCaseSizeSet: 'Set case size',
  txtInventoriesCostUnit: 'Inventory cost per unit',
  txtInventoriesDownloadInventoryReport: 'Download inventory report by item XLS',
  txtInventoriesDownloadInventoryReportByLocation: 'Download inventory report by area XLS',
  txtInventoriesDownloadSpreadsheetByLocation: 'Download items by area XLS',
  txtInventoriesEdit: 'Edit Inventory',
  txtInventoriesEditDetails: 'Details / Edit',
  txtInventoriesEmailReports: 'Email Reports (Inventory/Consumption/Variance)',
  txtInventoriesEntries: 'Entries',
  txtInventoriesEntriesCount: 'Entries',
  txtInventoriesEntriesEdit: 'Inventory entries for item',
  txtInventoriesEntriesMove: 'Move inventory entries',
  txtInventoriesEntriesMoveConfirmation: 'Are you sure you want to move selected entries to the selected inventory / area?',
  txtInventoriesEntriesMoveTo: 'Move inventory entries to:',
  txtInventoriesEntriesMoveToOther: 'Move inventory entries to other inventories',
  txtInventoriesEntriesToMove: 'Entries to move',
  txtInventoriesEntryAdd: 'Add inventory entry',
  txtInventoriesEntryEdit: 'Inventory entry',
  txtInventoriesEntryMove: 'Move inventory entry',
  txtInventoriesFilterByCreatedDate: 'Filter by Added Date',
  txtInventoriesFilterByLocation: 'Filter by area',
  txtInventoriesFinishedAt: 'End Time',
  txtInventoriesFinishedAtNone: 'Not finished yet',
  txtInventoriesFullBottles: 'Full Items',
  txtInventoriesFullWeightSet: 'Set full weight',
  txtInventoriesInAppTime: 'In-App Time',
  txtInventoriesLocationSelect: 'Select location',
  txtInventoriesLock: 'Lock',
  txtInventoriesLockConfirmationDescription: 'Please confirm you want to lock this inventory count. All other unlocked counts dated before this one will also be locked.',
  txtInventoriesLockConfirmationTitle: 'Lock inventory',
  txtInventoriesLocked: 'Locked',
  txtInventoriesLockedDescription: 'The inventory count is locked by {a} at {b} and cannot be edited.',
  txtInventoriesMergeItems: 'Merge items',
  txtInventoriesPartialsBottle: 'Open Item',
  txtInventoriesPartialsBottles: 'Open Items',
  txtInventoriesReset: 'Restart',
  txtInventoriesScaleInputInfo: 'Input grams will be converted to milliliters',
  txtInventoriesScanOrder: 'Scan Order',
  txtInventoriesSearchAddQuick: 'Quick search and add item',
  txtInventoriesSearchPrevious: 'Search in previous inventory',
  txtInventoriesSearchVenue: 'Search in venue items',
  txtInventoriesStartedAt: 'Start Time',
  txtInventoriesStartedBy: 'Started By',
  txtInventoriesStartedNot: 'Not started',
  txtInventoriesSubmitAll: 'Submit all at once',
  txtInventoriesTotalBottles: 'Total Items',
  txtInventoriesTotalBottlesCost: 'Total Items($)',
  txtInventoriesTotalBottlesValue: 'Total Items Value',
  txtInventoriesTotalUom: 'Total (UM)',
  txtInventoriesUnlock: 'Unlock',
  txtInventoriesUnlockConfirmationDescription:
    'Unlocking inventories applies your current POS mapping to the past and has a significant impact on your historical data.\n\nPlease confirm you want to unlock this inventory count. All other inventory counts from the date of this count to now will also be unlocked.\n\nDoing so you acknowledge that this will impact your historical data.',
  txtInventoriesUnlockConfirmationTitle: 'Unlock inventory',
  txtInventoriesUnlockDisabledTooltip: 'You can only unlock up to 10 inventories at a time. In order to unlock this inventory, you must first unlock the more recent inventories.',
  txtInventoriesUnlocked: 'Unlocked',
  txtInventoriesWeightEmpty: 'Empty weight',
  txtInventoriesWeightFull: 'Full weight',
  txtInventoryBarcodes: 'Inventory barcodes',
  txtInventoryBarcodesPDF: 'Download inventory barcodes PDF',
  txtInventoryDetailsPopupConvertedDefaultVariation: 'Summary - Converted to Default Variation',
  txtInventoryDetailsPopupCountsByVariation: 'Counts by Item Variation',
  txtInventoryEmailResendMessage: 'Your report is being generated and will send when complete. This may take a few minutes.',
  txtInventoryEmailSentMessage: 'Your email is scheduled to be sent to all recipients',
  txtInventoryLoadAll: 'Load all inventories',
  txtInventoryMarkAsCompleted: 'Mark as completed',
  txtInventoryOpen: 'Go to Inventory',
  txtInventoryReportSummaryPDF: 'Download inventory report by item PDF',
  txtInventoryScheduledNextChange: 'Change next inventory date',
  txtInventorySubmitAsZero: 'Submit as 0',
  txtInventorySubmittedValue: 'Submitted',
  txtInventoryUnusualDuration: 'The inventory was started more than 48 hours ago',
  txtItemDetailsInventoryHistory: 'Inventories / Invoices / Depletions',
  txtItemDetailsSoldSinceInventory: 'Sold since last inventory',
  txtItemErrorCanArchive: 'Please retry the archive',
  txtItemErrorCantArchive: 'The operation cannot be completed because of the following reasons',
  txtItemErrorFound: 'The following errors occurred while updating the selected item(s)',
  txtItemErrorRemoveFromRetryList: 'Keep item, do not archive',
  txtItemErrorRemoveFromShoppingCart: 'Remove from Shopping Cart',
  txtItemErrorUsedInLastInventory: 'Used in Last Inventory',
  txtItemErrorUsedInPOSItems: 'Used in POS Items',
  txtItemErrorUsedInPOSItemsEdit: 'Edit POS item',
  txtItemErrorUsedInShoppingCart: 'Used in Shopping Cart',
  txtItemErrorUsedInSubrecipes: 'Used in Batches',
  txtItemErrorUsedInSubrecipesEdit: 'Edit batch',
  txtItemOperationsAdditionalFields: 'Additional Fields',
  txtItemOperationsBarcodePrint: 'Print Barcode',
  txtItemOperationsCaseSizeChangeCancel: 'Create new item variation',
  txtItemOperationsCaseSizeChangeMessage:
    'Please confirm that you wish to change the case size of this item variation.\n  Changing the case size will only apply to invoices and inventory counts moving forward.\n\n  If the case size was incorrect in the past, you will need to edit your previous invoices/inventory counts.\n\n  If the item can be ordered/received in multiple case sizes (6, 12, 24, etc.), we recommend creating a new item variation instead of changing the current case size.',
  txtItemOperationsCaseSizeChangeOK: 'Change current case size',
  txtItemOperationsCaseSizeCreateVariantConfirm: 'Create new item variation?',
  txtItemOperationsCloneSuffix: ' - (clone)',
  txtItemOperationsInventoryAreas: 'Inventory areas',
  txtItemOperationsMeasurementAlias: 'Alias',
  txtItemOperationsMeasurementQuantity: 'Quantity',
  txtItemOperationsMeasurementType: 'Measurement Type',
  txtItemOperationsParLevelSwitchToCases: 'switch to par level per case',
  txtItemOperationsParLevelSwitchToUnits: 'switch to par level per unit',
  txtItemOperationsSearchLocal: 'Search your local items',
  txtItemOperationsSearchMode: 'Search mode for item',
  txtItemOperationsSearchWisk: 'Search WISK for item',
  txtItemSearch: 'Search WISK Item',
  txtItemSearchAdd: 'Search / Add',
  txtItemStatsGraphWeeklyIntake: 'Weekly Invoice',
  txtItemStatsGraphWeeklySales: 'Weekly Sales',
  txtItemSwap: 'Swap Item',
  txtItemSwapClearTooltip: 'Search for another item',
  txtItemSwapFindReplacement: 'Search for replacement item',
  txtItemSwapSuccessNotify: 'Items are being swapped. This may take a few moments.',
  txtItemSwapThisWillBeReplaced: 'This item will be replaced in the selected POS items and batches',
  txtItemSwapThisWillBeReplacement: 'This item will be used in the selected POS items and batches',
  txtLocationsDefault: 'Default Area for Invoices and Returns',
  txtLocationsMessageNoSaveSort: "Can't save sort order with archived areas visible",
  txtLocationsNew: 'New area',
  txtMenuAccount: 'Account',
  txtMenuAskUsAnything: 'Ask us anything',
  txtMenuConsumption: 'Consumption',
  txtMenuDashboard: 'Dashboard',
  txtMenuFeedbackCenter: 'WISK Feedback',
  txtMenuFeedbackPage: 'Feedback',
  txtMenuGettingstarted: 'Getting started',
  txtMenuHelpCenter: 'WISK Help Center',
  txtMenuHelpChat: 'Chat with us',
  txtMenuHelpPage: 'Help Page',
  txtMenuHome: 'Home',
  txtMenuIntakes: 'Invoices',
  txtMenuInventory: 'Inventory',
  txtMenuLocations: 'Areas',
  txtMenuMultiVenue: 'Multivenue',
  txtMenuMultiVenueTransfers: 'Venue Transfers',
  txtMenuOverstockUnderstock: 'Overstock / Understock',
  txtMenuPOSItems: 'POS Items',
  txtMenuPrepareOrders: 'Prepare Orders',
  txtMenuPriceChanges: 'Price Changes',
  txtMenuPurchaseOrders: 'Purchase Orders',
  txtMenuSales: 'Sales',
  txtMenuSignOut: 'Log Out',
  txtMenuSubscription: 'Subscription',
  txtMenuSwitchVenue: 'Switch Venue',
  txtMenuTeam: 'Team',
  txtMenuVariance: 'Variance',
  txtMenuVenueBottles: 'Items',
  txtMenuVenues: 'Venues',
  txtMenuVenueSettings: 'Venue Settings',
  txtMessageRedirect: 'Setting up integration...',
  txtMessageRedirectSuccess: 'All done! Redirecting to home page...',
  txtMovementAccessPurchaseOrdersInOncePlace: 'Access all your purchase orders in once place',
  txtMovementAccessPurchaseOrdersInOncePlaceExplanation:
    'The days of sifting through emails to figure out what you ordered from a certain supplier,\n  or what you paid at any given time, are over. Access all your history of purchase orders from\n  all your suppliers on this page. Sort by supplier, by category, by product, to easily find any past record of a purchase order.',
  txtMovementAccountingQuickbooksClass: 'Quickbooks class',
  txtMovementAccountingQuickbooksDesktop: 'Get Quickbooks Desktop file',
  txtMovementAccountingQuickbooksDesktopExplain:
    'This action will mark the selected invoice as sent to accounting and generate a Quickbooks Desktop file for you to download.\n\nPlease confirm all invoice details are correct before generating the file. If you edit the invoice after generating the file, you can generate a new file with the latest changes.',
  txtMovementAccountingQuickbooksDesktopExplainMultiple:
    'This action will mark the selected invoices as sent to accounting and generate a Quickbooks Desktop file for you to download.\n\n  Please confirm all invoice details are correct before generating the file. You cannot make any changes to the invoice once the file is generated.',
  txtMovementAccountingResend: 'Resend to accounting',
  txtMovementAccountingResendConfirmation:
    'This invoice has previously been sent to accounting, are you sure you want to send it again? This will create a new expense/bill in your accounting software instead of updating the one that was created originally. You may need to delete the resulting duplicate in your accounting software.',
  txtMovementAccountingSend: 'Send to accounting',
  txtMovementAccountingSendConfirmation: 'Please confirm all invoice details are correct before sending to your accounting software.',
  txtMovementAccountingSent: 'Sent to accounting',
  txtMovementAccountingSentAlready: 'Already sent to accounting',
  txtMovementAccountingSentChanged: 'Changes have been made to this invoice since it was last sent to accounting. Please re-send this invoice to your accounting software to make sure the latest changes are reflected',
  txtMovementAccountingSentNotEditable: 'This invoice has been sent to your accounting software in its current state. If you make additional changes you will need to re-send it.',
  txtMovementAdjustmentReasonNew: 'New depletion reason',
  txtMovementAdjustmentReasons: 'Depletion Reasons',
  txtMovementBatchErrorMessage:
    '\n    To proceed with the batch operation, please resolve all errors by either:\n\n      1. Opening each invoice from the grid and fixing errors.\n\n      2. Removing the invoice from the list.\n\n    Ensure all lines in the grid display "No error" before clicking "Retry".\n    ',
  txtMovementCostPerUnit: 'Cost per unit',
  txtMovementDepletion: 'Depletion',
  txtMovementDepletionDollar: 'Depletion ($)',
  txtMovementDepletionReason: 'Depletion Reason',
  txtMovementDepletions: 'Depletions',
  txtMovementDepletionUnit: 'Depletion (units)',
  txtMovementDuplicateInvoiceNumber: 'Duplicate invoice number',
  txtMovementEditAddSearchNoResults: 'No results in your venue items, try searching online',
  txtMovementEditConvertedSuccessMessage: 'Invoice generated successfully',
  txtMovementEditEmailPDF: 'Download images in a PDF format',
  txtMovementEditExpandBatchesPOSITems: 'Expand batches and POS Items',
  txtMovementEditInvoiceDifference: 'Difference',
  txtMovementEditInvoiceId: 'Invoice Id',
  txtMovementEditInvoiceNumber: 'Invoice number',
  txtMovementEditInvoiceTotal: 'Invoice total',
  txtMovementEditInvoiceTotalOnPaper: 'Total on paper',
  txtMovementEditInvoiceTotalPaper: 'Scanned total',
  txtMovementEditLocationFrom: 'From area',
  txtMovementEditLocationTo: 'To area',
  txtMovementEditNoPhotosMessage: 'No photos were taken for this invoice',
  txtMovementEditNotPublicDistributor: 'The selected distributor is a private distributor.',
  txtMovementEditPartnerVenue: 'Partner venue',
  txtMovementEditPricePerUnitOrCase: 'Cost per UM',
  txtMovementEditPriceWithoutTax: 'Cost without tax',
  txtMovementEditSubmitIssue: 'Submit issue',
  txtMovementEditSubmitIssueConfirmationText: 'Are you sure you want to submit issue as {a}?',
  txtMovementEditValidationUnitsOrCases: 'Please enter the number of cases or individual units you are receiving.',
  txtMovementEditViewIntake: 'View invoice',
  txtMovementEditViewInvoiceImages: 'View attached images',
  txtMovementEditViewInvoiceImagesSideBySide: 'Side by side image view',
  txtMovementEditViewInvoiceImagesSideBySideExit: 'Exit side by side view',
  txtMovementErrorRemoveFromRetryList: 'Remove from list',
  txtMovementExtraLineAddCharge: 'Add Charge',
  txtMovementExtraLineAddCredit: 'Add Credit',
  txtMovementExtraLineReasonNew: 'New adjustment reason',
  txtMovementExtraLineReasons: 'Charge / Credit Reasons',
  txtMovementFlatAggregatedReportGLAccountNotAssigned: 'Not assigned',
  txtMovementFlatAggregatedReports: 'Aggregated Reports',
  txtMovementFlatAggregateOn: 'Aggregate on',
  txtMovementFlatAggregateOnDistributor: 'Distributor',
  txtMovementFlatAggregateOnFamily: 'Family',
  txtMovementFlatAggregateOnFamilyCategory: 'Family + Category',
  txtMovementFlatAggregateOnGLAccountItemVariation: 'GL Account + Item Variation',
  txtMovementFlatAggregateOnGLAccountYearMonth: 'GL Account + Year + Month',
  txtMovementFlatAggregateOnInvoiceNumberGLAccount: 'Invoice Number + GL Account',
  txtMovementFlatAggregateOnItemVariation: 'Item Variation',
  txtMovementFlatSelectedRowMovements: 'Movements for selected row',
  txtMovementInvoice: 'Invoice',
  txtMovementItemAdd: 'Add Item',
  txtMovementItemWarningRemoveFromStock: 'Anything you add above will remove the items from stock',
  txtMovementLinkToPurchaseOrder: 'Link to purchase order',
  txtMovementLockedDescription: 'This invoice is in a locked inventory period and cannot be edited.',
  txtMovementPOSItemSubrecipeAdd: 'Add POS Item / Batch',
  txtMovementsClonedMessage: 'Cloned successfully, opening cloned intake',
  txtMovementsDownloadWithHeader: 'XLS with header details',
  txtMovementsItemMeasurement: 'Item measurement',
  txtMovementsLineMeasurement: 'Quantity',
  txtMovementSource: 'Source',
  txtMovementsSearchPlaceholder: 'Search by Distributor name, person, operation type',
  txtMovementsSummary: 'Movements Summary',
  txtMovementsTotalsMatch: 'Totals do not match',
  txtMovementsValidationTotalsMatch: 'Total on paper and the computed total does not match',
  txtMovementTakeControlOfYourOrders: 'Take control of your orders!',
  txtMovementTransferOutSent: 'Transfer out sent',
  txtMovementTransferOutSentAlready: 'Already sent',
  txtOnboardingHelpMessage: 'Click here if you need help',
  txtOnboardingSelectPlaceholder: 'View items for venue',
  txtOnboardingTitle: 'Venue Onboarding',
  txtOnboardingVenueStatus: 'Venue Status: ',
  txtOnlineItemSearchCatalog: 'Search online catalog',
  txtOnlineItemSearchDistributor: 'Select item from distributor',
  txtOnlineItemSearchPartnerVenue: 'Search partner venue',
  txtOpenInAppstore: 'The WISK Web Portal is not optimized for mobile browsers. For the best experience please use the iOS app.',
  txtOpenInAppstoreTitle: 'Open the WISK mobile app',
  txtOrderPageCartBuilderTitle: 'Step 1: Cart Builder',
  txtOrderPageIntakesTitle: 'Step 4: Invoices',
  txtOrderPageShoppingCartTitleEmpty: 'Step 2: Your cart (empty)',
  txtOrganizations: 'Organizations',
  txtOrganizationsAddVenue: 'Add venue',
  txtOrganizationsAddVenues: 'Add selected venues to organization',
  txtOrganizationsArchiveConfirm: 'Please confirm you want to archive the selected organization.',
  txtOrganizationsEdit: 'Edit organization',
  txtOrganizationsFilterArchived: 'Archived',
  txtOrganizationsFilterHasVenue: 'With venues using Wisk',
  txtOrganizationsFilterNoVenue: 'Without venues using Wisk',
  txtOrganizationsNew: 'New organization',
  txtOrganizationsRemoveVenueFromOrganizationTitle: 'Remove from organization',
  txtOrganizationsRemoveVenuesFromOrganizationMessage: 'Please confirm you want to remove the selected venues from the organization.',
  txtOrganizationsRemoveVenuesFromOrganizationTitle: 'Clear organization from  selected venues',
  txtOrganizationsTotalVenues: 'Total venues',
  txtPageNotFound: 'The page you are looking for was not found.',
  txtPageNotFoundTitle: "Oops! You're lost.",
  txtParentVenueActivateItems: 'Activate items',
  txtParentVenueFindItems: 'Find items in parent venue',
  txtParentVenueItemsActiveInVenues: 'Active in venues',
  txtParentVenueItemsSelectVenue: 'Select Venues',
  txtParSuggestedInfoDailyConsumption: 'Daily consumption',
  txtParSuggestedInfoIdealStock: 'Ideal stock',
  txtParSuggestedInfoInvalidData: 'Invalid Data',
  txtParSuggestedInfoNotEnoughData: 'There is not enough data, we need at least two inventories in the last 6 weeks to compute the WISK par (Consumption).',
  txtParSuggestedInfoOverStockBy: 'Over stock by',
  txtParSuggestedInfoRecommendedStock: 'Recommended stock',
  txtParSuggestedInfoSetYourPar: 'Set your par level to match WISK par',
  txtParSuggestedInfoUnderStockBy: 'Under stock by',
  txtParSuggestedInfoWeeklyConsumption: 'Weekly consumption',
  txtParSuggestedSalesInfoDailyConsumption: 'Daily sales',
  txtParSuggestedSalesInfoNotEnoughData: 'There is not enough data to compute the WISK Par (Sales). The Item must be mapped to at least one POS Item and have 6 weeks of sales data.',
  txtParSuggestedSalesInfoWeeklyConsumption: 'Weekly sales',
  txtPDFViewerDownload: 'Download PDF',
  txtPDFViewerPageDown: 'Page down',
  txtPDFViewerPages: 'Pages',
  txtPDFViewerPageUp: 'Page up',
  txtPDFViewerPan: 'Pan / drag / scroll to zoom',
  txtPDFViewerRotateClockwise: 'Rotate clockwise',
  txtPDFViewerRotateCounterClockwise: 'Rotate counter clockwise',
  txtPDFViewerSelectText: 'Select text',
  txtPDFViewerZoomControlTooltipDropdown: 'Control zoom using predefined zoom presets',
  txtPDFViewerZoomControlTooltipScroll: 'Zoom in and out using the mouse wheel',
  txtPDFViewerZoomIn: 'Zoom in',
  txtPDFViewerZoomOut: 'Zoom out',
  txtPDFViewerZoomPresets: 'Zoom presets',
  txtPlanFeatureAdBookDemo: 'Book a demo',
  txtPlanFeatureAdQuestion1: 'Interested in this feature?',
  txtPlanFeatureAdQuestion2: 'Want to learn more?',
  txtPlanFeatureAdUpgradeCompletedNavigateNow: "Your subscription has been upgraded, and you now have access to the selected feature. Would you like to go to the feature's page?",
  txtPlanFeatureAdUpgradeNow: 'Upgrade now',
  txtPlanSelectorChargeConfirmationOn: 'You will be charged {a} / {b} until you cancel or change your subscription.',
  txtPlanSelectorChargeConfirmationOnPromote: 'By clicking "Confirm subscription", your card on file will be charged immediately {a} / {b} until you cancel or change your subscription.',
  txtPlanSelectorChargeLong: 'until you cancel or change your subscription',
  txtPlanSelectorConfirmSubscription: 'Confirm subscription',
  txtPlanSelectorCurrentPlan: 'Current plan',
  txtPlanSelectorDiscountMessageForYearlyPlan: 'monthly, if you select a yearly plan.',
  txtPlanSelectorGet2monthsFree: 'Get 2 months free!',
  txtPlanSelectorMonthlySaving: 'Monthly savings',
  txtPlanSelectorPerMonth: 'per month',
  txtPlanSelectorPerVenue: 'per venue',
  txtPlanSelectorScheduleMyMeeting: 'Schedule my meeting',
  txtPlanSelectorYearlySaving: 'Yearly savings',
  txtPlanSelectorYouCanSave: 'You can save',
  txtPOSEditClearData: 'Clear previous data',
  txtPOSExtraAccessKey: 'Access Key',
  txtPOSExtraAldeloAppStoreSubscription: 'Aldelo AppStore Subscription',
  txtPOSExtraAldeloAppStoreToken: 'Aldelo AppStore Token',
  txtPOSExtraAPIKey: 'API Key',
  txtPOSExtraApiSecret: 'API Secret',
  txtPOSExtraAPIToken: 'API Token',
  txtPOSExtraAuthorization: 'Authorization',
  txtPOSExtraAuthToken: 'Auth Token',
  txtPOSExtraBucket: 'Bucket',
  txtPOSExtraCg: 'CG',
  txtPOSExtraClientId: 'Client ID',
  txtPOSExtraClientSecret: 'Client Secret',
  txtPOSExtraCmid: 'MID',
  txtPOSExtraCompanyId: 'Company ID',
  txtPOSExtraDataName: 'Data Name',
  txtPOSExtraDispensaryName: 'Dispensary name',
  txtPOSExtraFolder: 'Folder',
  txtPOSExtraGenerateAPIKey: 'Generate an API key',
  txtPOSExtraGoFunVenueId: 'Venue ID in GoFun system',
  txtPOSExtraHeartlandLocationKey: 'Heartland Location Key',
  txtPOSExtraHostAddress: 'Host address',
  txtPOSExtraImportSalesByLegacyPluNumber: 'Use legacy PLU number',
  txtPOSExtraImportSalesByRevenueCenter: 'Import sales by revenue center',
  txtPOSExtraImportSpecialRequests: 'Import special requests',
  txtPOSExtraIncludeAllPOSItems: 'Include all POS Items',
  txtPOSExtraIncludeAllPOSItemsEvenIfNotSold: 'Include all POS Items even if not sold',
  txtPOSExtraIncludeGroupIdIntoPluNumber: 'Include Group ID into PLU number',
  txtPOSExtraIncludeModifiers: 'Include modifiers',
  txtPOSExtraIncludeModifiersWithPriceOfZero: 'Include modifiers with price of 0',
  txtPOSExtraIncludeSubmodifiers: 'Include submodifiers',
  txtPOSExtraIncludeTextModifiers: 'Include text modifiers',
  txtPOSExtraKey: 'Key',
  txtPOSExtraLocationId: 'Location ID',
  txtPOSExtraMerchantId: 'Merchant ID',
  txtPOSExtraMobileBytesLocationKey: 'MobileBytes Location Key',
  txtPOSExtraModifiersAsSeparateItems: 'Import Modifiers as separate POS Items',
  txtPOSExtraNetColumn: 'Net Sales column letter (A, B,..)',
  txtPOSExtraNetworkId: 'Network ID',
  txtPOSExtraOutlet: 'Outlet',
  txtPOSExtraPasteAPIKey: 'or paste an existing one',
  txtPOSExtraPluColumn: 'PLU Number column letter (A, B,..)',
  txtPOSExtraPriceAsModifier: 'Use price as modifier',
  txtPOSExtraQtyColumn: 'Quantity column letter (A, B,..)',
  txtPOSExtraRestaurantId: 'Restaurant ID',
  txtPOSExtraRPowerApiSelectStore: 'Select store',
  txtPOSExtraSecret: 'Secret',
  txtPOSExtraSerial: 'Serial',
  txtPOSExtraServer: 'Server',
  txtPOSExtraShopName: 'Shop Name',
  txtPOSExtraTitleColumn: 'Title column letter (A, B,..)',
  txtPOSExtraToken: 'Token',
  txtPOSExtraUsePOSCode: 'Use POS Code instead of menu ID',
  txtPOSExtraUsername: 'Username',
  txtPOSExtraVeloceApiFindLocation: 'Find Location ID',
  txtPOSIntegration: 'POS Integration',
  txtPOSIntegrationActiveItems: 'Active integrations',
  txtPOSIntegrationAdd: 'Add POS Integration',
  txtPOSIntegrationArchiveDescription: 'Are you sure you want to deactivate this POS integration?',
  txtPOSIntegrationCalledOff: 'There is an issue preventing the import of sales from your POS system into your account. Please contact support to resolve it.',
  txtPOSIntegrationCategoriesAutoExclude: 'Auto exclude',
  txtPOSIntegrationClearData: 'Clear previous data',
  txtPOSIntegrationClearDataConfirmation: 'Are you sure you want to clear this integration data?',
  txtPOSIntegrationCreateNew: 'Create new',
  txtPOSIntegrationCurrencyConversation: 'Currency conversion rate',
  txtPOSIntegrationEdit: 'Edit POS Integration',
  txtPOSIntegrationInactiveItems: 'Inactive integrations',
  txtPOSIntegrationLogs: 'Logs',
  txtPOSIntegrationModifiersNew: 'Use new modifiers',
  txtPOSIntegrationNickname: 'Nickname',
  txtPOSIntegrationOauthDescription: 'You should save your new POS integration to continue setup.',
  txtPOSIntegrationRestoreInactive: 'Restore Inactive',
  txtPOSIntegrations: 'POS Integrations',
  txtPOSIntegrationStatusExpiredMessage: 'Your POS integration expired, please do the set up again',
  txtPOSIntegrationSuccessfull: 'Integrated successfully',
  txtPOSItem: 'POS Item',
  txtPOSItemCloneAsDraft: 'Clone as draft',
  txtPOSItemCloneAsDraftConfirmationMessage: 'Please confirm cloning the selected item as draft',
  txtPOSItemCostAlertEmpty: 'Set an alert to track cost increases',
  txtPOSItemCostLessThanAlert: 'Cost is below alert level.',
  txtPOSItemCostMoreThanAlert: 'Cost is above alert level.',
  txtPOSItemCostPercentage: 'POS Item Cost Percentage',
  txtPOSItemDraftPOSItem: 'Draft POS Item',
  txtPOSItemDraftPOSItemConvert: 'Convert to draft POS item',
  txtPOSItemDraftPOSItemRevert: 'Revert to normal POS item',
  txtPOSItemDraftPOSItems: 'Cost calculator (Draft POS Items)',
  txtPOSItemEdit: 'Edit POS Item',
  txtPOSItemEditNonBeverageCost: 'Other costs (ex: labour/garnish)',
  txtPOSItemEditNotEditableMessage:
    'This POS Item was imported from your POS. To modify the title or price, please make the changes directly in your POS system. WISK will pull the updated info on the next sales refresh.',
  txtPOSItemHistory: 'History',
  txtPOSItemIngredientCost: 'Ingredient Cost',
  txtPOSItemIngredientCostBreakdown: 'Ingredient Cost History - Expanded',
  txtPOSItemIntegrationNeedsSuperUser: 'This integration needs a member from the WISK Team to complete it.',
  txtPOSItemIntegrationSetup: 'POS Integration Setup Instructions',
  txtPOSItemNewDraftPOSItem: 'New draft POS Item',
  txtPOSItemPercentageOfTotalCost: '% of total cost',
  txtPOSItemProfitPercentage: 'Profit Percentage',
  txtPOSItemRecipeCostIngredients: 'Ingredient Cost History',
  txtPOSItemRecipePDFMultiple: 'Recipe book for multiple pos items',
  txtPOSItemRestoreAllDeleted: 'Restore All deleted POS item',
  txtPOSItemRestoreDeleted: 'Restore deleted POS item',
  txtPOSItemsAboveCostAlert: 'Above Cost Alert',
  txtPOSItemsAllItemsMapped: 'Congratulations! All of your POS items are mapped.',
  txtPOSItemsAvailalbeServingSizes: "Available serving sizes based on item's unit of measurement.",
  txtPOSItemsByPOSCode: 'By POS Code',
  txtPOSItemsByPOSItem: 'By POS Item',
  txtPOSItemsClearIngredientsFrom: 'Clear ingredients from POS item',
  txtPOSItemsClearIngredientsFromConfirmation: 'Are you sure you want to clear ingredients from the selected POS item(s)?',
  txtPOSItemsCopyOnlyIfFound: 'Only copy if there is a match',
  txtPOSItemsCostAlert: 'Cost Alert',
  txtPOSItemsCostAlertClear: 'Clear Cost Alert',
  txtPOSItemsCostAlertRelative: 'Relative Cost Alert',
  txtPOSItemsCostPercentageHistory: 'Cost Percentage History',
  txtPOSItemsCostPercentageHistoryCostPerIngredient: 'Cost per Ingredient',
  txtPOSItemsCostPercentageHistoryNotification: 'Notification',
  txtPOSItemsCostPercentageHistoryTargetValue: 'Alert Percentage',
  txtPOSItemsDownloadSpreadsheetPOSItems: 'Export POS Items as Spreadsheet',
  txtPOSItemSearchBasedOn: 'Search in',
  txtPOSItemSearchDraftPOSItems: 'Draft POS Items',
  txtPOSItemSearchPOSItems: 'POS Items',
  txtPOSItemsFreshGlance: 'A fresh glance at your POS',
  txtPOSItemsFreshGlanceExplanation:
    'How are you interacting with the items in your POS, and what sorts of trends and insights can be gathered? With true POS integration WISK lets you quickly see your data like never before.',
  txtPOSItemsGo: 'Go to POS items page',
  txtPosItemsGridAveragePrice: 'Average Price',
  txtPosItemsGridLast30DaysActualCostPercentage: 'Last 30 Days Actual Cost Percentage',
  txtPosItemsGridLast30DaysAveragePrice: 'Last 30 Days Average Price',
  txtPosItemsGridModifier: 'Modifier',
  txtPosItemsGridPosCategoryMenu: 'Pos Menu Category',
  txtPosItemsGridPosCategorySale: 'Pos Sale Category',
  txtPosItemsGridQuantitySold: 'Quantity sold',
  txtPosItemsGridStatus: 'Status',
  txtPosItemsGridStatusSales: 'Status based on sales',
  txtPOSItemShowIngredients: 'Show all ingredients',
  txtPOSItemsImportIngredientsConfirm: 'The following ingredients will be imported to all selected POS items:',
  txtPOSItemsImportIngredientsDirectly: 'Import ingredients',
  txtPOSItemsImportIngredientsFrom: 'Import ingredients from another POS item',
  txtPOSItemsImportIngredientsFromSearch: 'Search for the POS item from which to import the ingredients into the selected ones',
  txtPOSItemsImportIngredientsInfo: 'Add one or more ingredients to the selected POS items. When ready, press Submit to save the changes.',
  txtPOSItemsIngredientsBatchAddConfirmation: 'The selected ingredients will be added shortly to the selected POS items.',
  txtPOSItemsLast30Profit: 'Last 30 Days Profit',
  txtPOSItemsLast30QuantitSold: 'Last 30 Days Quantity sold',
  txtPOSItemsLast30SalesCost: 'Last 30 Days Sales Cost',
  txtPOSItemsLast30Total: 'Last 30 Days Total',
  txtPosItemsLastSoldDate: 'Last Sale Date',
  txtPOSItemsMostSoldLast30Days: 'Most Sold Last 30 Days',
  txtPOSItemsNeedReview: 'Need review',
  txtPOSItemsNewPOSItem: 'New POS Item',
  txtPOSItemsNotificationClearFilter: 'Clear filter',
  txtPOSItemsNotificationUpdateUnmapped: 'Click here to update.',
  txtPOSItemsSearch: 'Search POS Item',
  txtPOSItemsSearchIngredient: 'Search for ingredient',
  txtPOSItemsServingSize: 'Serving Size',
  txtPOSItemsServingSizeName: 'Serving size name',
  txtPOSItemsServingSizeNew: 'New serving size',
  txtPOSItemsServingSizes: 'Serving Sizes',
  txtPOSItemsSuggestedCostPercentage: 'Suggested Cost Percentage',
  txtPOSItemsSuggestedPrice: 'Suggested Price',
  txtPOSItemsTitle: 'POS Items',
  txtPOSItemsTryIntegration: "Try WISK's POS integration today!",
  txtPOSItemTotalPOSItemCost: 'Total POS Item Cost',
  txtPOSModifier: 'POS Modifier',
  txtPOSModifierApplyChange: 'Apply',
  txtPOSModifierCostCannotBeCalculated: 'The change in cost cannot be calculated because the quantity will vary by POS Item.',
  txtPOSModifierEdit: 'Edit POS modifier',
  txtPOSModifierMappingSubtype: 'Operation',
  txtPOSModifierMappingType: 'Mapping type',
  txtPOSModifierMultiplier: 'Multiplier',
  txtPOSModifierNew: 'New POS modifier',
  txtPOSModifiers: 'Modifiers',
  txtPOSModifierUsedWith: 'Recently used with',
  txtPurchaseOrder: 'Purchase Order',
  txtPurchaseOrdersAddItemPlaceHolderDisabled: 'Search partner venue using the button above',
  txtPurchaseOrdersAddItemToOrder: 'Add item to order',
  txtPurchaseOrdersAddOrder: 'Add order',
  txtPurchaseOrdersAddWiskItem: 'Add WISK Item',
  txtPurchaseOrdersApprovalStatus: 'Approval status',
  txtPurchaseOrdersApprovalStatusResetMessge: 'The purchase order status will be set back to pending approval upon modification of the order.',
  txtPurchaseOrdersApprove: 'Approve',
  txtPurchaseOrdersClearDeliveryStatus: 'Clear delivery status',
  txtPurchaseOrdersClonedMessage: 'Cloned successfully, opening cloned purchase order',
  txtPurchaseOrdersConvertIntake: 'Receive order and add invoice',
  txtPurchaseOrdersConvertIntakeMessage: 'Selected order will be marked as received and converted to invoice',
  txtPurchaseOrdersDecline: 'Decline',
  txtPurchaseOrdersDeclinePromptMessage: 'Decline reason',
  txtPurchaseOrdersDownloadSpreadsheet: 'Download Spreadsheet',
  txtPurchaseOrdersEmail: 'Email order',
  txtPurchaseOrdersEmailPressEnter: 'Press enter to add a new email address',
  txtPurchaseOrdersEmailSent: 'The purchase order email was sent to the distributor.',
  txtPurchaseOrdersEmailSentStatus: 'Email sent status',
  txtPurchaseOrdersEmailSentText: 'Email Sent',
  txtPurchaseOrdersEmailSMS: 'Email / SMS order to distributor',
  txtPurchaseOrdersFrom: 'From',
  txtPurchaseOrdersGeneratedSuccessMessage: 'Purchase order generated successfully',
  txtPurchaseOrdersGeneratedSuccessTitle: 'Purchase order generated',
  txtPurchaseOrdersId: 'Order id',
  txtPurchaseOrdersLinkToInvoice: 'Link order to existing invoice',
  txtPurchaseOrdersMarkReceived: 'Mark as received',
  txtPurchaseOrdersMarkReceivedNoIntake: 'Unable to fulfill',
  txtPurchaseOrdersMarkSent: 'Mark as sent',
  txtPurchaseOrdersNew: 'New purchase order',
  txtPurchaseOrdersNotes: 'Notes to send to the distributor',
  txtPurchaseOrdersNotesModalTitle: 'Send notes to distributor',
  txtPurchaseOrdersNotesSend: 'Send',
  txtPurchaseOrdersNotesSent: 'Notes sent to the distributor',
  txtPurchaseOrdersNotReceived: 'Not received yet',
  txtPurchaseOrdersNotSent: 'Not sent to distributor',
  txtPurchaseOrdersPhonePressEnter: 'Press enter to add a new phone number',
  txtPurchaseOrdersPricePerUnit: 'Price per unit',
  txtPurchaseOrdersSAQCancel: 'Not yet',
  txtPurchaseOrdersSAQInfo: 'Copy the SAQ order to Clipboard and then Open the SAQ portal',
  txtPurchaseOrdersSAQOK: 'Order placed',
  txtPurchaseOrdersSAQOpen: 'Open SAQ Portal',
  txtPurchaseOrdersSelectDistributor: 'Select Distributor',
  txtPurchaseOrdersSelectDistributorOrPartnerVenue: 'Add from distributor or partner venue',
  txtPurchaseOrdersSelectPartnerVenue: 'Select partner venue',
  txtPurchaseOrdersSend: 'Send order (Email/SMS)',
  txtPurchaseOrdersSendForApproval: 'Send for approval',
  txtPurchaseOrdersSendSAQ: 'Send to SAQ',
  txtPurchaseOrdersSMSSentStatus: 'SMS sent status',
  txtPurchaseOrdersTo: 'To',
  txtPurchaseOrdersViewOrder: 'View order',
  txtReportsAnalytics: 'Reports / Analytics',
  txtReportsAnalyticsCardEmptyInfo: 'No extra reports, click on the header of this card to go to the default page',
  txtReportsAnalyticsDashboardVisible: 'Visible in reports dashboard',
  txtReportsDashboardTitle: 'Report Center',
  txtRoleArchiveError: 'Please remove the role from the following users, and try again:',
  txtRoleRenameValidationMessage: 'The selected role name is already in use',
  txtSalesByItemSpreadsheetMonthly: 'Download spreadsheet - monthly',
  txtSalesByItemSpreadsheetWeekly: 'Download spreadsheet - weekly',
  txtSalesCostlWithoutDiscount: 'Cost Without Discount',
  txtSalesCostPercentage: 'Cost Percentage',
  txtSalesDiscount: 'Discount',
  txtSalesFilterByPOSIntegration: 'Filter by POS integrations',
  txtSalesGo: 'Go to sales page',
  txtSalesGridAggregationBusinessDay: 'Business Day',
  txtSalesGridAggregationBusinessWeek: 'Business Week',
  txtSalesGridAggregationByDay: 'By Day',
  txtSalesGridAggregationByLocation: 'By Area',
  txtSalesGridAggregationByWeek: 'By Week',
  txtSalesGridAggregationOverall: 'Overall',
  txtSalesGridModifiersWith: 'with modifiers',
  txtSalesGridMostSold: 'Most Sold Last 30 Days ($)',
  txtSalesGridRevenueCenter: 'Revenue Center',
  txtSalesGridRevenueCenters: 'Revenue Centers',
  txtSalesGridSoldPOSItems: 'POS Items Sold',
  txtSalesGridSoldUnits: 'Items Sold',
  txtSalesGridTotals: 'Totals',
  txtSalesGridTotalSales: 'Total Sales',
  txtSalesGridTotalSalesCost: 'Total Cost',
  txtSalesGridUnwrapped: 'Unwrapped',
  txtSalesRefreshImport: 'Reimport sales data',
  txtSalesRefreshImportIntegrationRequirement: 'You need to have at least one active POS integration to import sales.',
  txtSalesRefreshImportInventoryRequirement: 'You need to have at least one inventory to use inventory selector.',
  txtSalesRefreshImportMessage: 'We have triggered a reimport of your sales data. Please check again in a few minutes.',
  txtSalesRefreshImportNotSupported: 'Reimport sales data is not supported for this integration.',
  txtSalesReportTryIt: 'Try WISK Sales Reports today!',
  txtSalesReportYouUnderstand: 'Sales reports you can understand',
  txtSalesReportYouUnderstandExplanation:
    'WISK give you the ability to see sales data in a completely new way. Not only can you see similar sales data to your POS, but you can get down to the ingredient level. Knowing that you sold 22 mojitos is nice, but understanding that you sold 16 ounces of a specific rum is powerful. Now you can look at sales from a profit angle, not just a revenue angle!',
  txtSalesTotal: 'Total Sales',
  txtSalesTotalCost: 'Total Cost',
  txtSalesTotalProfit: 'Total Profit',
  txtSalesTotalWithoutDiscount: 'Total Without Discount',
  txtSalesUploadErrors: 'The following errors occurred, please fix the lines with errors and upload the file again.',
  txtSalesUploadKeepSales: 'Keep existing sales for the selected interval',
  txtSalesUploadMessageKeepExisting: 'The sales data in the template will be added to your existing sales data for the selected period.',
  txtSalesUploadMessageReplaceExisting:
    'The sales upload will replace any existing sales data for the selected period.\n  To keep your existing sales data, click "Cancel" and check the "Keep existing sales for the selected interval" checkbox.',
  txtSalesUploadTemplateLink: 'Please download this file, populate it, then upload it back to us',
  txtSalesUploadTitle: 'Upload sales manually',
  txtScannedInvoice: 'Draft Invoice',
  txtScannedInvoiceDateInFutureTitle: 'Selected Date is in the Future',
  txtScannedInvoiceLineBatchRemove: 'Remove selected lines',
  txtScannedInvoiceLineBatchRemoveConfirmMessage: 'Are you sure you want to remove these lines?',
  txtScannedInvoiceLineNeedsAttentionResolve: 'Clears the notes and the needs attention flag from this line.',
  txtScannedInvoiceLineNeedsAttentionResolveConfirmMessage: 'Please confirm you want to clear the needs attention flag from this line.',
  txtScannedInvoiceLineRemoveConfirmationTitle: 'Remove invoice line',
  txtScannedInvoiceLineRemoveConfirmMessage: 'Are you sure you want to remove this line?',
  txtScannedInvoicePricePerUnit: 'Cost per unit',
  txtScannedInvoicePricePerUnitPrevious: 'Previous cost per unit',
  txtScannedInvoices: 'Draft Invoices / Distributor invoices',
  txtScannedInvoicesAddLineItem: 'Add new line item',
  txtScannedInvoicesAllItems: 'View all',
  txtScannedInvoicesAskForApproval: 'Send for approval',
  txtScannedInvoicesAskForApprovalMessage: 'Please confirm you want to send this draft invoice for approval.',
  txtScannedInvoicesCodeOverwrite: 'This action will replace the distributor and distributor code of the selected item variation',
  txtScannedInvoicesCodeOverwriteOK2: "Replace the selected item's distributor and distributor code with those from draft invoice line",
  txtScannedInvoicesCodeOverwriteOKNot2: 'Create a new item variation and add to the selected item',
  txtScannedInvoicesConvert: 'Convert to invoice',
  txtScannedInvoicesDistributorNotSaved: 'Please select a distributor from the list or create one if not found',
  txtScannedInvoicesDistributorNotSavedMessage: 'Please select a distributor in order to start mapping items',
  txtScannedInvoicesFintechDistributor: 'Fintech distributor',
  txtScannedInvoicesFoundLocal: 'Your item database has {a} similar items',
  txtScannedInvoicesFoundOnline: "WISK's item database has {a} similar items",
  txtScannedInvoicesGridViewByInvoiceDate: 'By Invoice Date',
  txtScannedInvoicesImportedTitle: 'Imported title',
  txtScannedInvoicesInLockedPeriod: 'This invoice is in a locked inventory period and cannot be converted until an Admin from the venue unlocks the corresponding inventory.',
  txtScannedInvoicesInvoiceDate: 'Invoice date',
  txtScannedInvoicesItemMissing: 'No item selected',
  txtScannedInvoicesItemNotFoundWillBeCreated: 'Create new item using the Actions Menu',
  txtScannedInvoicesItemSearchBy: 'Search',
  txtScannedInvoicesItemSearchByCode: 'By item code',
  txtScannedInvoicesItemSearchByName: 'By item name',
  txtScannedInvoicesItemSearchDialog: 'Select item',
  txtScannedInvoicesItemSearchDialogAddOnlineItem: 'Add to venue',
  txtScannedInvoicesItemSearchDialogAddOnlineItemStatus: 'Not in venue, found online',
  txtScannedInvoicesItemTitle: 'Item title',
  txtScannedInvoicesItemTitleCode: 'Item title / code',
  txtScannedInvoicesLast: 'Last draft invoice',
  txtScannedInvoicesLineInsertAbove: 'Insert line above',
  txtScannedInvoicesLineInsertBelow: 'Insert line below',
  txtScannedInvoicesLocateLine: 'Locate invoice line',
  txtScannedInvoicesMissingInfoFixConfirm:
    'Please check the notes left by the Invoicing Team and either fill in the requested missing information or add it to the note. When finished, click "OK" in this window to send the invoice back to the team to finish processing.',
  txtScannedInvoicesMissingInformation: 'Missing information',
  txtScannedInvoicesMoveToVenue: 'Move to another venue',
  txtScannedInvoicesNeedAttentionItems: 'Needs attention',
  txtScannedInvoicesNeedsActionTab: 'Client Review Needed!',
  txtScannedInvoicesNewNanonetsModel: 'Instant Learning Model',
  txtScannedInvoicesNumberOfPages: 'Number of pages',
  txtScannedInvoicesOldNanonetsModel: 'Old Model',
  txtScannedInvoicesPDFMerge: 'Merge invoice pages',
  txtScannedInvoicesPDFSplit: 'Manage invoice pages',
  txtScannedInvoicesPDFSplitDone: 'The pdf file was split successfully. The current draft invoice is being archived and for each pdf resulted from the split a new invoice will be created',
  txtScannedInvoicesPDFSplitDragDropInfo: 'Drag and drop pages to reorder or move them to another invoice',
  txtScannedInvoicesPDFSplitHere: 'Split here',
  txtScannedInvoicesPDFSplitMessage: 'The current invoice will be archived and for each pdf resulted from the split a new invoice will be created',
  txtScannedInvoicesPDFSplitNoListMessage: 'Nothing to split or submit, please add more files or click Reset to start over',
  txtScannedInvoicesPDFSplitPageNumber: 'Page number in new invoice',
  txtScannedInvoicesPDFSplitPageNumberOld: 'Page number in old invoice',
  txtScannedInvoicesPDFSplitRemovePage: 'Remove page',
  txtScannedInvoicesPDFSplitReset: 'Reset and remove uploaded files',
  txtScannedInvoicesPDFSplitShort: 'Split PDF',
  txtScannedInvoicesPDFSplitZoom: 'View larger',
  txtScannedInvoicesReImport: 'Re-import selected invoices',
  txtScannedInvoicesReimportSuccess: 'Invoices re-imported successfully',
  txtScannedInvoicesReprocess: 'Reprocess',
  txtScannedInvoicesReupload: 'Reupload invoice',
  txtScannedInvoicesReuploadDone: 'Invoice re-uploaded successfully. Please wait for the invoice to be processed.',
  txtScannedInvoicesScanDate: 'Upload date',
  txtScannedInvoicesShort: 'Draft Invoices',
  txtScannedInvoicesStatusResetConfirm: 'Please confirm you want to clear the status of this invoice and send it back to the Invoicing Team for processing.',
  txtScannedInvoicesStatusResetInfo: 'This action will clear the status of the invoice and send it back to the Invoicing Team for processing.',
  txtScannedInvoicesTotalItems: 'Total items',
  txtScannedInvoicesTotalsAlmostMatchTooltip: "The totals don't match, but are close enough that you can convert the draft invoice",
  txtScannedInvoicesUnmappedItems: 'Unmapped items',
  txtScannedInvoicesUpload: 'Upload Invoices',
  txtScannedInvoicesUploaderMode: 'Select Upload Mode',
  txtScannedInvoicesUploaderModeProcessAsMultiple: 'Process as multiple invoices',
  txtScannedInvoicesUploaderModeProcessAsSingle: 'Process as a single invoice',
  txtScannedInvoicesUploaderSaveAsMultiple: 'Save as multiple invoices',
  txtScannedInvoicesUploaderSaveAsSingle: 'Save as a single invoice',
  txtScannedInvoicesValidationMissingItemId: 'Add or map to a WISK item',
  txtScannedInvoicesView: 'View scanned invoice',
  txtScannedInvoicesViewExtraLines: 'Charges / credits',
  txtScannedInvoicesViewImage: 'Original invoice',
  txtScannedInvoicesViewItems: 'View items',
  txtScannedInvoicesViewItemsWithError: 'With error',
  txtScannedInvoicesViewOCR: 'Text Result',
  txtScannedInvoicesWarningsHeader: 'The following errors are preventing this draft to be converted to an invoice',
  txtScannedInvoicesWiskReviewed: 'Mark as reviewed by WISK',
  txtScannedInvoiceUpload: 'Upload Invoice',
  txtServingSizesAddAlias: 'Add alias',
  txtServingSizesAddServingSize: 'Add serving size',
  txtServingSizesInputAlias: 'Input alias',
  txtServingSizesShowArchived: 'Show archived',
  txtServingSizesTitle: 'Serving sizes',
  txtShoppingCartClear: 'Clear shopping cart',
  txtShoppingCartDownloadSpreadsheetAllDistributors: 'Download Shopping Cart Spreadsheet for all Distributors',
  txtShoppingCartSAQOrderModalTitle: 'SAQ Order',
  txtSignedUpOnMobileAlert: 'You will now be redirected to the WISK mobile app to continue the signup process',
  txtSignUpAskUserOrigin: 'Where did you find out about WISK?',
  txtSignUpAskUserOriginCustom: 'Where?',
  txtSignupSubTitleUser: 'A few details about yourself.',
  txtSignupSubTitleVenue: "Let's get your venue started on WISK.",
  txtSignupTitleTrial: 'Choose your plan',
  txtSignupTitleUser: 'User Details',
  txtSignupTitleVenue: 'Set up your venue',
  txtSortAdd: 'Then by',
  txtSortBy: 'Sort by',
  txtStockGridCurrentStock: 'Current stock',
  txtStockGridDailyConsumption: 'Daily consumption',
  txtStockGridRecommendedStock: 'Recommended stock',
  txtStockGridStockDays: 'Stock days',
  txtStockGridStockWeeks: 'Stock weeks',
  txtStockGridWeeklyConsumption: 'Weekly consumption',
  txtStripeAccountInformation: 'Account information',
  txtStripeAddressInputManually: 'Input address manually',
  txtStripeAddressLine1: 'Address line 1',
  txtStripeAddressLine2: 'Address line 2',
  txtStripeAddressPostalCode: 'Postal code',
  txtStripeBankAccounts: 'Bank accounts',
  txtStripeBillingAddress: 'Billing address',
  txtStripeBillingInformation: 'Billing information',
  txtStripeCardAdd: 'Add card',
  txtStripeCardAddMessage: 'Credit card',
  txtStripeCardDefault: 'Set as default',
  txtStripeCardEdit: 'Modify or remove card',
  txtStripeCardExpireMonth: 'Exp. month',
  txtStripeCardExpireYear: 'Exp. year',
  txtStripeCardExplainFreeTrialCard: "Your card won't be charged until the free trial ends.",
  txtStripeCardholderName: 'Cardholder name',
  txtStripeCardRemove: 'Remove card',
  txtStripeCardRemoveNotAllowedDefault: "Default card can't be removed",
  txtStripeCardRemoveNotAllowedLast: "Last card can't be removed",
  txtStripeCards: 'Cards',
  txtStripeCardSubscribeTo: 'Subscribe to',
  txtStripeCardTrialEnds: 'Trial Ends',
  txtStripeCardWeRemindYou: "We'll remind you a few days before.",
  txtStripeCustomerBalance: 'Customer balance',
  txtStripeDelinquentAddCardTitle: 'Add new card to reactivate account',
  txtStripeDelinquentRetryPayments: 'Retry failed payments in order to reactivate account',
  txtStripeDelinquentStatusText: 'Reactivate account',
  txtStripeEmailCC: 'CC',
  txtStripeEmailTo: 'Send email to',
  txtStripeInvoicePrefix: 'Invoice prefix',
  txtStripeInvoices: 'Invoices',
  txtStripePaid: 'Paid',
  txtStripePaymentRetry: 'Retry payment',
  txtStripePayments: 'Payments',
  txtStripePaymentsRetry: 'Retry failed payments',
  txtStripeSubscriptions: 'Subscriptions',
  txtSubrecipeGetRecipePDF: 'Download recipe book',
  txtSubrecipeRecipe: 'Recipe book',
  txtSubrecipeRecipePDFMultiple: 'Recipe book for multiple batches',
  txtSubrecipesAsItem: 'Used as inventory item',
  txtSubrecipesCopyToVenues: 'Copy to venues',
  txtSubrecipesCopyToVenuesConfirmMessage:
    "The selected batches will be copied to all selected venues. If the batch doesn't exist in a selected venue, it's created. If the batch already exists, the recipe won't be updated.",
  txtSubrecipesEdit: 'Edit batch',
  txtSubrecipesLinkedItem: 'Linked inventory item',
  txtSubrecipesLinkedSubrecipe: 'Linked batch',
  txtSubrecipesNew: 'Create new batch',
  txtSubrecipesRealTimeStockNotAvailable: 'Real time stock for batch inventory items is not available',
  txtSubrecipesTitle: 'Batches',
  txtSubrecipeTitle: 'Batch',
  txtSubscriptionRestrictedFeature: 'Restricted Feature',
  txtTax: 'Tax rate',
  txtTaxes: 'Taxes',
  txtTaxesCompounded: 'Compounded taxes',
  txtTaxesEdit: 'Edit tax',
  txtTaxesInner: 'Compounded taxes',
  txtTaxesManage: 'Manage taxes',
  txtTaxesMessageSaveFirst: 'Please name your tax rate and save before you continue',
  txtTaxesNew: 'New tax',
  txtTaxesRate: 'Rate',
  txtTaxesRateComputed: 'Computed rate',
  txtTaxesScheme: 'Tax Scheme',
  txtTaxesSingle: 'Single tax',
  txtTaxesTitle: 'Tax name',
  txtTaxesTitleAccounting: 'Tax name in accounting',
  txtUserInvitedToVenues: 'An invitation has been sent for all the selected venues',
  txtUserOnboardingAlmostThere: 'Almost there! After your account is confirmed you will be able to access the application.',
  txtUserOnboardingEmailConfirmAgain: 'Send email confirmation again',
  txtUserOnboardingEmailConfirmResendSuccess: 'Email verification link has been sent.',
  txtUserOnboardingNoVenue: 'No venue found. Please create your first venue in order to start.',
  txtUserOnboardingReopeningMessage: 'By clicking "Resume Subscription", your payment method will be charged at the beginning of the next billing period',
  txtUserOnboardingReopeningTitle: 'Reopening? Unpause your account and enjoy WISK right where you left off.',
  txtUserOnboardingVenueNone: 'Welcome to WISK! Please fill in your venue details below',
  txtUsersCompleteInformation: 'Please complete your information to continue using Wisk.',
  txtUsersDarkModeOptions: 'Select your preferred theme',
  txtUsersDarkModeOptionsAuto: 'Auto (follow system settings)',
  txtUsersDarkModeOptionsDark: 'Dark',
  txtUsersDarkModeOptionsLight: 'Light',
  txtUsersEmailReports: 'Email Reports',
  txtUsersEmailReportsConfirmationMessage: 'Are you sure you want to apply this change on your email reports?',
  txtUsersFoodInventoryTrainingCompleted: 'Food inventory training completed',
  txtUsersInventoryTrainingCompleted: 'Inventory training completed',
  txtUsersInvite: 'Invite',
  txtUsersInvitedToVenue: 'An invitation has been sent to all the selected users',
  txtUsersInviteMultiple: 'Invite more users at once',
  txtUsersInviteUser: 'Invite User',
  txtUsersInviteUsers: 'Invite Users',
  txtUsersReceiveEmailDailySales: 'Daily sales',
  txtUsersReceiveEmailIntakes: 'Invoices',
  txtUsersReceiveEmailInventory: 'Inventories',
  txtUsersReceiveEmailPurchaseOrders: 'Orders',
  txtUsersReceiveEmailWeeklySales: 'Weekly sales',
  txtUsersRefer: 'Refer Friends',
  txtUsersRefereeName: 'Accepted by',
  txtUsersReferInfo: 'Refer users by adding their email address or phone number in the form below',
  txtUsersReferInvite: 'Invite your friends to WISK',
  txtUsersReferInviteSuccess: 'Invitation sent successfully',
  txtUsersSubscribeAllEmailReportsOneVenue: 'Subscribe all email reports for "{a}" venue',
  txtUsersSubscribeTypeForAllVenues: 'Subscribe "{a}" email report for all venues',
  txtUsersUnsubscribeAllEmailReports: 'Unsubscribe all email reports',
  txtUsersUnsubscribeAllEmailReportsAllVenues: 'Unsubscribe all email reports on all venues',
  txtUsersUnsubscribeAllEmailReportsOneVenue: 'Unsubscribe all email reports for "{a}" venue',
  txtUsersUnsubscribeTypeForAllVenues: 'Unsubscribe "{a}" email report for all venues',
  txtUsersVenues: 'Users > Venues',
  txtValidationDateRange: 'Please select a date range',
  txtValidationEmail: 'Please input a valid email address',
  txtValidationEscapeToReset: 'Press Escape key in order to return to the previous value',
  txtValidationPhone: 'Please input a valid phone number',
  txtValidationRequired: 'Value is required',
  txtValidationRequiredItemSelected: 'A value from the list must be selected',
  txtValidationURL: 'Please input a valid URL',
  txtVarianceDetails: 'Variance Details',
  txtVarianceDetailsBeverageCostOverTime: 'Beverage Cost over time',
  txtVarianceDetailsCostPercentageOverTime: 'Cost percentage over time',
  txtVarianceDetailsSoldConsumed: 'Sold / Consumed',
  txtVarianceDownloadMultiVenueInventoryXLS: 'Multi Venue Inventory Spreadsheet',
  txtVarianceEmailOverview: 'Variance overview',
  txtVarianceEmailOverviewPDF: 'Download Variance Overview PDF',
  txtVarianceGroup: 'Variance Group',
  txtVarianceGroups: 'Variance Groups',
  txtVarianceGroupsManage: 'Manage Variance Groups',
  txtVarianceGroupsNew: 'New Variance Group',
  txtVarianceGroupsViewActive: 'View Variance Groups',
  txtVarianceIdentifyLosses: 'Identify losses',
  txtVarianceImproveYourBeverageCost:
    'Improve your beverage cost by reducing shrinkage from over-pouring, spillage, and theft.\n  The variance page compares your POS data with your consumption data to let you know exactly how much money you are losing - down to the ounce.',
  txtVarianceNotificationUnmapped: 'Fix unmapped items',
  txtVarianceSelectInterval: 'Select a Variance period',
  txtVarianceStartReducing: 'Start reducing your variance today!',
  txtVarianceStatsShrinkagePercentage: 'Shrinkage Percentage',
  txtVarianceStatsVarianceCost: 'Variance Cost',
  txtVarianceStatsVarianceRetail: 'Variance Retail',
  txtVarianceTitle: 'Variance',
  txtVarianceUnmappedForceMapMessage1: 'To view the variance you must have over 80% of your sales mapped. ',
  txtVarianceUnmappedForceMapMessage2: 'Please map or archive the following POS Items to view your variance.',
  txtVarianceUnmappedModalTitle: 'Unmapped POS Items',
  txtVarianceUploadSalesManually: 'Upload sales manually',
  txtVenueActiveTransfer: 'Venue transfers',
  txtVenueActiveTransferEmail: 'Download venue transfers (PDF)',
  txtVenueBottleKeepTrackBottles: 'Keep track of your bottles today!',
  txtVenueBottleManageYourItemsExplanation:
    'Easily manage and update all your items on this page. You can drag & drop the columns in any order you want, update key fields like cost, distributor, title, and many more, and even save these views as presets to access them instantly in the future.',
  txtVenueBottleNotEditable: 'This item is temporarily locked from further changes until the current update is applied to your data.',
  txtVenueBottlesAddCodeToVariant: 'Add this code to an existing item variation',
  txtVenueBottlesAllItemsInOnePlace: 'All your items, in one place!',
  txtVenueBottlesByLocation: 'Items by location',
  txtVenueBottlesCheckMeasurement: 'Check Measurement',
  txtVenueBottlesCloneVariant: 'Clone this item variation',
  txtVenueBottlesCreate: 'Create new item',
  txtVenueBottlesCreateVariant: 'Create new item variation',
  txtVenueBottlesCreateVariantShort: 'New variation',
  txtVenueBottlesEdit: 'Edit WISK item',
  txtVenueBottlesFind: 'Search item',
  txtVenueBottlesFindInParent: 'Search for items in parent venue',
  txtVenueBottlesFloatingActionsDescription: 'items selected',
  txtVenueBottlesForceArchive: 'Force Archive',
  txtVenueBottlesImport: 'Import items from Excel file',
  txtVenueBottlesImportBarcodes: 'Import prices from Excel file based on barcodes',
  txtVenueBottlesItemsPage: 'Go to items page',
  txtVenueBottlesManageFamiliesCategories: 'Manage Families and Categories',
  txtVenueBottlesMeasurement: 'Item measurement',
  txtVenueBottlesMeasurementConversion: 'Measurement Conversion',
  txtVenueBottlesNewBottleModalTitle: 'New Item',
  txtVenueBottlesNotEnoughDataWiskPar: 'Not enough data to calculate the WISK Par',
  txtVenueBottlesNotFoundCreate: 'Item not found, create new',
  txtVenueBottlesPDF: 'Download Items PDF',
  txtVenueBottlesPDFSortedByTitle: 'By Alphabetical Order',
  txtVenueBottlesPDFSortedByPreviousInventory: 'By Previous Inventory Order',
  txtVenueBottlesPDFBarcodes: 'Items Barcodes',
  txtVenueBottlesPriceDisabledInvoicePresent: 'The price is taken from the last invoice, please modify it there',
  txtVenueBottlesPriceDisabledMultipleVariants: 'Please change the price on each variation of this item',
  txtVenueBottlesPriceDisabledSubrecipe: 'The price is taken from the connected subrecipe, please modify it there',
  txtVenueBottlesRecentlyAdded: 'Recently Added',
  txtVenueBottlesSpreadsheet: 'Items Spreadsheet',
  txtVenueBottlesTitle: 'Items List',
  txtVenueBottlesUnitsByDefaultVariation: 'Units by default variation',
  txtVenueBottlesUploadDownloadExistingItems: 'Download template',
  txtVenueBottlesUploadErrorMessage: 'The file could not be uploaded because of the following errors. Please correct these errors and try uploading the file again:',
  txtVenueBottlesUploadItems: 'Upload items',
  txtVenueBottlesUploadMessage: "Download the file below and populate it with your latest item data. Existing items will be updated, and new items will be added to your venue's item list.",
  txtVenueBottlesVariant: 'Item Variation',
  txtVenueBottlesVariantConfirmMeasurmentConversion: 'Use measurement conversion',
  txtVenueBottlesVariantDefault: 'Default for inventory / par level',
  txtVenueBottlesVariantDefaultSet: 'Set as default for inventory / par level',
  txtVenueBottlesVariantDefaultTitle: 'Default variation',
  txtVenueBottlesVariantHideArchived: 'Hide archived variations',
  txtVenueBottlesVariantInventoriable: 'Inventoriable',
  txtVenueBottlesVariantInventoriableAs: 'Inventoried as',
  txtVenueBottlesVariantInventoriableAsCases: 'Inventoriable as cases',
  txtVenueBottlesVariantInventoriableAsCasesDisabledReason: 'Case size must be greater than 1',
  txtVenueBottlesVariantInventoriableAsUnits: 'Inventoriable as units',
  txtVenueBottlesVariantLabel: 'Variation Name',
  txtVenueBottlesVariantNameNotUnique: 'A variation with the same name already exists for this item.',
  txtVenueBottlesVariantOrderFormat: 'Allow order in units',
  txtVenueBottlesVariantReceiveOnly: 'Receive only',
  txtVenueBottlesVariants: 'Item Variations',
  txtVenueBottlesVariantShowArchived: 'View archived variations',
  txtVenueBottlesViewInventory: 'Find in inventory',
  txtVenueBottlesViewMovement: 'Find in intake',
  txtVenueOperationsAccountingIntegration: 'Accounting integration',
  txtVenueOperationsAccountingSetup: 'Setup accounting integration',
  txtVenueOperationsAccountingSetupDone: 'Completed',
  txtVenueOperationsAccountingSetupNone: 'Not completed',
  txtVenueOperationsAccountingStatusExpiredMessage: 'Your accounting integration expired, please do the set up again',
  txtVenueOperationsActive: 'Active',
  txtVenueOperationsAdminFields: 'Super User fields',
  txtVenueOperationsBilled: 'Billed',
  txtVenueOperationsBusinessDayStartHour: 'Business day starting hour',
  txtVenueOperationsCancelSubscription: 'Cancel Subscription',
  txtVenueOperationsCentralVenue: 'Central Venue',
  txtVenueOperationsChangePlan: 'Change Plan',
  txtVenueOperationsChangePlanReminderSelected: 'You have selected this plan in the previous step',
  txtVenueOperationsChangeSubscriptionPlatform: 'Convert Subscription from Apple to Credit Card',
  txtVenueOperationsChoosePlan: 'Choose Plan',
  txtVenueOperationsCopyItems: 'Wisk Items',
  txtVenueOperationsCopyItemsLabel: 'Copy data to new venue',
  txtVenueOperationsCopyItemsNone: 'None',
  txtVenueOperationsCopyPOSItems: 'Wisk Items and POS mappings',
  txtVenueOperationsCouponApply: 'Apply coupon',
  txtVenueOperationsCurrentPlan: 'Keep using this plan',
  txtVenueOperationsDefaultUMVolume: 'Default UM Volume',
  txtVenueOperationsDefaultUMWeight: 'Default UM Weight',
  txtVenueOperationsExternalLinks: 'External links',
  txtVenueOperationsFintechLabel: 'Fintech Label',
  txtVenueOperationsGoogleAddress: 'Google address',
  txtVenueOperationsIdealStockDays: 'Ideal stock days',
  txtVenueOperationsInfo: 'Venue info',
  txtVenueOperationsInventoryHours: 'Inventory hours before WISK',
  txtVenueOperationsInventoryLockDaysAfterApproving: 'Days to lock inventory after approving',
  txtVenueOperationsInventoryLockDaysAfterUnlocking: 'Days to lock inventory after unlocking',
  txtVenueOperationsInvoiceProcessingNotes: 'Invoice Processing Notes',
  txtVenueOperationsInvoiceProcessingNotesForSelectedDistributor: 'Invoice Processing Notes for the selected distributor',
  txtVenueOperationsLineaProContinuousScan: 'LineaPro continuous scans',
  txtVenueOperationsLiveSales: 'Live sales',
  txtVenueOperationsName: 'Venue name',
  txtVenueOperationsOnboardingType: 'Onboarding type',
  txtVenueOperationsPartialMeasuringType: 'Partial measuring type',
  txtVenueOperationsPauseCouponApply: 'Subscription PAUSED',
  txtVenueOperationsPaymentInfo: 'Payment info',
  txtVenueOperationsPaymentMethod: 'Payment method',
  txtVenueOperationsPlan: 'Plan',
  txtVenueOperationsPlanPriceMonthly: 'Price monthly',
  txtVenueOperationsPlanPriceYearly: 'Price yearly',
  txtVenueOperationsPlanUpgradeNeeded: 'Plan upgrade necessary',
  txtVenueOperationsPopulateData: 'Venue data initialization',
  txtVenueOperationsPopulateDataCopy: 'Copy data',
  txtVenueOperationsPopulateDataNew: 'New data',
  txtVenueOperationsPopulateDataShare: 'Share data',
  txtVenueOperationsPOSType: 'POS Type',
  txtVenueOperationsPriceFee: 'Price fee',
  txtVenueOperationsPriceRetail: 'Price retail',
  txtVenueOperationsRealtimeStockRecalculationDays: 'Real time stock recalculation threshold (days)',
  txtVenueOperationsRequireIntakePhoto: 'Require invoice photo',
  txtVenueOperationsResetLocalVenueData: 'Reload local venue data',
  txtVenueOperationsResyncAreasData: 'Resync areas data',
  txtVenueOperationsResyncVenueData: 'Resync venue data',
  txtVenueOperationsSalesConversation: 'Sales currency conversion',
  txtVenueOperationsShowCurrentDistributorPrices: 'Show current distributor prices',
  txtVenueOperationsSalesRep: 'Sales Rep',
  txtVenueOperationsSelectedPlan: 'Selected Plan',
  txtVenueOperationsSelectPlan: 'Select Plan',
  txtVenueOperationsSendInvoiceEmail: 'Send invoice by email',
  txtVenueOperationsSignature: 'Signature',
  txtVenueOperationsSubscriptionStatus: 'Subscription status',
  txtVenueOperationsTesting: 'Venue used for testing purposes',
  txtVenueOperationsTransfers: 'Transfers',
  txtVenueOperationsType: 'I will primarily use WISK for:',
  txtVenueOperationsTypeBarInventory: 'Bar Inventory',
  txtVenueOperationsTypeRestaurantInventory: 'Restaurant Inventory',
  txtVenueOperationsUpgradeNow: 'Upgrade Now',
  txtVenueOperationsVariance: 'Variance',
  txtVenueOperationsVenueType: 'Venue type',
  txtVenueOperationsWeightedAveragePricing: 'Weighted Average Pricing',
  txtVenuesAddPermission: 'Add Permission',
  txtVenuesAddPermissionDisabledAllAdded: 'This role already contains all the permissions available',
  txtVenuesAddRoleValidation: 'This role name is already in use',
  txtVenuesClone: 'Create a new test venue based on this one (only for WISK Admin)',
  txtVenuesCopy: 'Copy data to other venue',
  txtVenuesCopyAnonymize: 'Anonymize',
  txtVenuesCopyAssignUsers: 'Assign users',
  txtVenuesCopyWithCustomFields: 'With custom fields',
  txtVenuesCopyWithInventories: 'With inventories',
  txtVenuesCopyWithPOSItems: 'With POS items',
  txtVenuesCopyWithSales: 'With sales',
  txtVenuesDaysSinceLastIntake: 'Last invoice date',
  txtVenuesDaysSinceLastInventory: 'Last inventory date',
  txtVenuesDaysSinceLastSale: 'Time since last sale',
  txtVenuesDaysSinceLastSaleImported: 'Last sale imported',
  txtVenuesDistributorsScore: 'Distributors Score',
  txtVenuesFirstInventoryDate: 'First Inventory Date',
  txtVenuesGoToStripe: 'Go to Stripe',
  txtVenuesHealthScore: 'Health Score',
  txtVenuesIntakesCount: 'Invoices count',
  txtVenuesIntakesWeeklyAverage: 'Invoices weekly average',
  txtVenuesInventoriesCount: 'Inventories count',
  txtVenuesLastInventoryValue: 'Last inventory value',
  txtVenuesLastSaleCheck: 'Last time WISK checked for new sales',
  txtVenuesNew: 'New Venue',
  txtVenuesNewRole: 'Add Role',
  txtVenuesNewRoleFromThis: 'New Role based on this',
  txtVenuesNewRoleFromThisInfo: 'The new Role will contain all the permissions from this one',
  txtVenuesOverStockPercentage: 'Over stock percentage',
  txtVenuesOverStockValue: 'Over stock value',
  txtVenuesParLevelsScore: 'Par Levels Score',
  txtVenuesPartnerVenue: 'Partner Venue',
  txtVenuesPartnerVenueCannotChangeNotAdmin: 'Change is disabled because the current user is not admin of selected venue',
  txtVenuesPartnerVenues: 'Partner Venues',
  txtVenuesPermissions: 'Permissions',
  txtVenuesPOS: 'POS',
  txtVenuesPOSBrandOther: 'My POS is not in the list',
  txtVenuesPOSIntegrationStatus: 'POS Integration Status',
  txtVenuesPOSItemsScore: 'POS Items Score',
  txtVenuesPOSSelectBrand: 'Select POS Brand',
  txtVenuesPricesScore: 'Prices Score',
  txtVenuesRoles: 'Roles',
  txtVenuesRolesPermissions: 'Roles > Permissions',
  txtVenuesScore: 'Score',
  txtVenuesSelect: 'Select Venue',
  txtVenuesStats: 'Venue Stats',
  txtVenuesStripeId: 'Stripe Id',
  txtVenueStatsCentralItemsList: 'Use a shared WISK Items list',
  txtVenueStatsCentralPOSItemsList: 'Use a shared POS Items list',
  txtVenuesUserAdd: 'Add to venues',
  txtVenuesUsers: 'Venues > Users',
  txtVenuesVenueBottlesCount: 'Items Count',
  txtVenuesWeeklyAverageInventories: 'Weekly average inventories',
  txtVenuesWeeklySalesAverage: 'Weekly Sales Average',
  txtVenueSwitchLabel: 'Switch to:',
  txtVenueSwitchTitle: 'Switch to a different venue',
  txtVenueTransferDetails: 'Venue transfer details',
  txtVenueTransferDetailsEmail: 'Download transfers details (PDF)',
  txtVenueTransferDetailsEmailByVenue: 'Download transfers details by venue (PDF)',
  txtVenueTransferRequestAccept: 'Accept, start preparation',
  txtVenueTransferRequestDecline: 'Decline',
  txtVenueTransferRequestEnabled: 'Venue transfers',
  txtVenueTransferRequestId: 'Venue transfer request id',
  txtVenueTransferRequestNew: 'New venue transfer request',
  txtVenueTransferRequestSend: 'Send items',
  txtVenueTransferRequestsReportGroupById: 'By transfer request id',
  txtVenueTransferRequestsReportGroupByItem: 'By item',
  txtVenueTransferRequestsViewReport: 'View active transfer requests',
  txtVenueTransferRequestsViewReportTitle: 'Active transfer requests',
  txtVenueUpgradeNowTooltip: 'Get more out of WISK by upgrading to a higher plan',
  txtWebAppNotOptimizedMobile: 'Wisk web-app is not optimized for mobile browsers.',
  txtWeightedAveragePricingInfo: 'Your venue uses weighted average for calculating costs.',
  txtWiskExportSettings: 'Export Settings',
  txtWiskFilterContains: 'Contains',
  txtWiskFilterEquals: 'Equals',
  txtWiskFilterEqualsDate: 'Exact',
  txtWiskFilterGreaterThan: 'Greater Than',
  txtWiskFilterGreaterThanDate: 'After',
  txtWiskFilterGreaterThanOrEqual: 'Greater Than or Equal',
  txtWiskFilterHasValue: 'Has Value',
  txtWiskFilterInRange: 'In Range',
  txtWiskFilterIsEmpty: 'Is Empty',
  txtWiskFilterIsNotEmpty: 'Is Not Empty',
  txtWiskFilterIsNotOneOf: 'Is not one of',
  txtWiskFilterIsOneOf: 'Is one of',
  txtWiskFilterLessThan: 'Less Than',
  txtWiskFilterLessThanDate: 'Before',
  txtWiskFilterLessThanOrEqual: 'Less Than or Equal',
  txtWiskFilterNotContains: 'Not Contains',
  txtWiskFilterNotEquals: 'Not Equals',
  txtWiskFilterNotEqualsDate: 'Not',
  txtWiskFilterNotSet: 'Not Set',
  txtWiskGridAddViewLabel: 'Report Name',
  txtWiskGridAddViewTitle: 'Add report',
  txtWiskGridAddViewValidationExists: 'This name is already used for this grid',
  txtWiskGridClonedSuccess: 'Report cloned successfully',
  txtWiskGridCloneViewTitle: 'Clone current report',
  txtWiskGridFilterEmptyOperationMessage: 'No field selected',
  txtWiskGridFilterOperationValue: 'Value',
  txtWiskGridGroupCollapse: 'Collapse group',
  txtWiskGridGroupCollapseAll: 'Collapse all',
  txtWiskGridGroupCollapseChildren: 'Collapse subgroups',
  txtWiskGridGroupExpand: 'Expand group',
  txtWiskGridGroupExpandAll: 'Expand all',
  txtWiskGridGroupExpandChildren: 'Expand subgroups',
  txtWiskGridGrouping: 'Grouping',
  txtWiskGridGroupingPanelDragGroupDisabledMessage: 'Row group is always sorted first and independently from inner rows',
  txtWiskGridGroupingPanelDragMessage: 'Drag to group and reorder',
  txtWiskGridGroupRemove: 'Remove from group',
  txtWiskGridRefreshData: 'Refresh Grid Data',
  txtWiskGridViewModificationDisabledMessageSystem: 'Changes to system reports only last until you refresh the browser. To keep a custom version of this report, click "Clone current report".',
  txtWiskGridViewModificationDisabledMessageUser: 'You cannot edit reports with your current user role.',
  txtWiskGridViewsManage: 'Select report',
  txtWiskItemsClearParLevel: 'Clear Par Level',
  txtWiskItemsFloatingActionsGroup: 'Group items',
  txtWiskItemsFloatingActionsMerge: 'Merge items',
  txtWiskItemsGridGroupBy: 'Group by',
  txtWiskItemsGridGroupMyViews: 'Your reports',
  txtWiskItemsGridGroupSuggested: 'Suggested reports',
  unable_to_fulfill: 'Unable to fulfill',
  unit: 'unit',
  units: 'units',
  wednesday: 'Wednesday'
}

export default translations
